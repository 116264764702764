import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from 'magma/common/interfaces';
import { faCheck } from 'magma/generated/fa-icons';
import { Model } from 'magma/services/model';

@Component({
  selector: 'manage-presentation-viewers-modal',
  templateUrl: './manage-presentation-viewers-modal.component.pug',
  styleUrls: ['./manage-presentation-viewers-modal.component.scss'],
})
export class ManagePresentationViewersModal {
  readonly checkIcon = faCheck;
  selectedActiveCanvasUsers: User[] = [];
  selectedActiveSequenceUsers: User[] = [];
  selectedPresentationViewers: User[] = [];
  selectedUsersTab = 'from-active-canvas';


  @Output() close = new EventEmitter<boolean>();
  @Input() data: { model?: Model } = {};

  get presentationViewers(): User[] {
    return this.data.model!.getPresentationViewers.filter(v => this.data.model!.getUserByUniqId(v.uniqId));
  }


  get activeCanvasUsers() {
    const users = (this.data.model?.drawing?.users ?? []).filter(user => !this.presentationViewers.some(u => u.uniqId === user.uniqId));

    if (this.selectedActiveCanvasUsers.length > users.length)
      this.selectedActiveCanvasUsers = this.selectedActiveCanvasUsers.filter(user => users.some(u => u.uniqId === user.uniqId));
    if (this.selectedActiveSequenceUsers.length > this.sequenceUsers.length)
      this.selectedActiveSequenceUsers = this.selectedActiveSequenceUsers.filter(user => this.sequenceUsers.some(u => u.uniqId === user.uniqId));
    return users;
  }

  get sequenceUsers() {
    const users = (this.data.model?.sequenceUsers ?? []).filter(user => !this.presentationViewers.some(u => u.uniqId === user.uniqId));
    if (this.selectedActiveSequenceUsers.length > users.length)
      this.selectedActiveSequenceUsers = this.selectedActiveSequenceUsers.filter(user => users.some(u => u.uniqId === user.uniqId));
    return users.filter(user => !this.presentationViewers.some(u => u.uniqId === user.uniqId));
  }

  get drawing() {
    return this.data.model?.editor?.drawing;
  }

  setSelectedUsers(users: User[], onSequence = false) {
    if (onSequence) {
      this.selectedActiveSequenceUsers = users;
      this.selectedActiveCanvasUsers = this.activeCanvasUsers.filter(user => !!users.some(u => u.uniqId === user.uniqId));
    } else {
      this.selectedActiveCanvasUsers = users;
      this.selectedActiveSequenceUsers = this.sequenceUsers.filter(user => (this.selectedActiveSequenceUsers.includes(user) && !this.activeCanvasUsers.some(u => u.uniqId === user.uniqId)) || !!users.some(u => u.uniqId === user.uniqId));
    }
  }

  setSelectedViewers(users: User[]) {
    this.selectedPresentationViewers = users;
  }

  inviteUserToPresentation = (user: User) => {
    this.data.model?.inviteToPresentation(user.uniqId);
  };

  kickUserFromPresentation = (user: User) => {
    this.data.model?.kickFromPresentation([user.uniqId]);
  };

  isCoPresenter(user: User) {
    return this.data.model?.isPresentationCoPresenter(user.uniqId);
  }


  onClose(confirmed = false) {
    if (confirmed) {
      this.close.emit(true);
    } else {
      this.close.emit(undefined);
    }
  }
}
