import { Component } from '@angular/core';
import { LocaleService } from 'magma/services/locale.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DEFAULT_LOCALE } from 'magma/common/constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'embedded-framer-page',
  templateUrl: 'embedded-framer-page.component.pug',
  styleUrls: ['embedded-framer-page.component.scss'],
})
export class EmbeddedFramerPageComponent {
  constructor(
    private localeService: LocaleService,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
  ) { }

  get embeddedPageUrl() {
    const framerPath = this.activatedRoute.snapshot.data.embeddedFramerPagePath;
    if (!framerPath) throw new Error('Missing embeddedFramerPagePath route parameter.');
    let locale = this.localeService.getLocale() ?? '';
    if (!locale || locale === DEFAULT_LOCALE) return this.domSanitizer.bypassSecurityTrustResourceUrl(`/${framerPath}?embedded=1`);
    locale = locale.split('-')[0];
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`/${locale}/${framerPath}?embedded=1`);
  }
}
