import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'services/modal.service';
import { JamsService } from 'magma/services/jams.service';
import { LiveJam, JamDrawing, JamVisibility } from 'magma/common/interfaces-jams';
import { drawingsPath } from 'magma/common/data';
import { faUsers, farEllipsisV, faEyeSlash, faExclamationTriangle } from 'magma/common/icons';
import { Analytics } from 'magma/common/interfaces';
import { TrackService } from 'services/track.service';
import { UserService } from 'services/user.service';
import { createJamAnalyticsData, JamAnalyticsPayload } from 'magma/common/analytics';


@UntilDestroy()
@Component({
  selector: 'jam',
  templateUrl: './jam.component.pug',
  styleUrls: ['./jam.component.scss', '../common.component.scss'],
})
export class JamComponent {
  JamVisibility = JamVisibility;
  readonly farEllipsisV = farEllipsisV;
  readonly usersIcon = faUsers;

  readonly hiddenIcon = faEyeSlash;
  readonly forceHiddenIcon = faExclamationTriangle;

  @Input() jamDrawing!: JamDrawing;
  @Input() index!: number;
  @Output() onChanged = new EventEmitter();
  @Output() onRemoved = new EventEmitter<string>();

  liveJamData: LiveJam | undefined;
  connectedUsers = new Set();

  constructor(
    private jamsService: JamsService,
    private modalService: ModalService,
    private router: Router,
    private track: TrackService,
    private userService: UserService
  ) {
  }

  private getLocationForAnalytics() {
    return this.router.url;
  }

  async ngAfterViewInit() {
    this.jamsService.observeJam(this.jamDrawing.shortId).pipe(untilDestroyed(this)).subscribe(async update => {
      if (update) {
        this.jamDrawing = update;
      } else {
        // drawing was hidden or removed
        this.onRemoved.emit(this.jamDrawing.shortId);
      }
    });
    this.jamsService.observeLiveJam(this.jamDrawing.shortId).pipe(untilDestroyed(this)).subscribe(async update => {
      if (update) this.liveJamData = update;
    });
  }

  private hasJoined() {
    return !!(this.userService.userId && this.jamDrawing.jam.users.find(userId => userId === this.userService.userId));
  }

  get isUserAnonymous() {
    return this.userService.user?.userType === 'anonymous';
  }

  get isUserVerified() {
    return !this.userService.user?.unverifiedEmail;
  }

  async joinJam() {
    if (this.hasJoined()) {
      this.openDrawing();
      return;
    }

    this.track.event<JamAnalyticsPayload>(Analytics.ViewArtJam, createJamAnalyticsData(this.jamDrawing, {
      from: this.getLocationForAnalytics(),
      indexOnTheList: this.index,
      anonymous: this.isUserAnonymous,
      verified: this.isUserVerified,
    }));

    void this.modalService.joinJam({ jamDrawing: this.jamDrawing, indexOnTheList: this.index });
  }

  openDrawing() {
    void this.router.navigate([drawingsPath, this.jamDrawing.shortId]);
  }

  isLive() {
    return this.liveJamData?.connectedUsers?.length ?? false;
  }
}
