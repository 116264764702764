import { ToastService } from 'magma/services/toast.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSpinner } from 'magma/common/icons';
import { Analytics } from 'magma/common/interfaces';
import { meta } from 'magma/common/data';
import { faTwitter, faInstagram, faFacebook } from 'magma/common/icons';
import { EntitiesService } from 'services/entities.service';
import { generateSharedImageUrl } from 'util/util';
import { ITrackService } from 'magma/services/track.service.interface';

export interface ShareEntityAsImageModalInput {
  entityId: string;
  openedBy: string;
}

@Component({
  selector: 'share-entity-as-image-modal',
  templateUrl: 'share-entity-as-image-modal.component.pug',
  styleUrls: ['share-entity-as-image-modal.component.scss'],
})
export class ShareEntityAsImageModalComponent implements OnInit {
  readonly faTwitter = faTwitter;
  readonly faInstagram = faInstagram;
  readonly faFacebook = faFacebook;
  readonly faSpinner = faSpinner;
  readonly twitter = meta('twitter:site');
  readonly instagram = meta('instagram:site');
  readonly facebook = meta('facebook:site');

  publicUrl: string | undefined;
  loading = false;

  @Output() close = new EventEmitter();

  @Input() data: ShareEntityAsImageModalInput = { entityId: '', openedBy: '' };

  constructor(private entitiesService: EntitiesService, private track: ITrackService, private toastService: ToastService) {
  }

  ngOnInit() {
    this.track.event(Analytics.OpenShareAsPNGModal, { openedBy: this.data.openedBy });
  }

  async generateLink() {
    try {
      this.loading = true;
      const data = await this.entitiesService.shareLiveEntityAsImage(this.data.entityId, 'share-as-png-modal');
      this.publicUrl = generateSharedImageUrl(data.objectId);
    } catch (e) {
      this.toastService.error({ message: 'Failed to generate link', subtitle: e.message });
    }
    this.loading = false;
  }

  copyEvent(method: string) {
    this.track.event(Analytics.CopyShareAsPNGLink, { eventSource: 'share-modal', method });
  }

  onClose() {
    this.close.emit();
  }
}
