import { APP_BOOTSTRAP_LISTENER, type Renderer2, RendererFactory2 } from '@angular/core';
import { locale, reportMap, translateFromMap } from '../common/i18n';

export function provideI18nRenderer() {
  return {
    provide: APP_BOOTSTRAP_LISTENER,
    multi: true,
    useFactory: (r: RendererFactory2) => () => i18nRenderer(r),
    deps: [RendererFactory2]
  };
}

type PatchedRenderer2 = Renderer2 & { _patched?: true };

export function i18nRenderer(factory: RendererFactory2) {
  const originalCreateRenderer = factory.createRenderer;
  factory.createRenderer = function (hostElement: any, type: any) {
    const renderer: PatchedRenderer2 = originalCreateRenderer.call(factory, hostElement, type);
    if (renderer._patched) return renderer;

    renderer._patched = true;

    const originalSetValue = renderer.setValue;
    renderer.setValue = function (node: any, value: string) {
      if (node instanceof Text) {
        value = translateFromMap(value, locale);
        reportMap(node, value);
      }
      return originalSetValue.call(renderer, node, value);
    };

    const originalCreateText = renderer.createText;
    renderer.createText = function (value: string) {
      const translated = translateFromMap(value, locale);
      const text = originalCreateText.call(renderer, translated);
      reportMap(text, value);
      return text;
    };

    const originalSetProperty = renderer.setProperty;
    renderer.setProperty = function (el: any, name: string, value: any) {
      // do we want to monitor property changes?
      if (typeof value === 'string') {
        if (name === 'title') {
          value = translateFromMap(value, locale);
        }
      }
      return originalSetProperty.call(renderer, el, name, value);
    };

    const originalSetAttribute = renderer.setAttribute;
    renderer.setAttribute = function (el: any, name: string, value: string) {
      if (typeof value === 'string') {
        if (name === 'title' || name === 'tooltip' || name === 'placeholder') {
          value = translateFromMap(value, locale);
        }
      }
      return originalSetAttribute.call(renderer, el, name, value);
    };
    return renderer;
  };
}
