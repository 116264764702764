
import { UserReportService } from 'magma/services/user-reports.service';
import { HttpClient } from '@angular/common/http';
import { toPromise } from 'shared/utils';
import { UserReportCreate } from 'magma/common/interfaces';
import { Injectable } from '@angular/core';

@Injectable()
export class PortalUserReportService extends UserReportService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  async create(data: UserReportCreate) {
    return toPromise(this.httpClient.post<void>(`/api/report`, data));
  }
}
