const badWords = new Set([
  'ass',
  'bastard',
  'bitch',
  'bollocks',
  'boob',
  'choad',
  'cock',
  'crap',
  'cum',
  'cunt',
  'dick',
  'douche',
  'dork',
  'fag',
  'fuck',
  'goddamn',
  'jerk',
  'knob',
  'minger',
  'moron',
  'numbnuts',
  'piss',
  'prick',
  'puta',
  'retard',
  'skank',
  'slag',
  'slut',
  'snatch',
  'sod',
  'tosser',
  'twat',
  'wanker',
  'whore',
  'wimp',
  'wuss',
  'clod',
  'cretin',
  'dimwit',
  'git',
  'numpty',
  'pillock',
  'schmuck',
  'scumbag',
  'tart'
]);

let previousLength = badWords.size;
do {
  previousLength = badWords.size;
  for (const word of badWords) {
    for (const letter of word) {
      const lowercased = letter.toLowerCase();
      if (lowercased === 'l') {
        badWords.add(word.replace('l', 'i'));
        badWords.add(word.replace('l', '1'));
        badWords.add(word.replace('l', '|'));
        badWords.add(word.replace('l', '!'));
      } else if (lowercased === 'i') {
        badWords.add(word.replace('i', 'l'));
        badWords.add(word.replace('i', '1'));
        badWords.add(word.replace('i', '|'));
        badWords.add(word.replace('i', '!'));
      } else if (lowercased === 's') {
        badWords.add(word.replace('s', '$'));
        badWords.add(word.replace('s', '5'));
      } else if (lowercased === 'g') {
        badWords.add(word.replace('g', '6'));
      } else if (lowercased === 'a') {
        badWords.add(word.replace('a', '@'));
        badWords.add(word.replace('a', '4'));
      } else if (lowercased === 'o') {
        badWords.add(word.replace('o', '0'));
      } else if (lowercased === 'b') {
        badWords.add(word.replace('b', '6'));
      } else if (lowercased === 'e') {
        badWords.add(word.replace('e', '€'));
      } else if (lowercased === ' ') {
        badWords.add(word.replace(' ', '_'));
        badWords.add(word.replace(' ', '-'));
        badWords.add(word.replace(' ', ''));
      } else if (lowercased === 'u') {
        badWords.add(word.replace('u', 'oo'));
        badWords.add(word.replace('u', '00'));
        badWords.add(word.replace('u', 'o0'));
        badWords.add(word.replace('u', '0o'));
      }
    }
  }
} while (badWords.size !== previousLength);

export function hasBadWords(str: string) {
  const lowercased = str.toLowerCase();
  for (const badWord of badWords.values()) {
    if (lowercased.includes(badWord)) {
      return true;
    }
  }
  return false;
}
