import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCheck, faTimes, storyprotocolIcon, inheritedLicenseIcon, faExternalLink, faInfoCircle } from 'magma/common/icons';
import { ArtworkLicensingData, ClientInheritedLicense, LicenseRights, LicenseTermId, Drawing } from 'magma/common/interfaces';
import { revisionThumbPath } from 'magma/common/clientUtils';
import { getRevisionTitle } from 'magma/common/publishing';
import { UserService } from 'services/user.service';
import { PublishersService } from 'magma/services/publishersService';
import { ToastService } from 'magma/services/toast.service';
import { createTranslate } from 'magma/common/i18n';

const tr = createTranslate();

@Component({
  selector: 'artwork-licensing-modal',
  templateUrl: './artwork-licensing-modal.component.pug',
  styleUrls: ['./artwork-licensing-modal.component.scss'],
})
export class ArtworkLicensingModal {
  readonly inheritedLicenseIcon = inheritedLicenseIcon;
  readonly checkIcon = faCheck;
  readonly crossIcon = faTimes;
  readonly storyprotocolIcon = storyprotocolIcon;
  readonly viewExternalIcon = faExternalLink;
  readonly infoCircle = faInfoCircle;

  readonly nonCommercialLicenseTermId = LicenseTermId.NonCommercialRemix;

  constructor(
    private userService: UserService,
    private publishersService: PublishersService,
    private toastsService: ToastService,
  ) { }

  @Output() close = new EventEmitter<void>();
  @Input() set data({ revision, inheritedLicense, drawing }: ArtworkLicensingData) {
    this.revision = revision; // if this is licensed
    this.inheritedFrom = inheritedLicense; // if it was remixed from something
    this.drawing = drawing;
    this.licenseRights = drawing?.licensing?.rights;

    if (inheritedLicense && !revision) {
      this.thumb = new URL(revisionThumbPath(inheritedLicense.shortId, inheritedLicense.cacheId), window.location.origin).href;
    } else if (revision) {
      this.thumb = new URL(revisionThumbPath(revision.shortId, revision.cacheId), window.location.origin).href;
    } else {
      throw new Error('Invalid modal state!');
    }
  }

  drawing: Drawing | undefined = undefined;
  inheritedFrom: ClientInheritedLicense | undefined;
  revision: ArtworkLicensingData['revision'] | undefined;
  licenseRights: LicenseRights | undefined = undefined;
  licenseRightsVisible = false;
  thumb = '';

  get name() {
    const takeFrom = this.revision || this.inheritedFrom;
    return takeFrom?.publishedIn?.StoryProtocol?.ipAssetName;
  }

  get subtitleVariant() {
    if (this.revision && this.inheritedFrom) {
      return 2;
    } else if (this.inheritedFrom) {
      return 1;
    } else {
      return 0;
    }
  }

  get url() {
    const takeFrom = this.revision || this.inheritedFrom;
    if (!takeFrom) {
      DEVELOPMENT && console.error('Missing title');
      return '';
    }
    return takeFrom.publishedIn?.StoryProtocol?.url ?? '';
  }

  get participants() {
    const takeFrom = this.revision || this.inheritedFrom;
    return takeFrom?.publishedIn?.StoryProtocol?.participants ?? [];
  }

  get remixedFrom() {
    return {
      url: new URL(`/d/${this.inheritedFrom?.shortId}`, window.location.origin).href,
      name: this.inheritedFrom?.publishedIn.StoryProtocol?.ipAssetName ?? 'Unknown artwork name',
    };
  }

  get publishedAt() {
    if (!this.revision && this.inheritedFrom) {
      return this.inheritedFrom.publishedIn.StoryProtocol?.publishedAt;
    } else {
      return this.revision?.publishedIn?.StoryProtocol?.publishedAt;
    }
  }

  get termId() {
    if (!this.revision && this.inheritedFrom) {
      return this.inheritedFrom.publishedIn.StoryProtocol?.licenseTermId ?? LicenseTermId.NonCommercialRemix;
    } else {
      return this.revision?.publishedIn?.StoryProtocol?.licenseTermId ?? LicenseTermId.NonCommercialRemix;
    }
  }

  async remix() {
    if (!this.drawing) throw new Error('Missing drawing');
    if (!this.revision) throw new Error('Missing revision');
    const name = getRevisionTitle(this.drawing, this.revision);
    await this.publishersService.remixRevision(this.revision.shortId, `Remix of "${name}"`);
    this.close.emit();
    this.toastsService.success({ message: tr`Successfully remixed "${name}"` });
  }
}
