import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { faExternalLink, farExclamationTriangle } from 'magma/common/icons';
import { UserReportCreate } from 'magma/common/interfaces';
import { JAMS_CODE_OF_CONDUCT_URL, JAMS_USER_REPORT_OPTIONS, UserReportModalInput } from 'magma/common/interfaces-jams';
import { ToastService } from 'magma/services/toast.service';
import { UserReportService } from 'magma/services/user-reports.service';

@UntilDestroy()
@Component({
  selector: 'user-report-modal',
  templateUrl: 'user-report-modal.component.pug',
  styleUrls: ['./user-report-modal.component.scss'],
})
export class UserReportModalComponent {
  readonly farExclamationTriangle = farExclamationTriangle;
  readonly faExternalLink = faExternalLink;
  readonly MAX_TITLE_LENGTH = 250;
  readonly MAX_DESCRIPTION_LENGTH = 2500;
  readonly options = JAMS_USER_REPORT_OPTIONS;
  readonly JAMS_CODE_OF_CONDUCT_URL = JAMS_CODE_OF_CONDUCT_URL;

  @Output() close = new EventEmitter<boolean | undefined>();
  @Input() data!: UserReportModalInput;

  stage: 0 | 1 = 0;
  report: UserReportCreate = {
    description: '',
    title: ''
  };

  get selectedOption() {
    return this.options.find(o => o.title === this.report.title) || this.options[0];
  }

  constructor(private toastService: ToastService, private userReportService: UserReportService) { }

  ngOnInit() {
    this.report.drawing = this.data.drawing;
  }

  async onSave() {
    try {
      this.report.user = this.data.user?.accountId;
      await this.userReportService.create(this.report);
      this.close.emit();
      this.toastService.success({ message: 'Your report has been successfully sent.' });
    } catch (e) {
      this.toastService.error({ message: 'Failed to send your report', subtitle: e.message });
    }
  }

  canCreate() {
    return this.report.title.length > 0;
  }

  onClose() {
    this.close.emit();
  }
}
