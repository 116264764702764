
import { Injectable } from '@angular/core';
import { JamDrawing, JamsList, LiveJam, CreateJamDrawing, UpdateJamDrawing, JamFilter, JamData, JamUserDataModerators } from 'magma/common/interfaces-jams';
import { JamsService } from 'magma/services/jams.service';
import { RpcService } from './rpc.service';
import { Model } from 'magma/services/model';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_CANVAS_WIDTH, DEFAULT_CANVAS_HEIGHT, ACCESS_DENIED_INVALID_BIRTHDATE, ACCESS_DENIED_MISSING_BIRTHDATE, ACCESS_DENIED_ANONYMOUS_USER } from 'magma/common/constants';
import { BehaviorSubject, combineLatest, map, shareReplay, startWith, switchMap } from 'rxjs';
import { storageGetBoolean, storageSetBoolean } from 'magma/services/storage';
import { UserService } from './user.service';
import { ModalService } from './modal.service';
import { ToastService } from 'magma/services/toast.service';
import { TrackService } from './track.service';
import { Analytics } from 'magma/common/interfaces';
import { ActivatedRoute } from '@angular/router';
import { isAdult } from 'magma/common/user';

@Injectable({ providedIn: 'root' })
export class PortalJamsService extends JamsService {

  activeJam$ = new BehaviorSubject<JamData | undefined>(undefined);
  activeGroup$ = new BehaviorSubject<string | undefined>(undefined);
  showNsfw$ = new BehaviorSubject(storageGetBoolean('settings-show-nsfw-jams'));
  filterGenre$ = new BehaviorSubject<string[] | undefined>(undefined); // remove ?

  refreshList$ = this.rpc.watch(() => this.rpc.jams.refreshJamList()).pipe(startWith(undefined));

  liveJams: JamDrawing[] = [];
  liveArtJams$ = combineLatest([
    this.activeGroup$,
    this.showNsfw$,
    this.filterGenre$,
    this.activatedRoute.queryParams,
  ]).pipe(
    switchMap(([group, nsfw, genre, queryParams]) => {
      // this will provide only diffs so we need to cache all results
      this.liveJams = [];
      return this.rpc.watch(() => this.rpc.jams.observeLiveJams({ filter: 'live', group, nsfw, genre, archived: queryParams['archived'] === 'true' ? true : false })).pipe(
        map(jamsMap => {
          this.updateLiveJams(jamsMap);
          return this.liveJams;
        }),
      );
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );

  constructor(private rpc: RpcService, private model: Model, private userService: UserService, private modalsService: ModalService,
    private toastService: ToastService, private track: TrackService, private activatedRoute: ActivatedRoute) {
    super();
  }

  canToogleFilter(birthdate: Date) {
    return this.userService.user?.userType !== 'anonymous' && isAdult(birthdate);
  }

  async ensureUserMatureContentAccess() {
    if (!this.userService.user) throw new Error('Missing user data');
    const birthdate = this.userService.user.birthdate ? new Date(this.userService.user.birthdate) : undefined;

    if (this.userService.user.userType === 'anonymous') throw new Error(ACCESS_DENIED_ANONYMOUS_USER);

    if (birthdate) {
      if (!isAdult(birthdate)) {
        throw new Error(ACCESS_DENIED_INVALID_BIRTHDATE);
      }
    } else {
      const birthdate = await this.modalsService.setUserBirthdate();
      if (birthdate) {
        if (!isAdult(birthdate)) {
          throw new Error(ACCESS_DENIED_INVALID_BIRTHDATE);
        } else {
          return await this.rpc.jams.refreshUser(); // refresh user on server side rpc connection
        }
      } else {
        throw new Error(ACCESS_DENIED_MISSING_BIRTHDATE);
      }
    }
  }

  updateArtJamsMatureFilter(isEnabled: boolean) {
    this.showNsfw$.next(isEnabled);
    storageSetBoolean('settings-show-nsfw-jams', isEnabled);
    this.track.event(Analytics.ToggleMatureContent, { isEnabled });
  }

  private updateLiveJams(jams: Map<string, JamDrawing | undefined>) {
    for (const [key, value] of jams.entries()) {
      if (value && !value.jam.archived) {
        const index = this.liveJams.findIndex(j => j._id === value._id);
        if (index !== -1) {
          this.liveJams[index] = value;
        } else {
          this.liveJams.push(value);
        }
      } else {
        this.liveJams = this.liveJams.filter(j => j.shortId !== key);
      }
    }
    this.liveJams.sort((a, b) => {
      if (a.jam.featured && !b.jam.featured) return -1;
      if (!a.jam.featured && b.jam.featured) return 1;

      return (b.createdAt?.getTime() ?? 0) - (a.createdAt?.getTime() ?? 0);
    });
  }

  observeLiveJam(drawingShortId: string) {
    return this.rpc.watch<LiveJam | undefined>(() => this.rpc.jams.observeLiveJam(drawingShortId));
  }

  observeJam(drawingShortId: string) {
    return this.rpc.watch<JamDrawing | undefined>(() => this.rpc.jams.observeJam(drawingShortId));
  }

  async getJams(filter: JamFilter, skip: number, size: number): Promise<JamsList> {
    return this.rpc.jams.getJams(filter, skip, size);
  }

  async getJam(shortId: string): Promise<JamDrawing | undefined> {
    return this.rpc.jams.getJam(shortId);
  }

  async getJamUsers(entityId: string): Promise<JamUserDataModerators[]> {
    return this.rpc.jams.getJamUsers(entityId);
  }

  async editJam(shortId: string, jam: UpdateJamDrawing, from: string) {
    return this.rpc.jams.editJam(shortId, jam, from);
  }

  async createJam(jam: CreateJamDrawing) {
    // TODO make it configurable
    await this.model.createDrawing({
      background: DEFAULT_BACKGROUND_COLOR,
      width: DEFAULT_CANVAS_WIDTH,
      height: DEFAULT_CANVAS_HEIGHT,
      ...jam
    });

    if (jam.jam.nsfw && !this.showNsfw$.getValue()) {
      this.toastService.info({ message: 'You have successfully created an age-restricted Art Jam. To view it, enable mature content in the toggle below.' });
    } else {
      this.toastService.success({ message: 'Successfully created new Art Jam' });
    }
  }

  join(shortId: string, from: string, indexOnTheList: number): Promise<void> {
    return this.rpc.jams.join(shortId, from, indexOnTheList);
  }

  leave(shortId: string, from: string): Promise<void> {
    return this.rpc.jams.leave(shortId, from);
  }

  unarchive(shortId: string): Promise<void> {
    return this.rpc.jams.unarchive(shortId);
  }

  toggleTrollTag(userId: string, enabled: boolean): Promise<void> {
    return this.rpc.jams.toggleTrollTag(userId, enabled);
  }
}
