import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

// @ignore-translate
export enum CARD_TYPES {
  VISA = 'visa',
  MASTER_CARD = 'mastercard',
  AMERICAN_EXPRESS = 'amex',
  DISCOVER = 'discover',
  DINER_CLUB = 'diners',
  JCB = 'jcb',
  UNION_PAY = 'general',
}

@Component({
  selector: 'app-card-image',
  templateUrl: './card-images.component.html',
  styleUrls: ['./card-images.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardImagesComponent {
  @Input() cardType = '';
  CARD_TYPES = CARD_TYPES;
}
