import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AI_MODEL_BASES, AiModelBase, DreamboothInput } from 'magma/common/aiInterfaces';
import { disableMyArtdesk } from 'magma/common/data';
import { EntityType, SelectMode } from 'magma/common/interfaces';
import { ToastService } from 'magma/services/toast.service';
import { lastValueFrom, map } from 'rxjs';
import { AiService } from 'services/ai.service';
import { EntitiesService } from 'services/entities.service';
import { ModalService } from 'services/modal.service';
import { ProjectService } from 'services/projects.service';
import { TeamsQuery } from 'services/team.query';
import { createThumbPath } from 'shared/utils';

export interface QueueAsyncJobModalInput extends DreamboothInput {
  name: string;
  inputImageMetadata: { [key: string]: string };
}

@Component({
  selector: 'queue-async-job-modal',
  templateUrl: 'queue-async-job-modal.component.pug',
  styleUrls: ['./queue-async-job-modal.component.scss'],
})
export class QueueAsyncJobModalComponent {
  readonly entityType = EntityType;

  isSubmitting = false;
  step = 0;

  @Output() close = new EventEmitter<boolean>();
  @Input() data: QueueAsyncJobModalInput | undefined;

  job: QueueAsyncJobModalInput = {
    script: 'dreambooth',
    instancePrompt: '',
    classPrompt: '',
    inputImageMetadata: {},
    name: 'Job name',
    validationPrompt: '',
    steps: 3000,
    baseModel: 'runwayml/stable-diffusion-v1-5',
    resolution: 512,
    checkpointingSteps: 1000,
    numValidationImages: 4,
    numClassImages: 100,
    gradientAccumulationSteps: 1
  };

  baseModels = ['stabilityai/stable-diffusion-2-1', 'runwayml/stable-diffusion-v1-5'];

  constructor(private modals: ModalService, private entitiesService: EntitiesService, private aiService: AiService,
    private toastService: ToastService, private projectService: ProjectService, private teamsQuery: TeamsQuery) { }

  get team() {
    return this.teamsQuery.getActive();
  }

  get teamId() {
    return this.teamsQuery.getActive()?._id;
  }

  get epochs() {
    if (this.isDreambooth) {
      return this.job.steps / (this.job.numClassImages / this.job.gradientAccumulationSteps);
    } else {
      return this.job.steps / (this.shortIds.length / this.job.gradientAccumulationSteps);
    }
  }

  get isDreambooth() {
    return this.job.script === 'dreambooth';
  }

  ngOnInit() {
    if (this.data) {
      this.job = this.data;
    }
  }

  async addFile() {
    const res = await this.modals.selectFolder({
      teamId: this.teamId,
      showArtdesk: !disableMyArtdesk,
      mode: SelectMode.Select,
      showFiles: true
    });
    if (res?.type === 'Folder') {
      if (res.projectId) {
        const entities = await lastValueFrom(this.projectService.getProjectWithEntities(res.projectId, res.entityId).pipe(map(p => p.entities ?? [])));
        entities.forEach(e => {
          if (this.job.inputImageMetadata[e.shortId] === undefined) this.job.inputImageMetadata[e.shortId] = '';
        });
      } else {
        const entities = await lastValueFrom(this.entitiesService.getAll(res.entityId));
        entities.forEach(e => {
          if (this.job.inputImageMetadata[e.shortId] === undefined) this.job.inputImageMetadata[e.shortId] = '';
        });
      }
    } else if (res?.type === 'Drawing' && res.entityId) {
      this.job.inputImageMetadata[res.entityId] = '';
    } else if (res) {
      DEVELOPMENT && console.error('Failed to handle result', res);
    }
  }

  removeImage(shortId: string) {
    delete this.job.inputImageMetadata[shortId];
  }

  thumbPath(shortId: string) {
    return createThumbPath(shortId, this.team?.cacheId);
  }

  async onSubmit() {
    try {
      this.isSubmitting = true;
      const { baseModel, name, resolution, steps, validationPrompt, inputImageMetadata, checkpointingSteps,
        numValidationImages, script, instancePrompt, classPrompt, numClassImages, gradientAccumulationSteps, resumeFromCheckpoint } = this.job;

      await this.aiService.queueDreamboothJob(name, { baseModel, resolution, steps, validationPrompt, checkpointingSteps,
        numValidationImages, script, instancePrompt, classPrompt, numClassImages, gradientAccumulationSteps, resumeFromCheckpoint }, inputImageMetadata, this.teamId ?? null);
      this.close.emit(true);
    } catch (e) {
      this.toastService.error({ message: 'Failed to queue job', subtitle: e.message });
    }
    this.isSubmitting = false;
  }

  onClose() {
    this.close.emit(false);
  }

  get shortIds() {
    return Object.keys(this.job.inputImageMetadata);
  }

  isValid() {
    return this.job.name.length > 0 && this.job.validationPrompt.length > 0 && this.shortIds.length > 0;
  }
}
