import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Analytics } from 'magma/common/interfaces';
import { ManageService } from 'magma/services/manageService';
import { ITrackService } from 'magma/services/track.service.interface';
import { generateLiveEntityDownloadUrl } from 'util/util';
import { blazeIcon } from 'magma/common/icons';

export interface ExportEntityModalInput {
  entityShortId: string;
  cacheId: string | undefined;
  isPro: boolean;
  openedBy: string;
  error?: string;
}

@Component({
  selector: 'export-entity-modal',
  templateUrl: 'export-entity-modal.component.pug',
  styleUrls: ['export-entity-modal.component.scss'],
})
export class ExportEntityModal implements OnInit {
  readonly blazeIcon = blazeIcon;

  @Output() close = new EventEmitter();
  @Input() data: ExportEntityModalInput = { isPro: false, entityShortId: '', cacheId: undefined, openedBy: '' };

  constructor(private track: ITrackService, private manage: ManageService) {
  }

  ngOnInit() {
    this.track.event(Analytics.OpenExportModal, { openedBy: this.data.openedBy });
  }

  get isPro() {
    return IS_HOSTED || this.data.isPro;
  }

  getUrl(format: 'png' | 'jpg' | 'psd' | 'zip') {
    return generateLiveEntityDownloadUrl(this.data.entityShortId, format);
  }

  exported(format: string) {
    this.track.event(Analytics.ExportImage, { format, eventSource: 'export-modal' });
  }

  onClose() {
    this.close.emit();
  }

  upgradeToPro() {
    this.manage.upgrade('export-modal-psd-button');
  }
}
