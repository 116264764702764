import type { Userflow } from 'userflow.js';
import { OnboardingService } from 'magma/services/onboarding.service';
import { UserService } from './user.service';
import { Injectable, NgZone } from '@angular/core';
import { ModalService } from './modal.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { options } from 'magma/common/data';
import { storageGetNumber, storageRemoveItem, storageSetNumber } from 'magma/services/storage';
import { isUserflowId, UserflowEvent, UserFlowId, SECOND } from 'magma/common/constants';
import { TeamsQuery } from './team.query';
import { EntityData, UserData } from '../../../shared/interfaces';
import { findByName } from 'magma/common/baseUtils';
import { setSettingsHideBrushes, setSettingsSliders, DEFAULT_MAIN_TOOLS_ORDER, setSettingsColumns, SettingsSliders } from 'magma/common/settings';
import { AuthService } from './auth.service';
import { ToastService } from 'magma/services/toast.service';
import { ErrorReporter } from 'magma/services/errorReporter';
import { EntityType, Settings, ToolId, ToolSource, Analytics } from 'magma/common/interfaces';
import { applySettingsPartial, scheduleSaveSettings } from 'magma/services/settingsService';
import { onboardingFlowToReadableString } from '../../../shared/analytics';
import { RealModel } from 'magma/services/real-model';
import { clearMask } from 'magma/common/mask';
import { Editor, cancelTool } from 'magma/services/editor';
import { moveLayerToBottom, ownLayer, selectLayer, addAndSelectLayer } from 'magma/services/layerActions';
import { AI_RENDER_TYPES, RenderTypeId } from 'magma/common/aiInterfaces';
import { logAction } from 'magma/common/actionLog';
import { toPromise } from 'shared/utils';
import { selectTool } from 'magma/services/otherActions';

enum UserIdleState {
  AwaitingFirstAction = 'awaiting-first-action',
  Active = 'active',
  Idle = 'idle',
  IdleWarned = 'idle-warned',
}

const CONFERENCE_MODE_TIMEOUT_STORAGE_KEY = 'conferenceModeTimeout';
const FLOW_RETRY_TIMEOUT = 2.5 * SECOND;
const FLOW_RETRY_MAX_RETIRES = 5;

const LOG = false;
function onboardingLog(...args: any[]) {
  DEVELOPMENT && LOG && console.log(`[onboarding]`, ...args);
}

const runInstantlyForAnonymousUsers = [UserFlowId.OnboardingMode];

@Injectable({ providedIn: 'root' })
export class PortalOnboardingService extends OnboardingService {
  private idleState = UserIdleState.Active;
  private idleTimeToLogout = 0;
  private conferenceTimeout: NodeJS.Timeout | undefined = undefined;
  private readonly flowStartingRetryFlows = new Map<UserFlowId, number>([]);
  private readonly windowEventHandlers = new Map<UserflowEvent, Function>();
  private readonly userflowObjectEventHandlers = new Map<string, (...args: any[]) => void>();

  constructor(
    protected zone: NgZone,
    protected http: HttpClient,
    protected router: Router,
    protected teamsQuery: TeamsQuery,
    protected auth: AuthService,
    private userService: UserService,
    private modals: ModalService,
    private toasts: ToastService,
    private errorReporter: ErrorReporter,
  ) {
    super();
    onboardingLog('Constructing PortalOnboardingService - user.hasOnboarding: ', this.userService.user?.hasOnboarding);

    this.windowEventHandlers.set(UserflowEvent.InvokeConferenceLogout, this.invokeConferenceLogout);
    this.windowEventHandlers.set(UserflowEvent.NavigateToDrawingByName, this.navigateToDrawingByName);
    this.windowEventHandlers.set(UserflowEvent.JumpToDemoDrawing, this.jumpToDemoDrawing);
    this.windowEventHandlers.set(UserflowEvent.SetEditorSettings, this.setEditorSettings);
    this.windowEventHandlers.set(UserflowEvent.CompletedOnboardingFlow, this.completeFlow);
    this.windowEventHandlers.set(UserflowEvent.ClearInpaintingMask, this.clearAiInpaintingMask);
    this.windowEventHandlers.set(UserflowEvent.CreateLayerAtTheBottom, this.createLayerAtTheBottom);
    this.windowEventHandlers.set(UserflowEvent.SelectLayerWithIndex, this.selectLayerWithIndex);
    this.windowEventHandlers.set(UserflowEvent.SelectAiRenderMode, this.selectAiRenderMode);
    this.windowEventHandlers.set(UserflowEvent.EnsureToolsInToolbar, this.ensureToolsInToolbar);
    this.windowEventHandlers.set(UserflowEvent.EnsureTwoColumns, this.ensureTwoColumns);
    this.windowEventHandlers.set(UserflowEvent.EnsureSequencePanel, this.ensureSequencePanelState);
    this.windowEventHandlers.set(UserflowEvent.EnsureSlidersSettings, this.ensureSliders);
    this.windowEventHandlers.set(UserflowEvent.RedirectTo, this.redirectTo);
    this.windowEventHandlers.set(UserflowEvent.SelectTool, this.setTool);

    this.userflowObjectEventHandlers.set('flowEnded', this.markUserActive);
    this.userflowObjectEventHandlers.set('checklistEnded', this.markUserActive);
  }

  get shouldInitConferenceTimeouts() {
    return (this.isConferenceMode && !this.conferenceTimeout);
  }

  async initConferenceTimeouts(user: UserData) {
    try {
      onboardingLog('initConferenceTimeouts');
      await this.authenticate(user, 'conferenceMode');
      this.revokeUserflowListeners();
      this.setupUserflowListeners();
      this.idleTimeToLogout = storageGetNumber(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY);
      this.scheduleNextIdleCheck(false);
    } catch (e) {
      DEVELOPMENT && console.error(e);
      this.errorReporter.reportError(e);
    }
  }

  pickDeployedOnboardingFlow(user: UserData) {
    if (!(this.userService.user && this.hasOnboarding && !this.isConferenceMode)) {
      return undefined;
    }

    const deployedFlows = user?.onboardingFlows ?? [];
    onboardingLog(`deployedOnboardingFlows: "${deployedFlows.map(onboardingFlowToReadableString).join(', ')}"`);

    for (const flow of deployedFlows) {
      if (this.canLaunchDeployedOnboardingFlow(flow)) {
        return flow;
      }
    }

    return undefined;
  }

  async launchDeployedOnboardingFlows(user: UserData, onboardingFlowToRun: UserFlowId) {
    try {
      await this.authenticate(user, 'deployedFlow');
      this.revokeUserflowListeners();
      this.setupUserflowListeners();
      this.startFlow(onboardingFlowToRun);
    } catch (e) {
      DEVELOPMENT && console.error(e);
      this.errorReporter.reportError(e);
    }
  }

  private canLaunchDeployedOnboardingFlow(id: UserFlowId) {
    if (runInstantlyForAnonymousUsers.includes(id)) {
      return true;
    } else {
      return this.auth.loggedIn;
    }
  }

  startConferenceMode(idleTimeToLogout: number) {
    this.idleTimeToLogout = idleTimeToLogout * SECOND;
    storageSetNumber(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY, this.idleTimeToLogout);
    this.performConferenceLogout().catch((e) => {
      this.errorReporter.reportError(e);
    });
  }

  stopConferenceMode() {
    this.idleTimeToLogout = 0;
    storageRemoveItem(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY);
    this.clearTimeouts();
  }

  private clearTimeouts() {
    this.idleState = UserIdleState.Active;
    this.conferenceTimeout && clearTimeout(this.conferenceTimeout);
    for (const key of this.flowStartingRetryFlows.keys()) {
      this.flowStartingRetryFlows.set(key, -1);
    }
  }

  private setupUserflowListeners() {
    for (const [event, handler] of this.windowEventHandlers.entries()) {
      window.addEventListener(event as any, handler as any);
    }
    for (const [event, handler] of this.userflowObjectEventHandlers.entries()) {
      this.userflow?.on(event, handler);
    }
    window.addEventListener('beforeunload', () => { this.revokeUserflowListeners(); });
  }

  private revokeUserflowListeners() {
    for (const [event, handler] of this.windowEventHandlers.entries()) {
      window.removeEventListener(event as any, handler as any);
    }
    for (const [event, handler] of this.userflowObjectEventHandlers.entries()) {
      this.userflow?.off(event, handler);
    }
  }

  startFlow(id: UserFlowId, retries = true) {
    if (!this.userflow) return;
    if (this.userflow.isIdentified() || !retries) {
      this.flowStartingRetryFlows.delete(id);
      this.userflow.start(id)
        .then(() => {
          return this.http.post(`/api/onboarding/flow/${id}/start`, {}).toPromise();
        })
        .catch((e) => {
          DEVELOPMENT && console.error(e);
          this.errorReporter.reportError(e);
        });
    } else if (retries) {
      const currentRetries = (this.flowStartingRetryFlows.get(id) ?? 0);
      if (currentRetries < FLOW_RETRY_MAX_RETIRES) {
        if (currentRetries === -1) {
          this.flowStartingRetryFlows.delete(id);
        } else {
          this.flowStartingRetryFlows.set(id, currentRetries + 1);
          setTimeout(() => { this.startFlow(id); }, FLOW_RETRY_TIMEOUT);
        }
      } else {
        this.flowStartingRetryFlows.delete(id);
        throw new Error(`Failed to start flow ${FLOW_RETRY_MAX_RETIRES} times (user not identified). Aborting next attempts.`);
      }
    }
  }

  get canStartConferenceMode() {
    return this.hasOnboarding && !!this.userService.isSuperAdmin();
  }

  get isConferenceMode() {
    return !!((!this.userService.user || this.hasOnboarding) && storageGetNumber(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY));
  }

  get hasOnboarding() {
    return !!this.userService.user?.hasOnboarding && !!options.userflowToken;
  }

  async ensureAuthenticated() {
    if (!this.userflow?.isIdentified()) {
      const user = this.userService.user;
      if (!options.userflowToken) throw new Error('Can\'t ensureUserflow because userflowToken is missing!');
      if (!user) throw new Error('Can\'t ensureUserflow because user is missing!');
      if (!user.hasOnboarding) throw new Error('This user can\'t use onboarding!');
      await this.authenticate(user, 'ensureAuthenticated');
      this.revokeUserflowListeners();
      this.setupUserflowListeners();
    }
  }

  private async authenticate(user: UserData, caller: string) {
    if (!options.userflowToken || !this.hasOnboarding) {
      this.stopConferenceMode();
    } else {
      const version = document.getElementsByTagName('html')[0].dataset.hash;
      if (!this.userflow) {
        logAction(`Loading userflow (caller: ${caller})`);
        this.model?.trackEvent(Analytics.LoadUserflow, { caller, version });
        this.userflow = (await import('userflow.js') as any as { default: Userflow }).default;
      }
      if (!this.userflow) throw new Error('Failed to load userflow.js');
      this.userflow.init(options.userflowToken);
      this.userflow.setBaseZIndex(1100); // to hide userflow behind our modals in particular "you're about to be logged out"
      return this.userflow.identify(user._id, {
        email: user.email,
        // locale_code: this.localeService.getLocale(), // we could localize userflow like this if we had higher plan
        domain: window.location.origin,
        teams: this.teamsQuery.getAll().map(t => t.slug).join(','),
        version,
      });
    }
  }

  async displayWarningModal() {
    this.idleState = UserIdleState.IdleWarned;
    const swapAccounts = await this.modals.idleLogoutWarning();
    if (swapAccounts) {
      return this.performConferenceLogout();
    } else {
      this.markUserActive();
      this.scheduleNextIdleCheck(false);
    }
  }

  async performConferenceLogout() {
    this.clearTimeouts();
    await this.userflow?.endAll();
    this.modals.closeAll();
    await this.auth.logout('');
  }

  startConferenceCycle() {
    this.idleTimeToLogout = storageGetNumber(CONFERENCE_MODE_TIMEOUT_STORAGE_KEY);
    this.http.post<{
      data: { email: string; password: string; }
    }>('api/onboarding/start-conference-cycle', {}).toPromise()
      .then((res) => {
        if (!res) throw new Error('Failed to obtain response from start-conference-cycle!');
        return this.auth.login({
          email: res.data.email,
          password: res.data.password,
          rememberMe: false,
        });
      })
      .then(() => {
        setSettingsSliders('full');
        setSettingsHideBrushes(false);
        this.idleState = UserIdleState.AwaitingFirstAction;
        this.scheduleNextIdleCheck(false);
        return Promise.all([
          this.authenticate(this.userService.user!, 'conferenceMode'),
          this.router.navigate(['/community-hub']),
        ]);
      })
      .then(() => this.startFlow(UserFlowId.ConferenceMode2))
      .catch((e) => {
        DEVELOPMENT && console.error(e);
        this.errorReporter.reportError(e);
      });
  }

  private scheduleNextIdleCheck(justWarned: boolean) {
    this.conferenceTimeout !== undefined && clearTimeout(this.conferenceTimeout);
    this.conferenceTimeout = setTimeout(() => {
      if (this.isConferenceMode && this.auth.loggedIn) {
        switch (this.idleState) {
          case UserIdleState.Idle: {
            this.displayWarningModal().catch((e) => {
              DEVELOPMENT && console.error(e);
              this.errorReporter.reportError(e);
            });
            break;
          }
          case UserIdleState.IdleWarned: {
            this.performConferenceLogout().catch((e) => {
              DEVELOPMENT && console.error(e);
              this.errorReporter.reportError(e);
            });
            break;
          }
          default: {
            if (this.idleState !== UserIdleState.AwaitingFirstAction) {
              this.idleState = UserIdleState.Idle;
            }
            break;
          }
        }
        this.scheduleNextIdleCheck(this.idleState === UserIdleState.IdleWarned);
      }
    }, justWarned ? this.idleTimeToLogout / 4 : this.idleTimeToLogout * 3 / 4);

    this.zone.runOutsideAngular(() => {
      window.addEventListener('pointerup', () => {
        this.markUserActive();
      }, { once: true });
    });
  }

  private readonly markUserActive = () => {
    this.idleState = UserIdleState.Active;
  };

  private readonly invokeConferenceLogout = () => {
    this.performConferenceLogout().catch((e) => {
      this.errorReporter.reportError(e);
    });
  };

  private readonly navigateToDrawingByName = async ({ detail: desiredDrawingName }: { detail: string; }) => {
    const team = this.teamsQuery.getActive();
    onboardingLog(`Navigating to drawing by name: '${desiredDrawingName}' (in team '${team?.name})'`);
    if (!team) throw new Error('No active team!');

    const { data: entities } = (await this.http.get(`api/teams/${team._id}/entities`).toPromise()) as {
      data: EntityData[]
    };
    const entity = findByName(entities, desiredDrawingName) as EntityData | undefined;
    if (!entity) throw new Error('Drawing not found!');
    if (entity.type !== EntityType.Drawing) throw new Error('Requested entity is not a drawing!');

    this.router.navigate(['d', entity.shortId]).catch((e) => {
      DEVELOPMENT && console.error(e);
      this.errorReporter.reportError(e);
    });
  };

  private readonly jumpToDemoDrawing = async ({ detail: desiredFlowId }: { detail: UserFlowId; }) => {
    try {
      onboardingLog(`Jumping to demo drawing for flow '${desiredFlowId}'`);
      if (!desiredFlowId) throw new Error(`Missing desiredFlowId in "${UserflowEvent.JumpToDemoDrawing}" event`);
      const shortId = await this.http.get(`/api/onboarding/flow/${desiredFlowId}/drawing`).toPromise();
      await this.router.navigate(['d', shortId]);
    } catch (e) {
      this.toasts.error({ message: 'Something went wrong when starting your onboarding flow.' });
      this.errorReporter.reportError('Something went wrong when starting your onboarding flow.', e, { desiredFlowId });
    }
  };

  private readonly setEditorSettings = async ({ detail }: { detail: Partial<Settings> }) => {
    try {
      onboardingLog(`Setting editor settings from userflow`, detail);
      if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.SetEditorSettings}"`);
      applySettingsPartial(this.model, detail);
    } catch (e) {
      this.toasts.error({ message: 'Something went wrong when continuing your onboarding flow.' });
      this.errorReporter.reportError('Something went wrong when continuing your onboarding flow.', e, { detail });
    }
  };

  private readonly clearAiInpaintingMask = () => {
    onboardingLog(`Clearing Ai inpainting mask`);
    const editor = (this.model as RealModel).editor;
    if (editor) {
      clearMask(editor.aiTool.inpaintMask);
      editor.apply(() => { });
    }
  };

  private readonly completeFlow = async ({ detail: flowId }: { detail: UserFlowId }) => {
    onboardingLog(`Completing flow '${onboardingFlowToReadableString(flowId)}'`);
    if (!isUserflowId(flowId)) throw new Error(`Missing/invalid flowId in "${UserflowEvent.CompletedOnboardingFlow}" event`);
    toPromise(this.http.post(`/api/onboarding/flow/${flowId}/complete`, {})).catch(e => DEVELOPMENT && console.error(e));
  };

  private readonly createLayerAtTheBottom = async () => {
    onboardingLog(`Creating layer at the bottom`);
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.CreateLayerAtTheBottom}"`);
    const editor = this.model.editor as Editor;
    if (editor.activeLayer) {
      await addAndSelectLayer(editor);
      setTimeout(() => {
        if (editor.activeLayer) {
          moveLayerToBottom(editor, editor.activeLayer);
          editor.apply(() => { });
        }
      }, 300);
    }
  };

  private readonly selectLayerWithIndex = async ({ detail: index }: { detail: number }) => {
    onboardingLog(`Selecting layer with index ${index}`);
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.SelectLayerWithIndex}"`);
    const { editor, drawing, user } = this.model;
    const layer = drawing.layers[index];
    if (layer) {
      await ownLayer(editor, layer, true);
      selectLayer(editor, user, drawing, layer, true);
    }
  };

  private readonly selectAiRenderMode = ({ detail: desiredRenderType }: { detail: RenderTypeId }) => {
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.SelectAiRenderMode}"`);
    const editor = this.model.editor as Editor;
    const validRenderTypeId = Object.values(RenderTypeId).includes(desiredRenderType);
    const renderType = AI_RENDER_TYPES.find(t => t.id === desiredRenderType);
    if (!validRenderTypeId || !renderType) {
      DEVELOPMENT && console.warn(`Unrecognised AI render mode - ${desiredRenderType}`);
      return;
    }
    editor.aiTool.selectRenderType(renderType);
  };

  private readonly ensureToolsInToolbar = ({ detail: tools }: { detail: ToolId[] }) => {
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.EnsureToolsInToolbar}"`);
    const { hiddenToolsOrder } = this.model.settings;

    for (const tool of tools) {
      const indexInHiddenTools = hiddenToolsOrder.findIndex((t) => t === tool);
      if (indexInHiddenTools !== -1) {
        const toolDefaultPosition = DEFAULT_MAIN_TOOLS_ORDER.findIndex((t) => t === tool);
        this.model.settings.mainToolsOrder.splice(toolDefaultPosition, 0, tool);
        this.model.settings.hiddenToolsOrder.splice(indexInHiddenTools, 1);
        scheduleSaveSettings(this.model);
      }
    }
  };

  private readonly ensureSequencePanelState = ({ detail: visible = true }: { detail?: boolean }) => {
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.EnsureSequencePanel}"`);
    if (this.model.isDrawingInProgress) {
      cancelTool(this.model.editor, 'ensureSequencePanelState');
    }
    this.model.settings.showSequence = visible;
    scheduleSaveSettings(this.model);
  };

  private readonly ensureSliders = ({ detail: newSlidersString }: { detail: SettingsSliders }) => {
    if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.EnsureSlidersSettings}"`);
    setSettingsSliders(newSlidersString);
    scheduleSaveSettings(this.model);
  };

  private readonly redirectTo = async ({ detail }: { detail: { flowId: UserFlowId, nr: number } }) => {
    try {
      const { flowId, nr } = detail;
      onboardingLog(`Onboarding redirection to '${nr}'`);
      if (!flowId) throw new Error(`Missing flowId in "${UserflowEvent.RedirectTo}" event`);
      if (nr === undefined) throw new Error(`Missing nr in "${UserflowEvent.RedirectTo}" event`);
      const redirections = await this.http.get<string[]>(`/api/onboarding/flow/${flowId}/redirections`).toPromise();
      const url = redirections?.[nr];
      if (!url) throw new Error(`Missing redirection (${nr}) for flow "${flowId}"`);
      const urlPath = url.trim().split('/');
      if (urlPath[0] === '') urlPath.unshift();
      await this.router.navigate(urlPath);
    } catch (e) {
      this.toasts.error({ message: 'Something went wrong when redirecting during your onboarding flow.' });
      this.errorReporter.reportError('Something went wrong when redirecting during your onboarding flow.', e, { flowId: detail.flowId, nr: detail.nr });
    }
  };

  private readonly setTool = async ({ detail: toolId }: { detail: ToolId }) => {
    try {
      onboardingLog(`Setting tool to '${toolId}'`);
      if (!this.model) throw new Error(`Missing model for onboarding flow "${UserflowEvent.SelectTool}"`);
      if (!toolId) throw new Error(`Missing flowId in "${UserflowEvent.SelectTool}" event`);
      const tool = this.model.editor.tools.find((t) => t.id === toolId);
      if (!tool) throw new Error(`Incorrect tool id`);
      selectTool(this.model.editor, tool, ToolSource.Auto);
    } catch (e) {
      this.toasts.error({ message: 'Something went wrong when selecting tool during your onboarding flow.' });
      this.errorReporter.reportError('Something went wrong when selecting tool during your onboarding flow.', e, { toolId });
    }
  };

  private readonly ensureTwoColumns = () => {
    setSettingsColumns(true);
  };
}
