import { Component } from '@angular/core';
import { ModalService } from 'services/modal.service';
import { faPlus, faLink } from 'magma/common/icons';
import { OnboardingService } from 'magma/services/onboarding.service';

@Component({
  selector: 'drawing-buddies-page',
  templateUrl: 'drawing-buddies-page.component.pug',
  styleUrls: ['drawing-buddies-page.component.scss'],
})
export class DrawingBuddiesPageComponent {
  readonly plusIcon = faPlus;
  readonly linkIcon = faLink;

  constructor(private modalService: ModalService) { }

  async createCanvas() {
    await this.modalService.createDrawing();
  }
}
