import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { LiveJam, JAM_THUMB_HEIGTH, JAM_THUMB_WIDTH, JamDrawing, JamVisibility } from 'magma/common/interfaces-jams';
import { getThumbPath } from 'magma/common/clientUtils';
import { getContext2d, loadImage } from 'magma/common/canvasUtils';
import { faUser, faEye, faEyeSlash, faExclamationTriangle, faUserCheck, faBolt, artJamsReadOnlyIcon } from 'magma/common/icons';
import { UserService } from 'services/user.service';

@Component({
  selector: 'jam-thumbnail',
  templateUrl: './jam-thumbnail.component.pug',
  styleUrls: ['./jam-thumbnail.component.scss', '../common.component.scss'],
})
export class JamThumbnailComponent {
  JamVisibility = JamVisibility;
  readonly THUMB_WIDTH = JAM_THUMB_WIDTH;
  readonly THUMB_HEIGHT = JAM_THUMB_HEIGTH;
  readonly usersIcon = faUser;
  readonly visibleIcon = faEye;
  readonly hiddenIcon = faEyeSlash;
  readonly forceHiddenIcon = faExclamationTriangle;
  readonly archivedIcon = artJamsReadOnlyIcon;
  readonly userJoinedIcon = faUserCheck;
  readonly featuredIcon = faBolt;

  @Input() jamDrawing!: JamDrawing;
  @ViewChild('thumb') thumbCanvas!: ElementRef<HTMLCanvasElement>;

  @Input() liveJamData!: LiveJam | undefined;
  connectedUsers = new Set<string>();

  constructor(private userService: UserService) {

  }

  async drawThumbnail(image: HTMLImageElement) {
    const context = getContext2d(this.thumbCanvas.nativeElement);
    const scale = Math.min(image.width / JAM_THUMB_WIDTH, image.height / JAM_THUMB_HEIGTH);
    const w = Math.ceil(image.width / scale);
    const h = Math.ceil(image.height / scale);

    context.clearRect(0, 0, JAM_THUMB_WIDTH, JAM_THUMB_HEIGTH);
    context.save();
    context.drawImage(image, (JAM_THUMB_WIDTH - w) / 2, (JAM_THUMB_HEIGTH - h) / 2, w, h);
    context.restore();
  }

  async ngAfterViewInit() {
    await this.drawThumbnail(await loadImage(getThumbPath(this.jamDrawing.shortId, this.jamDrawing.cacheId)));
  }

  ngOnChanges() {
    this.connectedUsers = new Set(this.liveJamData?.connectedUsers.map(u => u.id));
  }

  isLive() {
    return this.connectedUsers.size > 0 && !this.jamDrawing.jam.archived;
  }

  hasJoined() {
    return this.userService.userId && this.jamDrawing.jam.users.includes(this.userService.userId);
  }
}
