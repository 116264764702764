import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { take } from 'rxjs/operators';
import { UserService } from 'services/user.service';
import { routeToTeam } from 'shared/utils';
import { FeatureFlagService } from 'magma/services/feature-flag.service.interface';

@Injectable()
export class FeatureGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private featureService: FeatureFlagService,
  ) { }

  async canActivate(route: ActivatedRouteSnapshot) {
    await this.userService.user$.pipe(take(1)).toPromise();
    if (!route.data.feature) {
      DEVELOPMENT && console.warn(`You applied FeatureGuard on this route but you didn't specify route.data.feature which is required`);
      return false;
    } else if (this.featureService.isFeatureSupported(route.data.feature)) {
      return true;
    } else {
      void this.router.navigate(['/404']);
      return false;
    }
  }
}
