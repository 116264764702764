import { TeamMembersService } from 'services/team-members.service';
import { ToastService } from 'magma/services/toast.service';
import { Component, Input } from '@angular/core';
import { faKey, faLink, faUnlink, farExclamationCircle, faInfoCircle } from 'magma/common/icons';
import { InvitationService } from 'services/invitation.service';
import { TeamData, TeamInviteToken } from 'shared/interfaces';
import { TeamMembersQuery } from 'services/team-members.query';
import { UserService } from 'services/user.service';
import { signUps } from 'magma/common/data';

export interface TeamInviteMembersInput {
  team: TeamData;
  canManageInvites: boolean;
}

@Component({
  selector: 'team-invite-members',
  templateUrl: './team-invite-members.component.pug',
  styleUrls: ['./team-invite-members.component.scss'],
})
export class TeamInviteMembersComponent {
  readonly faKey = faKey;
  readonly faLink = faLink;
  readonly faUnlink = faUnlink;
  readonly warningIcon = faInfoCircle;
  readonly errorIcon = farExclamationCircle;
  readonly samlActive = signUps.includes('saml');
  invite: TeamInviteToken | undefined;
  isLoading = false;
  isRefreshing = false;

  @Input() insideModal = false;
  @Input() data!: TeamInviteMembersInput;

  constructor(
    private invitationService: InvitationService,
    private toastService: ToastService,
    private teamMembersService: TeamMembersService,
    private teamMembersQuery: TeamMembersQuery,
    private userService: UserService,
  ) { }

  async ngOnInit() {
    try {
      this.isLoading = true;
      this.invite = await this.invitationService.getInviteToken(this.data.team._id);
    } catch (e) {
      this.invite = undefined;
    }
    this.isLoading = false;
  }

  async generateToken() {
    try {
      this.isRefreshing = true;
      this.invite = await this.invitationService.refreshInviteToken(this.data.team._id);
      this.toastService.success({ message: 'New invite link has been created' });
    } catch (e) {
      this.toastService.error({ message: 'Failed to generate invite link', subtitle: e.message });
    }
    this.isRefreshing = false;
  }

  async changeInviteTokenAvailability(enabled: boolean) {
    try {
      const message = enabled ? 'Invite link was enabled' : 'Invite link was disabled';
      this.invite = await this.invitationService.updateInviteToken(this.data.team._id, { isActive: enabled });
      this.toastService.success({ message });
    } catch (e) {
      this.toastService.error({ message: 'Failed to update invite link', subtitle: e.message });
    }
  }

  async changeInviteTokenVisibility(visible: boolean) {
    try {
      this.invite = await this.invitationService.updateInviteToken(this.data.team._id, { isShared: visible });

      if (visible) {
        this.toastService.success({ message: 'Invite link will be visible for artspace members' });
      } else {
        this.toastService.success({ message: 'Invite link will no longer be visible for artspace members' });
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to update invite link', subtitle: e.message });
    }
  }

  get isTeamPublic() {
    return this.data.team.isPublic;
  }

  get inviteUrl() {
    return this.invite ? `${location.protocol}//${location.host}/invite/${this.invite.token}` : '';
  }
}
