import { disableSharingAsPng } from 'magma/common/data';
import { faTrash, faCloudDownload, faShare, faFileExport, faUserPlus, faCopy, faPen, faCode, faLink, faFolderTree, trashArrowUpIcon } from 'magma/common/icons';
import { EntityType, Permission, SvgIconDefinition } from 'magma/common/interfaces';
import { TeamService } from 'services/team.service';
import { EntityData, ProjectData } from 'shared/interfaces';

export interface EntityToolbarButton {
  id: string;
  title?: string;
  clickedTitle?: string;
  tooltip?: string;
  icon?: SvgIconDefinition;
  disabled?: boolean;
  clicked?: boolean;
  allowClick?: boolean;
}

export function getToolbar(entity: EntityData | EntityData[], teamService: TeamService, project: ProjectData | undefined, isBinFolder = false): EntityToolbarButton[] {
  const DIVIDER = { id: '' };
  const READONLY = { disabled: true, tooltip: 'You have read-only access. Please request write access from team owner' };

  if (Array.isArray(entity)) {
    if (entity.length === 1) {
      entity = entity[0];
    } else {
      const entities = entity;
      const areEntitiesReadOnly = (flag: Permission) => entities.some(e => !teamService.hasPermissionFlag(flag, project, e)) ? READONLY : {};
      const type = entities.every(e => e.type == entities[0].type) ? entities[0].type : undefined;

      return [
        { id: 'clone', title: 'Duplicate all', icon: faCopy, ...areEntitiesReadOnly(Permission.CanDuplicateEntities) },
        ...(type === EntityType.Drawing) ? [
          { id: 'download-png', title: 'Download all', icon: faCloudDownload },
        ] : [],
        DIVIDER,
        { id: 'bin', title: 'Move all to bin', icon: faTrash, ...areEntitiesReadOnly(Permission.CanDeleteEntities) },
        ...(type === EntityType.Drawing) ? [
          DIVIDER,
          { id: 'copy-link', title: 'Copy drawing links', icon: faLink, clickedTitle: 'Copied to clipboard' },
        ] : [],
      ];
    }
  }

  const isReadOnly = (flag: Permission, e: EntityData) => !teamService.hasPermissionFlag(flag, project, e) ? READONLY : {};

  if (isBinFolder) {
    switch (entity.type) {
      case EntityType.Drawing:
      case EntityType.Board:
        return [
          { id: 'restore', title: 'Restore', icon: trashArrowUpIcon, ...isReadOnly(Permission.CanDeleteEntities, entity) },
          { id: 'move', title: 'Move', icon: faFolderTree, ...isReadOnly(Permission.CanUpdateEntities, entity), ...isReadOnly(Permission.CanDeleteEntities, entity) },
          { id: 'delete', title: 'Delete', icon: faTrash, ...isReadOnly(Permission.CanDeleteEntities, entity), ...isReadOnly(Permission.CanDeleteEntitiesPermanently, entity), allowClick: true },
        ];
      case EntityType.Folder:
        return [
          { id: 'restore', title: 'Restore', icon: trashArrowUpIcon, ...isReadOnly(Permission.CanDeleteEntities, entity) },
          { id: 'move', title: 'Move', icon: faFolderTree, ...isReadOnly(Permission.CanUpdateEntities, entity), ...isReadOnly(Permission.CanDeleteEntities, entity) },
          { id: 'delete', title: 'Delete', icon: faTrash, ...isReadOnly(Permission.CanDeleteEntities, entity), ...isReadOnly(Permission.CanDeleteEntitiesPermanently, entity), allowClick: true },
        ];
      default:
        return [];
    }
  }

  switch (entity.type) {
    case EntityType.Flowchart:
      return [
        { id: 'share', title: 'Share', icon: faUserPlus },
        { id: 'embed', title: 'Embed', icon: faCode },
        { id: 'download', title: 'Download', icon: faCloudDownload },
        { id: 'move', title: 'Move', icon: faFolderTree, ...isReadOnly(Permission.CanUpdateEntities, entity) },
        { id: 'clone', title: 'Duplicate', icon: faCopy, ...isReadOnly(Permission.CanDuplicateEntities, entity) },
        { id: 'delete', title: 'Delete', icon: faTrash, ...isReadOnly(Permission.CanDeleteEntities, entity) },
      ];
    case EntityType.Drawing:
    case EntityType.Board:
      return [
        { id: 'share', title: 'Share', icon: faUserPlus },
        DIVIDER,
        { id: 'rename', title: 'Rename', icon: faPen, ...isReadOnly(Permission.CanUpdateEntities, entity) },
        { id: 'clone', title: 'Duplicate', icon: faCopy, ...isReadOnly(Permission.CanDuplicateEntities, entity) },
        { id: 'move', title: 'Move', icon: faFolderTree, ...isReadOnly(Permission.CanUpdateEntities, entity) },
        DIVIDER,
        { id: 'download-png', title: 'Download', icon: faCloudDownload, ...isReadOnly(Permission.CanViewEntities, entity) },
        ...(disableSharingAsPng ? [] : [{ id: 'share-as-image', title: 'Share as PNG', icon: faShare, ...isReadOnly(Permission.CanExportEntityAsImage, entity) }]),
        { id: 'export', title: 'Export...', icon: faFileExport, ...isReadOnly(Permission.CanExportEntityAsImage, entity) },
        DIVIDER,
        { id: 'bin', title: 'Move to bin', icon: faTrash, ...isReadOnly(Permission.CanDeleteEntities, entity) },
        DIVIDER,
        { id: 'copy-link', title: 'Copy drawing link', icon: faLink, clickedTitle: 'Copied to clipboard' },
      ];
    case EntityType.Folder:
      return [
        { id: 'rename', title: 'Rename', icon: faPen, ...isReadOnly(Permission.CanUpdateEntities, entity) },
        { id: 'move', title: 'Move', icon: faFolderTree, ...isReadOnly(Permission.CanUpdateEntities, entity) },
        { id: 'clone', title: 'Duplicate', icon: faCopy, ...isReadOnly(Permission.CanDuplicateEntities, entity) },
        DIVIDER,
        { id: 'bin', title: 'Move to bin', icon: faTrash, ...isReadOnly(Permission.CanDeleteEntities, entity) },
      ];
    default:
      return [];
  }
}
