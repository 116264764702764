import { Injectable } from '@angular/core';
import { Modals } from './modals';
import { Model } from './model';
import { CUSTOMER_INSIGHTS_GROUPS, CUSTOMER_INSIGHTS_POSTPONE, CUSTOMER_INSIGHTS_POSTPONE_PERIOD, CUSTOMER_INSIGHTS_REQUEST, CUSTOMER_INSIGHTS_STATUS_PREFIX, CustomerInsightGroups } from '../common/constants';
import { Analytics } from '../common/interfaces';
import { ITrackService } from './track.service.interface';

@Injectable({ providedIn: 'root' })
export class CustomerInsightsService {
  constructor(private modals: Modals, private model: Model, private track: ITrackService) { }

  shouldShowModal() {
    const group = this.getGroup();
    if (!group) return false;

    const status = this.getStatus();

    if (status === CUSTOMER_INSIGHTS_REQUEST) return true;

    if (status?.startsWith(CUSTOMER_INSIGHTS_POSTPONE)) {
      const today = new Date().getTime();
      const timestamp = +status.replace(CUSTOMER_INSIGHTS_POSTPONE, '');
      if (today - timestamp > CUSTOMER_INSIGHTS_POSTPONE_PERIOD) {
        return true;
      }
    }

    return false;
  }

  getGroup() {
    // not perfect solution, it will user first group if there will be two of them (this should not be possbile)
    return this.model.user.tags?.filter((tag: any) => CUSTOMER_INSIGHTS_GROUPS.includes(tag))[0] as CustomerInsightGroups | undefined;
  }

  getStatus() {
    return this.model.user.tags?.filter((tag) => tag.startsWith(CUSTOMER_INSIGHTS_STATUS_PREFIX))[0];
  }

  showModal() {
    const group = this.getGroup();
    if (group) {
      void this.modals.customerInsightsInvite(group);
      this.track.event(Analytics.CustomerInsightsShowModal);
    }
  }
}
