import { Component, EventEmitter, Input, Output } from '@angular/core';
import { sparkIcon, blazeIcon, farExclamationCircle, faInfoCircle } from 'magma/common/icons';
import { DAY } from 'magma/common/constants';
import { FreePlan, SubscriptionPlanType } from 'shared/billing';

@Component({
  selector: 'plan-box-info',
  templateUrl: 'plan-box-info.component.pug',
  styleUrls: ['plan-box-info.component.scss'],
})
export class PlanBoxInfoComponent {
  sparkIcon = sparkIcon;
  blazeIcon = blazeIcon;
  infoIcon = faInfoCircle;
  errorIcon = farExclamationCircle;

  @Input() interval!: string;
  @Input() plan!: SubscriptionPlanType;

  @Input() isFailed = false;
  @Input() isTrial = false;
  @Input() isCancelled = false;
  @Input() isIndividual = false;

  @Input() validUntil?: Date;
  @Input() nextChargeDate!: Date;

  @Output() upgrade = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() restart = new EventEmitter<void>();
  @Output() changeInterval = new EventEmitter<void>();

  get trialDaysLeft() {
    return Math.ceil((new Date(this.validUntil!).getTime() - Date.now()) / DAY);
  }

  get isFree() {
    return this.plan.name === FreePlan.name;
  }

  get name() {
    return this.plan.name;
  }

  get price() {
    return this.plan.monthly / 100;
  }
}
