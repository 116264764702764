import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MAX_ENTITY_NAME_LENGTH } from 'magma/common/constants';
import { faExternalLink, faInfoCircle } from 'magma/common/icons';
import { JAMS_GENERES, JAMS_USER_GUIDE_URL, JamVisibility, JamsModalInput, JamsModalOutput } from 'magma/common/interfaces-jams';

@Component({
  selector: 'jams-modal',
  templateUrl: 'jams-modal.component.pug',
  styleUrls: ['./jams-modal.component.scss'],
})
export class JamsModalComponent {
  JamVisibility = JamVisibility;
  readonly JAMS_GENERES = JAMS_GENERES;
  readonly MAX_TITLE_LENGTH = MAX_ENTITY_NAME_LENGTH;
  readonly MAX_DESCRIPTION_LENGTH = 2500;
  readonly MAX_RULES_LENGTH = 2500;
  readonly MAX_TAGS_LENGTH = 20;
  readonly MAX_GENRE_LENGTH = 5;
  readonly infoIcon = faInfoCircle;
  readonly faExternalLink = faExternalLink;
  readonly JAMS_USER_GUIDE_URL = JAMS_USER_GUIDE_URL;
  @Output() close = new EventEmitter<JamsModalOutput>();
  @Input() data: JamsModalInput | undefined;

  visibilityOptions = [JamVisibility.HIDDEN, JamVisibility.PUBLIC];
  isNew = false;
  requireAcceptingRules = false;

  title = '';
  tags: Set<string> = new Set();
  genre: Set<string> = new Set();
  description = '';
  rules = '';
  nsfw = false;
  visibility: JamVisibility.HIDDEN | JamVisibility.PUBLIC = JamVisibility.PUBLIC;

  constructor() { }

  ngOnInit() {
    const jamDrawing = this.data?.jamDrawing;
    if (jamDrawing) {
      this.title = jamDrawing.name;
      this.tags = new Set(jamDrawing.tags);
      this.genre = new Set(jamDrawing.jam.genre);
      this.description = jamDrawing.jam.description ?? '';
      this.nsfw = jamDrawing.jam.nsfw;
      this.rules = jamDrawing.jam.rules ?? '';
      this.visibility = jamDrawing.jam.visibility === JamVisibility.FORCE_HIDDEN ? JamVisibility.HIDDEN : jamDrawing.jam.visibility;
      this.requireAcceptingRules = !!jamDrawing.jam.rules?.length;
    } else {
      this.isNew = true;
    }
  }

  get header() {
    return this.isNew ? 'Create and publish new Art Jam' : 'Edit Art Jam';
  }

  get saveLabel() {
    return this.isNew ? 'Publish Jam' : 'Save Jam';
  }

  onSave() {
    if (!this.isValid()) return;

    if (this.isNew) {
      this.close.emit({
        create: {
          name: this.title,
          tags: Array.from(this.tags),
          jam: {
            description: this.description,
            rules: this.requireAcceptingRules ? this.rules : '',
            nsfw: this.nsfw,
            genre: Array.from(this.genre),
            visibility: JamVisibility.PUBLIC
          }
        }
      });
    } else {
      this.close.emit({
        update: {
          name: this.title,
          tags: Array.from(this.tags),
          jam: {
            description: this.description,
            rules: this.requireAcceptingRules ? this.rules : '',
            nsfw: this.nsfw,
            genre: Array.from(this.genre),
            visibility: this.data?.jamDrawing?.jam.visibility !== JamVisibility.FORCE_HIDDEN ? this.visibility : undefined,
          }
        }
      });
    }
  }

  onClose() {
    this.close.emit(undefined);
  }

  isValid() {
    return this.title.length > 0 && this.title.length <= this.MAX_TITLE_LENGTH &&
      this.description.length <= this.MAX_DESCRIPTION_LENGTH &&
      this.rules.length <= this.MAX_RULES_LENGTH &&
      this.tags.size <= this.MAX_TAGS_LENGTH &&
      this.genre.size <= this.MAX_GENRE_LENGTH;
  }
}
