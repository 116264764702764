import { Component, NgZone } from '@angular/core';
import { revisionThumbPath } from 'magma/common/clientUtils';
import { faExternalLink } from 'magma/generated/fa-icons/faExternalLink';
import { PublishersService } from 'magma/services/publishersService';
import { faInfoCircle } from 'magma/generated/fa-icons/faInfoCircle';
import { SimpleTableParams, SortOrders, SimpleTableColumn, DrawingRevision, LicenseTermId } from 'magma/common/interfaces';

@Component({
  selector: 'registered-ips-table',
  templateUrl: 'registered-ips-table.component.pug',
  styleUrls: ['registered-ips-table.component.scss']
})
export class RegisteredIpsTableComponent {
  readonly viewExternalIcon = faExternalLink;
  readonly infoCircle = faInfoCircle;
  readonly warningIcon = faInfoCircle;
  readonly revisionThumbPath = revisionThumbPath;
  readonly spTableColumns: SimpleTableColumn[] = [
    { name: 'Artwork', inMobileView: true, sortable: true, key: 'name' },
    { name: 'Published', inMobileView: true, sortable: true, key: 'date' },
    { name: 'Artists', inMobileView: true },
    { name: 'License', inMobileView: true },
    { name: 'Actions', inMobileView: true },
  ];

  readonly defaultParams: SimpleTableParams = {
    currentPage: 0,
    pageSize: 10,
    sortBy: '',
    sortDirection: SortOrders.Descending,
  };

  constructor(
    private readonly publishers: PublishersService,
    private readonly zone: NgZone,
  ) {
    this.fetchIps();
  }

  isLoading = false;
  ips: DrawingRevision[] = [];
  currentPage = 1;
  totalPages = 1;
  totalIps = 0;
  ipsPerPage = 10;
  sortBy: 'date' | 'name' = 'date';
  sortOrder = SortOrders.Descending;

  fetchIps() {
    this.isLoading = true;
    this.publishers.getMyRegisteredIps(this.ipsPerPage, (this.currentPage - 1) * this.ipsPerPage, this.sortBy, this.sortOrder)
      .then(({ revisions, count }) => {
        this.ips = revisions;
        this.totalIps = count;
        this.totalPages = Math.ceil(count / this.ipsPerPage);
      })
      .catch((e) => DEVELOPMENT && console.error(e))
      .finally(() => {
        this.isLoading = false;
      });
  }

  onSort(e: SimpleTableParams) {
    this.currentPage = e.currentPage;
    this.ipsPerPage = e.pageSize;
    this.sortBy = e.sortBy as any;
    this.sortOrder = e.sortDirection;
    this.fetchIps();
  }

  revisionLink(shortId: string) {
    const url = new URL(window.location.href);
    url.pathname = `/d/${shortId}`;
    return url.href;
  }

  publishedAt(revision: DrawingRevision) {
    return revision.publishedIn!.StoryProtocol!.publishedAt;
  }

  getRevisionTitle(revision: DrawingRevision) {
    return revision.publishedIn!.StoryProtocol!.ipAssetName;
  }

  isCommercial(revision: DrawingRevision) {
    return revision.publishedIn!.StoryProtocol!.licenseTermId !== LicenseTermId.NonCommercialRemix;
  }

  getUrl(revision: DrawingRevision) {
    return revision.publishedIn!.StoryProtocol!.url;
  }
}
