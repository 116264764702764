import { Component, EventEmitter, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { random } from 'lodash';
import { DAY, YEAR } from 'magma/common/constants';
import { farExclamationTriangle } from 'magma/common/icons';
import { Analytics } from 'magma/common/interfaces';
import { fromNow } from 'magma/common/utils';
import { ToastService } from 'magma/services/toast.service';
import { ITrackService } from 'magma/services/track.service.interface';
import { UserService } from 'services/user.service';


@UntilDestroy()
@Component({
  selector: 'user-birthdate-modal',
  templateUrl: 'user-birthdate-modal.component.pug',
  styleUrls: ['./user-birthdate-modal.component.scss'],
})
export class UserBirthdateModalComponent {
  readonly farExclamationTriangle = farExclamationTriangle;
  readonly TERMS_OF_USE_URL = 'https://magma.com/terms';
  readonly PRICING_PAGE_URL = 'https://magma.com/pricing';

  day = 1;
  month = 1;
  year = 1;

  below13 = false;

  @Output() close = new EventEmitter<Date | undefined>();

  constructor(private toastService: ToastService, private userService: UserService, private track: ITrackService) {
    const today = new Date();
    this.day = today.getDate();
    this.month = today.getMonth() + 1;
    this.year = today.getFullYear();
  }

  async onSave() {
    if (!this.canCreate()) return;
    try {
      const birthdate = new Date(this.year, this.month - 1, this.day);
      this.obfuscateDate(birthdate);

      await this.userService.save({ birthdate });
      this.toastService.success({ message: 'Your birthdate has been successfully updated.' });

      if (birthdate.getTime() > fromNow(-13 * YEAR).getTime()) {
        this.below13 = true;
        this.track.event(Analytics.ViewTermsOfUseModal);
      } else {
        this.close.emit(birthdate);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to update your birthdate report', subtitle: e.message });
    }
  }

  private getDateYearsAgo(yearsAgo: number) {
    const today = new Date();
    const date = new Date();
    date.setFullYear(today.getFullYear() - yearsAgo);
    return date;
  }

  private obfuscateDate(date: Date) {
    const timestamp = date.getTime();
    const age13timestamp = this.getDateYearsAgo(13).getTime();
    const age15timestamp = this.getDateYearsAgo(15).getTime();
    const age18timestamp = this.getDateYearsAgo(18).getTime();

    if (timestamp > age13timestamp) {
      date.setTime(date.getTime() + random(1, 30) * DAY);
    } else if (timestamp < age13timestamp && timestamp > age15timestamp) {
      date.setTime(date.getTime() - random(1, 30) * DAY);
    } else if (timestamp < age15timestamp && timestamp > age18timestamp) {
      date.setTime(date.getTime() + random(1, 30) * DAY);
    } else {
      date.setTime(date.getTime() - random(1, 30) * DAY);
    }
  }

  canCreate() {
    return this.day && Number.isFinite(Number(this.day)) && this.month && Number.isFinite(Number(this.month)) && this.year && Number.isFinite(Number(this.year));
  }

  onClose() {
    this.close.emit(undefined);
  }
}
