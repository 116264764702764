import { animate, AnimationBuilder, AnimationMetadata, AnimationPlayer, style } from '@angular/animations';
import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, merge } from 'rxjs';
import { TopLevelComponentLoaderService } from 'services/top-level-component-loader.service';
import { getAllParentsOfElement } from 'util/util';

// @ignore-translation
export type TopLevelPositions =
  'top left' | 'top center' | 'top right' |
  'center left' | 'center center' | 'center right' |
  'bottom left' | 'bottom center' | 'bottom right';

// @ignore-translation
@UntilDestroy()
@Directive({
  selector: '[topLevel]',
})
export class TopLevelDirective implements AfterViewInit, OnDestroy {
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private builder: AnimationBuilder,
    private topLevelComponentLoaderService: TopLevelComponentLoaderService
  ) { }

  @Input() topLevelParentForPosition: HTMLElement | null = null;
  @Input() topLevelPosition: TopLevelPositions = 'center center';

  private player: AnimationPlayer | null = null;
  private initialBoundingRect: DOMRect | null = null;

  ngAfterViewInit() {
    this.setupElementAtTopLevel();
  }

  getParentElement() {
    return (this.topLevelParentForPosition || this.elementRef.nativeElement.parentElement) as Element;
  }

  ngOnDestroy() {
    this.playAnimation('hide');
  }

  setupElementAtTopLevel() {
    const nativeElementForPosition = this.topLevelParentForPosition || this.elementRef.nativeElement;

    if (this.topLevelParentForPosition) {
      this.setupScrollListener();
    }

    this.initialBoundingRect = nativeElementForPosition.getBoundingClientRect();

    const nativeElement = this.elementRef.nativeElement;
    this.topLevelComponentLoaderService.container.appendChild(nativeElement);

    nativeElement.style.position = 'absolute';
    nativeElement.style.visibility = 'visible';

    this.updatePosition();

    this.playAnimation('show');
  }

  setupScrollListener() {
    const allParents = getAllParentsOfElement(this.getParentElement() as HTMLElement);

    merge(
      ...allParents
        .map((element) => {
          return fromEvent(element!, 'scroll');
        }),
    ).pipe(
      untilDestroyed(this),
    ).subscribe((event) => {
      this.updatePosition(event);
    });
  }

  updatePosition(event?: any) {
    const scrollX = event?.target.scrollLeft || 0;
    const scrollY = event?.target.scrollTop || 0;

    const alignmentAdjustments = this.getAdjustmentsForAlignment();

    const newX = (this.initialBoundingRect?.x || 0) + alignmentAdjustments.x - scrollX;
    const newY = (this.initialBoundingRect?.y || 0) + alignmentAdjustments.y - scrollY;

    const transform = `translateX(${newX}px) translateY(${newY}px)`;
    const width = `${this.initialBoundingRect?.width}px`;
    const height = `${this.initialBoundingRect?.height}px`;

    this.elementRef.nativeElement.style.transform = transform;
    this.elementRef.nativeElement.style.width = width;
    this.elementRef.nativeElement.style.height = height;
  }

  private getAdjustmentsForAlignment() {
    const adjustments = { x: 0, y: 0 };

    if (!this.topLevelParentForPosition) {
      return adjustments;
    }

    const parentBoundingRect = this.topLevelParentForPosition.getBoundingClientRect();

    switch (this.topLevelPosition) {
      case 'center center':
        adjustments.x = parentBoundingRect.width / 2;
        adjustments.y = parentBoundingRect.height / 2;
        break;

      case 'bottom center':
        adjustments.x = parentBoundingRect.width / 2;
        adjustments.y = parentBoundingRect.height;
        break;

      case 'bottom left':
        adjustments.x = 0;
        adjustments.y = parentBoundingRect.height;
        break;

      // TODO: calculations for more cases to be added here

      default: // top left
        break;
    }

    return adjustments;
  }

  private playAnimation(state: 'show' | 'hide') {
    if (this.player) {
      this.player.destroy();
    }

    const animationFactory = this.builder.build(state === 'show' ? this.fadeIn() : this.fadeOut());
    this.player = animationFactory.create(this.elementRef.nativeElement);
    this.player.play();
  }

  private fadeIn(): AnimationMetadata[] {
    return [
      style({ opacity: 0 }),
      animate('200ms ease-in', style({ opacity: 1 })),
    ];
  }

  private fadeOut(): AnimationMetadata[] {
    return [
      style({ opacity: '*' }),
      animate('200ms ease-in', style({ opacity: 0 })),
    ];
  }
}
