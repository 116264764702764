import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { faChevronDown, faTimes } from 'magma/common/icons';
import { ContextMenu } from 'magma/components/shared/directives/contextMenu';
import { Dropdown, DropdownMenu } from 'magma/components/shared/directives/dropdown';

@Component({
  selector: 'chips',
  templateUrl: './chips.component.pug',
  styleUrls: ['./chips.component.scss']
})
export class ChipsComponent {
  readonly faTimes = faTimes;
  readonly faChevronDown = faChevronDown;
  @Input() limit: number | undefined;
  @Input() chips: Set<string> = new Set();
  @Input() placeholder: string | undefined;
  @Output() chipsChanged = new EventEmitter<Set<string>>();
  @ViewChild('chip') chip!: ElementRef<HTMLDivElement>;
  @ViewChild('dropdown') dropdown!: DropdownMenu;

  @Input() options: string[] | undefined;

  private inputLength = 0;

  showPlaceholder() {
    return this.placeholder && this.chips.size === 0 && this.inputLength === 0;
  }

  onKeyUp() {
    this.inputLength = this.chip.nativeElement.textContent?.length ?? 0;
  }

  onKeyPress(event: KeyboardEvent) {
    const input = this.chip.nativeElement;

    if (this.limit !== undefined && this.chips.size >= this.limit) {
      event.preventDefault();
      input.textContent = '';
      return;
    }
    if (event.key === 'Enter' && event.shiftKey) {
      event.preventDefault();
      return;
    }
    if (event.key === 'Enter' && input.textContent) {
      event.preventDefault();
      this.chips.add(input.textContent.trim());
      input.textContent = '';
      this.chipsChanged.emit(this.chips);
    }
  }

  toggleChip(chip: string, selected: boolean) {
    if (selected) {
      if (this.limit && this.chips.size >= this.limit) return;
      this.chips.add(chip);
    } else {
      this.chips.delete(chip);
    }
    this.chipsChanged.emit(this.chips);
  }

  deleteChip(chip: string) {
    this.chips.delete(chip);
    this.chipsChanged.emit(this.chips);
  }

  openOptions() {
    this.dropdown.open(true);
  }
}
