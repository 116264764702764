import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { xmarkIcon } from 'magma/common/icons';
import { Analytics, DEFAULT_SIMPLE_TABLE_PARAMS, ReferralStatus, SimpleTableColumn, SimpleTableParams, SortOrders } from 'magma/common/interfaces';
import { belowBreakpointMD } from 'magma/common/utils';
import { faCheck } from 'magma/generated/fa-icons';
import { ToastService } from 'magma/services/toast.service';
import { ITrackService } from 'magma/services/track.service.interface';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SimpleTableService } from 'services/simple-table.service';
import { UserService } from 'services/user.service';

export interface ReferredUser {
  name: string;
  avatar: string;
  referralStatus: ReferralStatus;
  createdAt: string;
}

@UntilDestroy()
@Component({
  selector: 'account-referrals',
  templateUrl: './account-referrals.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './account-referrals.component.scss',
  ],
})
export class AccountReferralsComponent {
  readonly faCheck = faCheck;
  readonly rejectIcon = xmarkIcon;

  showCopied = false;
  referredUsers: ReferredUser[] = [];
  _referredUsers: ReferredUser[] = [];
  simpleTableParams = {...DEFAULT_SIMPLE_TABLE_PARAMS, sortBy: 'createdAt', sortDirection: SortOrders.Descending};
  code: string | undefined;
  columns: SimpleTableColumn[] = [
    { name: 'Username', inMobileView: true },
    { name: 'Joined', key: 'createdAt', sortable: true },
    { name: 'Reward Granted?', inMobileView: true, ignoreAction: true },
  ];
  isLoading = false;

  private reloadReferral$ = new BehaviorSubject(null);

  constructor(private userService: UserService, private toastService: ToastService, private simpleTableService: SimpleTableService, private trackService: ITrackService) {
    this.reloadReferral$.pipe(
      tap(() => this.isLoading = true),
      switchMap(() => 
        this.userService.getReferralCode().pipe(
          switchMap(referralCode => 
            this.userService.getReferredUsers().pipe(
              map(users => ({ referralCode, users }))
            )
          )
        )
      ),
      untilDestroyed(this),
    ).subscribe(({ referralCode, users }) => {
      this.code = referralCode;
      this._referredUsers = users;
      this.syncTable();
      this.isLoading = false;
    });
  }

  copyTrigger() {
    this.showCopied = true;
    setTimeout(() => {
      this.showCopied = false;
    }, 4000);
  }

  get referralLink() {
    const root = `${location.protocol}//${location.host}/`;
    return this.code ? `${root}create-account?ref=${this.code}` : '';
  }

  get numberOfPages() {
    return Math.ceil((this.simpleTableParams.total || 0) / this.simpleTableParams.pageSize);
  }

  get isMobileView() {
    return belowBreakpointMD();
  }

  sortingTable(data: SimpleTableParams) {
    this.simpleTableParams = { ...data };
    this.syncTable();
  }

  syncTable() {
    const result = this.simpleTableService.sortAndPaginateArray(this._referredUsers, this.simpleTableParams.sortBy, this.simpleTableParams.sortDirection, this.simpleTableParams.currentPage, this.simpleTableParams.pageSize);
    this.referredUsers = result.data;
    this.simpleTableParams.total = result.totalItems;
  }

  async generateReferralCode() {
    try {
      this.code = await this.userService.generateReferralCode().toPromise();
      this.trackService.event(Analytics.UniqueReferralLinkGenerated, { code: this.code });
      this.toastService.success({ message: 'New rererral link generated successfully' });
    } catch(e) {
      this.toastService.error({ message: 'Failed to regenerate Referral code', subtitle: e.message });
      DEVELOPMENT && console.error(e);
    }
  }
}
