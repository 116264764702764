import { Component, EventEmitter, Output } from '@angular/core';
import { BrushToolSettings, ToolResourceGroup } from 'magma/common/interfaces';
import { faChevronDown, faChevronRight, faGift, farCheck } from 'magma/generated/fa-icons';
import { ManageService } from 'magma/services/manageService';
import { UserService } from 'services/user.service';

@Component({
  selector: 'new-brushes-modal',
  templateUrl: 'new-brushes-modal.component.pug',
  styleUrls: ['new-brushes-modal.component.scss'],
})
export class NewBrushesModal {
  readonly giftIcon = faGift;
  readonly checkIcon = farCheck;
  readonly chevronDown = faChevronDown;
  readonly chevronRight = faChevronRight;
  brushes: ToolResourceGroup<BrushToolSettings>[] = BRUSHES;
  @Output() close = new EventEmitter<void>();
  private hiddenGroups = new Set<string>();

  constructor(private userService: UserService, private manage: ManageService) {
  }

  get hasPro() {
    return !!this.userService.user?.pro;
  }

  async upgrade() {
    this.manage.upgrade('brushes');
  }

  closeModal() {
    this.close.emit();
  }

  showGroup(id: string) {
    return !this.hiddenGroups.has(id);
  }

  toggleGroup(id: string) {
    if (this.hiddenGroups.has(id)) {
      this.hiddenGroups.delete(id);
    } else {
      this.hiddenGroups.add(id);
    }
  }
}

const BRUSHES: any = [{
  'id': 'magma_rou',
  'name': 'Round',
  'items': [{
    'name': 'ink',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': '',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }, {
    'name': 'soft ink',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0.2,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 0.5,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': '',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }, {
    'name': 'soft',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': true,
    'opacity': 0.5,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 0,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': '',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }, {
    'name': 'softer',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 0.3,
    'flowPressure': true,
    'opacity': 0.5,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 0,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': '',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }]
}, {
  'id': 'magma_bal',
  'name': 'Block and Line',
  'items': [{
    'name': 'Sharp Horizontal',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0.6,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.009550561797752809,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_bal_0',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 80
  }, {
    'name': 'Sharp Vertical',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0.69,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.0026075619295958283,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_bal_1',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 80
  }, {
    'name': 'Thin Flat',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.0037117903930131008,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_bal_2',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 100
  }, {
    'name': 'Flat Scatter',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.0037117903930131008,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 2.09,
    'tangentSpread': 0,
    'shape': 'magma_bal_2',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 100
  }, {
    'name': 'Flat Repeat',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.10838427947598255,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_bal_2',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 100
  }, {
    'name': 'Flat Scatter Follow',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.10838427947598255,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 0.62,
    'tangentSpread': 0,
    'shape': 'magma_bal_2',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': true,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 100
  }]
}, {
  'id': 'magma_sac',
  'name': 'Sketch and Chalk',
  'items': [{
    'name': 'Sketchy Round',
    'sizePressure': true,
    'sizeJitter': 0.03,
    'minSize': 0,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.031701346389228884,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_sac_2',
    'angle': 0,
    'angleJitter': 0.6283185307179586,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 60
  }, {
    'name': 'Sketchy 2',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.04912935323383085,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_sac_0',
    'angle': 0,
    'angleJitter': 0.6283185307179586,
    'angleToDirection': true,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 35
  }, {
    'name': 'pencil',
    'sizePressure': true,
    'sizeJitter': 0.5,
    'minSize': 0,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'pencil',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 10
  }, {
    'name': 'soft pencil',
    'sizePressure': true,
    'sizeJitter': 0.5,
    'minSize': 0.2,
    'flow': 1,
    'flowPressure': true,
    'opacity': 0.5,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'pencil',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 10
  }, {
    'name': 'thin graphite',
    'sizePressure': true,
    'sizeJitter': 0.75,
    'minSize': 0,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.3,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'brush',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 6
  }, {
    'name': 'Chalky 1',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.039782372143634386,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 0.5,
    'tangentSpread': 0,
    'shape': 'magma_sac_4',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 60
  }, {
    'name': 'Chalky 2',
    'sizePressure': false,
    'sizeJitter': 0.04,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.0998,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 0.05,
    'tangentSpread': 0,
    'shape': 'magma_sac_3',
    'angle': 0,
    'angleJitter': 0.25132741228718347,
    'angleToDirection': true,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 85
  }, {
    'name': 'Chalky 3',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0.6,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.009723698193411264,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_sac_1',
    'angle': 0,
    'angleJitter': 6.283185307179586,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 80
  }]
}, {
  'id': 'magma_tex',
  'name': 'Textured',
  'items': [{
    'name': 'rough',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'brush',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }, {
    'name': 'rougher',
    'sizePressure': true,
    'sizeJitter': 0.5,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'brush',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }, {
    'name': 'thick rough',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'brush',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }, {
    'name': 'splotchy',
    'sizePressure': false,
    'sizeJitter': 0.3,
    'minSize': 0,
    'flow': 0.5,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'paw',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }, {
    'name': 'Textured 1',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0.25,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.059838709677419354,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 0.95,
    'tangentSpread': 0,
    'shape': 'magma_tex_1',
    'angle': 1.5184364492350666,
    'angleJitter': 0.12566370614359174,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 80
  }, {
    'name': 'Textured 2',
    'sizePressure': true,
    'sizeJitter': 0.12,
    'minSize': 0.2,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.014079520697167756,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 1,
    'tangentSpread': 0,
    'shape': 'magma_tex_3',
    'angle': 0.03490658503988659,
    'angleJitter': 0,
    'angleToDirection': true,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 200
  }, {
    'name': 'Rocky',
    'sizePressure': false,
    'sizeJitter': 0.2,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.06287978863936593,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 1.52,
    'tangentSpread': 1.52,
    'shape': 'magma_tex_4',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 80
  }, {
    'name': 'Fuzzy',
    'sizePressure': false,
    'sizeJitter': 0.1,
    'minSize': 0.2,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.04838799332962757,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 0.6,
    'tangentSpread': 0,
    'shape': 'magma_tex_2',
    'angle': 0,
    'angleJitter': 6.283185307179586,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 100
  }, {
    'name': 'Broken Scatter',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0.09,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.1386,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 1,
    'tangentSpread': 0,
    'shape': 'magma_tex_0',
    'angle': 0,
    'angleJitter': 6.283185307179586,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 100
  }, {
    'name': 'splatter',
    'sizePressure': true,
    'sizeJitter': 0.5,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.2,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0.5,
    'tangentSpread': 0,
    'shape': 'splothes',
    'angle': 0,
    'angleJitter': 3.14,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 40
  }]
}, {
  'id': 'magma_foliage',
  'name': 'Foliage',
  'items': [{
    'name': 'Grassy Spread',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.11869767441860467,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 1.09,
    'tangentSpread': 1.09,
    'shape': 'magma_foliage_2',
    'angle': 0.03490658503988659,
    'angleJitter': 0.3141592653589793,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 60
  }, {
    'name': 'Grassy Dense',
    'sizePressure': true,
    'sizeJitter': 0.1,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.04804733727810651,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 1,
    'tangentSpread': 0,
    'shape': 'magma_foliage_3',
    'angle': 0,
    'angleJitter': 0.3141592653589793,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 80
  }, {
    'name': 'Leafy 1',
    'sizePressure': true,
    'sizeJitter': 0.05,
    'minSize': 0.25,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.2791222570532916,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 1,
    'tangentSpread': 0,
    'shape': 'magma_foliage_1',
    'angle': 0,
    'angleJitter': 3.141592653589793,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 85
  }, {
    'name': 'Leafy 2',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0.5,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.32545550847457627,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 0.81,
    'tangentSpread': 0,
    'shape': 'magma_foliage_0',
    'angle': 0,
    'angleJitter': 3.7699111843077517,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 60
  }, {
    'name': 'Leafy 3',
    'sizePressure': true,
    'sizeJitter': 0.44,
    'minSize': 0.19,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.1452771618625277,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 0.7,
    'tangentSpread': 0,
    'shape': 'magma_foliage_5',
    'angle': 0,
    'angleJitter': 0.18849555921538758,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 100
  }, {
    'name': 'Leaf',
    'sizePressure': true,
    'sizeJitter': 0.5,
    'minSize': 0.5,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.1701505016722408,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 2,
    'tangentSpread': 0,
    'shape': 'magma_foliage_4',
    'angle': 0,
    'angleJitter': 4.71238898038469,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0.49,
    'saturationJitter': 0,
    'brightnessJitter': 0.06,
    'size': 60
  }]
}, {
  'id': 'magma_other',
  'name': 'Other',
  'items': [{
    'name': 'Scattered Eggs',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.1401027749229188,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 4.22,
    'tangentSpread': 0,
    'shape': 'magma_other_3',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 40
  }, {
    'name': 'Egg',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0.4,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.029188078108941418,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_other_3',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 58
  }, {
    'name': 'Checkered',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 1,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_other_1',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': true,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 83
  }, {
    'name': 'Hair - Fur',
    'sizePressure': true,
    'sizeJitter': 0,
    'minSize': 0.7,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.009889558232931727,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_other_4',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 80
  }, {
    'name': 'Streaky 1',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.0028159041394335512,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_other_2',
    'angle': 0,
    'angleJitter': 0,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 94
  }, {
    'name': 'Rough Streak',
    'sizePressure': false,
    'sizeJitter': 0,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.009723698193411264,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 0,
    'tangentSpread': 0,
    'shape': 'magma_other_0',
    'angle': 0,
    'angleJitter': 0.4398229715025711,
    'angleToDirection': true,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0,
    'size': 80
  }, {
    'name': 'pawprints',
    'sizePressure': false,
    'sizeJitter': 0.25,
    'minSize': 0,
    'flow': 1,
    'flowPressure': false,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 1.75,
    'hardness': 1,
    'separateSpread': true,
    'normalSpread': 3,
    'tangentSpread': 0,
    'shape': 'paw',
    'angle': 1.57,
    'angleJitter': 0,
    'angleToDirection': true,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }, {
    'name': 'hearts',
    'sizePressure': false,
    'sizeJitter': 0.3,
    'minSize': 0,
    'flow': 1,
    'flowPressure': true,
    'opacity': 1,
    'opacityPressure': false,
    'spacing': 0.5,
    'hardness': 1,
    'separateSpread': false,
    'normalSpread': 3.5,
    'tangentSpread': 0,
    'shape': 'heart',
    'angle': 0,
    'angleJitter': 0.46,
    'angleToDirection': false,
    'colorPressure': false,
    'foregroundBackgroundJitter': 0,
    'hueJitter': 0,
    'saturationJitter': 0,
    'brightnessJitter': 0
  }]
}];
