import { Injectable, Injector } from '@angular/core';
import { Constructor } from 'magma/common/typescript-utils';
import { SplashScreenId } from 'magma/common/interfaces';
import { SplashScreenService, type ParticularSplashScreen } from 'magma/services/splashScreenService';
import { ConnectToStoryComponent } from '../components/splash-screens/connect-to-story/connect-to-story.component';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@Injectable()
@UntilDestroy()
export class PortalSplashScreenService extends SplashScreenService {
  readonly factories = new Map<SplashScreenId, Constructor<ParticularSplashScreen>>([]);

  constructor(private injector: Injector) {
    super();

    // Register splash screens here:
    this.factories.set(SplashScreenId.ConnectToStory, ConnectToStoryComponent);
  }

  private currentId: SplashScreenId | undefined = undefined;
  private input: any = { };
  private readonly onClosed = new Subject<[SplashScreenId, any]>();

  get inputData() {
    return this.input;
  }

  get isOpen(){
    // TODO: prevent navigation?
    return !!this.currentComponent;
  }

  get currentComponent() {
    if (this.currentId !== undefined) {
      return this.factories.get(this.currentId);
    } else {
      return undefined;
    }
  }

  openSplashScreen<T = any>(id: SplashScreenId, data: any = { }) {
    if (this.currentId) throw new Error(`Splash screen (${this.currentId}) already in action (opening ${id})`);

    const constructor = this.factories.get(id);
    if (!constructor) throw new Error(`Missing constructor for splash screen (${id})`);

    if (!constructor.prototype.canOpen(this.injector)) throw new Error(`Can't open splash screen (${id})`);

    this.currentId = id;
    this.input = data;

    return new Promise<T>((resolve) => {
      this.onClosed.pipe(
        filter(([closedScreenId]) => closedScreenId === id),
        take(1),
        untilDestroyed(this))
      .subscribe(([closedScreenId, data]) => resolve(data));
    });
  }

  closeSplashScreen(data: any) {
    const closedScreenId = this.currentId!;
    this.onClosed.next([closedScreenId, data]);
    this.currentId = undefined;
    this.input = { };
  }
}
