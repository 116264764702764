import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { Component, HostListener, Input } from '@angular/core';
import { EntityType } from 'magma/common/interfaces';
import { faBan, faCopy, faFile, faFolder, faTrash } from 'magma/generated/fa-icons';

@Component({
  selector: 'entity-drag-ghost',
  template: `
    <div class="drag-ghost" [class.disabled]="isDisabled" [class.danger]="isDanger" [class.multiple-shadow]="count > 1" *ngIf="visible" [@ghostAnimation]>
      <svg-icon class="file-type" [icon]="currentIcon" />
      <span class="title">{{ currentTitle }}{{ count > 1 ? '...' : ''}}</span>
      <span class="count">{{ currentCount }}</span>
    </div>
    <div class="drag-ghost multiple multiple-shadow" [class.disabled]="isDisabled" [class.danger]="isDanger" *ngIf="visible && count > 1" [@ghostAnimation]>
      <svg-icon class="file-type" [icon]="currentIcon" />
      <span class="title">{{ currentTitle }}{{ count > 1 ? '...' : ''}}</span>
      <span class="count">{{ currentCount }}</span>
    </div>
  `,
  styleUrls: ['./entity-drag-ghost.component.scss'],
  animations: [
    trigger('ghostAnimation', [
      transition(':enter', [
        animate('700ms', keyframes([
          style({ transform: 'scale(1,1) translateY(0)' }),
          style({ transform: 'scale(1.1, 0.9) translateY(0)' }),
          style({ transform: 'scale(0.9, 1.1) translateY(-10px)' }),
          style({ transform: 'scale(1.05, 0.95) translateY(0)' }),
          style({ transform: 'scale(1,1) translateY(-7px)' }),
          style({ transform: 'scale(1,1) translateY(0)' }),
        ])),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('150ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class EntityDragGhostComponent {
  readonly fileIcon = faFile;
  readonly folderIcon = faFolder;
  readonly multiplefilesIcon = faCopy;
  readonly blockedIcon = faBan;
  readonly binIcon = faTrash;
  readonly entityType = EntityType;
  @Input() visible = false;
  @Input() count = 0;
  @Input() title = '';
  @Input() type = '';
  isDisabled = false;
  isDanger = false;
  dropAllowedElements: HTMLCollectionOf<Element> | undefined = undefined;
  binFolderElement: HTMLElement | null = null;

  constructor() {
    this.dropAllowedElements = document.getElementsByClassName('drop-allowed');
    this.binFolderElement = document.getElementById('binFolder');
  }

  @HostListener('window:mousemove', ['$event'])
  handleMouseMove(event: MouseEvent) {
    if (this.visible) {
      let isMouseOutsideAll = true;

      if (this.dropAllowedElements) {
        for (let i = 0; i < this.dropAllowedElements.length; i++) {
          const element = this.dropAllowedElements[i] as HTMLElement;

          if (this.isElementIntersecting(element, event)) {
            isMouseOutsideAll = false;
            break;
          }
        }
      }

      this.isDanger = !!(this.binFolderElement && this.isElementIntersecting(this.binFolderElement, event));
      this.isDisabled = isMouseOutsideAll; // Update isDisabled based on mouse position
    }
  }

  get isFolder() {
    return this.type === this.entityType.Folder;
  }

  get currentCount() {
    return this.count > 99 ? '99+' : this.count;
  }

  get currentTitle() {
    return this.isDisabled ? 'Not allowed to move' : this.isDanger ? `Move ${this.title} to bin` : this.title;
  }

  get currentIcon() {
    return this.isDisabled ? this.blockedIcon : this.isDanger ? this.binIcon : this.count > 1 ? this.multiplefilesIcon : this.isFolder ? this.folderIcon : this.fileIcon;
  }

  isElementIntersecting(element: HTMLElement, event: MouseEvent) {
    const rect = element.getBoundingClientRect();
    return event && (event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom);
  }
}
