import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UserService } from 'services/user.service';


@UntilDestroy()
@Component({
  selector: 'account-integrations',
  templateUrl: './account-integrations.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './account-integrations.component.scss',
  ],
})
export class AccountIntegrationsComponent {
  constructor(private userService: UserService) { }

  spDetailsExpanded = false;

  get user() {
    return this.userService.user;
  }
}
