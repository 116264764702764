import { Pipe, PipeTransform } from '@angular/core';
import { createTranslate, translateString } from 'magma/common/i18n';
import { LocaleService } from 'magma/services/locale.service';

const tr = createTranslate();

@Pipe({ name: 'customDateFormat' })
export class CustomDateFormatPipe implements PipeTransform {

  constructor(private localeService: LocaleService) {}

  transform(value: string | Date | undefined): string {
    if (!value) return '';

    const currentDate = new Date();
    const date = new Date(value);

    let locale = this.localeService.getLocale() ?? undefined;
    if (locale === 'en') locale = undefined;

    if (isSameDay(currentDate, date)) {
      return tr`Today, ${formatTime(date, locale)}`;
    } else if (isSameDay(getYesterday(currentDate), date)) {
      return tr`Yesterday, ${formatTime(date, locale)}`;
    } else if (isLastFiveDaysOfWeek(currentDate, date)) {
      return `${translateString(formatDayOfWeek(date))}, ${formatTime(date, locale)}`;
    } else {
      return `${formatDate(date, locale)}`;
    }
  }
}

function isSameDay(a: Date, b: Date): boolean {
  return a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
}

function getYesterday(currentDate: Date): Date {
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  return yesterday;
}

function isLastFiveDaysOfWeek(currentDate: Date, date: Date): boolean {
  const diffInDays = (currentDate.getTime() - date.getTime()) / (1000 * 3600 * 24);
  return diffInDays >= 1 && diffInDays <= 5 && date.getDay() >= 1 && date.getDay() <= 5;
}

function formatDayOfWeek(date: Date): string {
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return daysOfWeek[date.getDay()];
}

function formatTime(date: Date, locale?: string): string {
  return date.toLocaleTimeString(locale ?? 'en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
}

function formatDate(date: Date, locale?: string): string {
  return date.toLocaleDateString(locale ?? 'en-US', { year: 'numeric', month: 'long', day: 'numeric' });
}
