import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Participant, RegisterArtworkData, RegisterArtworkModalOutputData } from 'shared/interfaces';
import { faCheck, faTimes, farCheckCircle, farExclamationCircle, farExclamationTriangle, storyprotocolIcon, faInfoCircle } from 'magma/common/icons';
import { PublisherId, InheritedLicense, PublisherConstantId, LicenseTermId } from 'magma/common/interfaces';
import { UserService } from 'services/user.service';
import { safeInt } from 'magma/common/toolUtils';
import { invalidEnum } from 'magma/common/baseUtils';
import { PublishersService } from 'magma/services/publishersService';
import { setDefaultStoryLicenseRights, getDefaultStoryLicenseRights } from 'util/util';
import { RESERVED_TAGS } from 'magma/common/constants';
import { ToastService } from 'magma/services/toast.service';
import { ModalService } from 'services/modal.service';
import { Router } from '@angular/router';

@Component({
  selector: 'register-artwork-modal',
  templateUrl: './register-artwork-modal.component.pug',
  styleUrls: ['./register-artwork-modal.component.scss'],
})
export class RegisterArtworkModal {
  readonly checkIcon = faCheck;
  readonly crossIcon = faTimes;
  readonly storyprotocolIcon = storyprotocolIcon;
  readonly circleCheck = farCheckCircle;
  readonly warningMark = farExclamationTriangle;
  readonly dangerMark = farExclamationCircle;
  readonly infoCircle = faInfoCircle;

  readonly defaultSharesPerPerson = 10;
  readonly nonCommercialLicenseTermId = LicenseTermId.NonCommercialRemix;

  constructor(
    private userService: UserService,
    private publishersService: PublishersService,
    private toastService: ToastService,
    private modalsService: ModalService,
    private router: Router,
  ) {
    this.publishersService.getConstant(PublisherId.StoryProtocol, PublisherConstantId.GetDefaultLicensingRights)
      .then((rights) => setDefaultStoryLicenseRights(rights))
      .catch((e) => DEVELOPMENT && console.log(e));
  }

  @Output() close = new EventEmitter<RegisterArtworkModalOutputData | undefined>();
  @Input() set data(d: RegisterArtworkData | undefined) {
    if (!d || !d.entity || !d.thumb) {
      DEVELOPMENT && console.warn(`Missing data for RegisterArtworkModal (${!d} || ${!d?.entity} || ${!d?.thumb})`);
      this.close.emit(undefined);
      return;
    }
    this.id = d.entity._id;
    this.name = `${d.entity.name}`;
    this.numberOfShares = (d.entity.participants?.length || 0) * this.defaultSharesPerPerson;
    this.resetParticipantsPercentage(d.entity.participants || []);
    this.shortId = `${d.entity.shortId}`;
    this.thumb = d.thumb.toDataURL('image/jpeg');
    this.img = window.location.origin + `/${d.entity.shortId}.png`;
    this.inheritedLicense = d.inheritedLicense;
    this.providerId = d.providerId;
    this.requestPending = false;
    this.termId = LicenseTermId.NonCommercialRemix;
    this.description = '';
    this.inEditMode = false;
    this.showErrorMessage = false;
  }

  // artwork data:
  id = '';
  name = '';
  participants: (Participant & { percentage: number })[] = [];
  participantsShares = new Map<string, number>();
  backupParticipantsShares = new Map<string, number>();
  thumb = '';
  img = '';
  shortId = '';
  inheritedLicense: InheritedLicense | undefined = undefined;

  // modal state:
  providerId?: PublisherId;
  termId = LicenseTermId.NonCommercialRemix;
  licenseDetailsVisible = false;
  description = '';
  numberOfShares = 0;

  requestPending = false;
  inEditMode = false;
  showErrorMessage = false;

  resetParticipantsPercentage(participants: Participant[], intial = true) {
    this.participants = (participants ?? []).map(p => ({
      ...p,
      percentage: this.floorPercentage(100 * Number((intial ? this.defaultSharesPerPerson : this.participantsShares.get(p._id))) / this.numberOfShares)
    }));
    const rest = 100 - this.participants.reduce((total, participant) => (total + participant.percentage), 0);
    const authorParticipant = this.participants.find(p => p._id === this.userService.user?._id);
    if (authorParticipant && rest > 0.01) {
      authorParticipant.percentage = this.floorPercentage(authorParticipant.percentage + rest);
    }

    if (intial) {
      this.participants.forEach(p => {
        this.participantsShares.set(p._id, this.defaultSharesPerPerson);
        this.backupParticipantsShares.set(p._id, this.defaultSharesPerPerson);
      });
    }
  }

  onClose(confirmed: boolean) {
    if (confirmed && !this.inEditMode) {
      let outputData;
      switch (this.providerId) {
        case PublisherId.StoryProtocol: {
          outputData = {
            providerId: PublisherId.StoryProtocol,
            shortId: this.id,
            opts: {
              ipAssetName: this.name,
              description: this.description,
              licenseTermId: this.termId,
              participants: this.participants.map(p => {
                return {
                  accountId: p._id,
                  name: p.name,
                  percentage: p.percentage
                };
              }),
            }
          };
          break;
        }
        case PublisherId.Template: {
          outputData = {
            providerId: PublisherId.Template,
            shortId: this.id,
            opts: {
              templateName: this.name,
            }
          };
          break;
        }
        case undefined: break;
        default: invalidEnum(this.providerId);
      }
      this.close.emit(outputData);
    } else {
      this.close.emit(undefined);
    }
  }

  get distributionIsValid() {
    const isValid = this.sharesSum === this.allowedShares;
    if (isValid) this.showErrorMessage = false;
    return isValid;
  }

  get sharesSum() {
    return [...this.participantsShares.values()].reduce((acc, curr) => acc + curr, 0);
  }

  get allowedShares() {
    return this.participants.length * this.defaultSharesPerPerson;
  }

  get underSharesLimit() {
    return this.allowedShares > this.sharesSum;
  }

  get overSharesLimit() {
    return this.sharesSum > this.allowedShares;
  }

  get registrationDisabled() {
    return !this.canRegister || this.inEditMode || this.requestPending || !this.participants.length || (this.isStoryProtocol && !this.isNonCommercial);
  }

  floorPercentage(percentage: number) {
    return (Math.floor(percentage * 100) / 100);
  }

  getParticipantShares(userId: string) {
    return this.participantsShares.get(userId);
  }

  onSharesChange(userId: string, event: Event) {
    const shares = safeInt(Number((event.target as HTMLInputElement).value), 0, this.allowedShares);
    this.participantsShares.set(userId, shares);
    (event.target as HTMLInputElement).value = shares.toString();
  }

  cancelSharesChange() {
    if (this.backupParticipantsShares.size > 0) {
      this.participantsShares = new Map(this.backupParticipantsShares);
    }
    this.resetParticipantsPercentage(this.participants, false);
    this.inEditMode = false;
  }

  saveSharesChanges() {
    if (this.underSharesLimit || this.overSharesLimit) {
      this.showErrorMessage = true;
      return;
    }
    this.backupParticipantsShares = new Map(this.participantsShares);
    this.resetParticipantsPercentage(this.participants, false);
    this.inEditMode = false;
  }

  distributeEqually(overOrUnder = false) {
    if (overOrUnder) {
      const remains = this.overSharesLimit ? this.sharesSum - this.allowedShares : this.allowedShares - this.sharesSum;
      if (remains !== 0) {
        const toAdd = (this.overSharesLimit ? -1 : 1) * Math.floor(remains / this.participants.length);
        const toAddExtra = (this.overSharesLimit ? -1 : 1) * Math.floor(remains % this.participants.length);
        this.participants.forEach((p, index) => {
          const value = safeInt(Number(this.participantsShares.get(p._id)) + toAdd + (index === 0 ? toAddExtra : 0), 0, this.allowedShares);
          this.participantsShares.set(p._id, value);
        });
        this.distributeEqually(true);
      }
    } else {
      this.resetParticipantsPercentage(this.participants);
    }
  }

  get licenseRights() {
    const defaultTerms = getDefaultStoryLicenseRights();
    return defaultTerms ? defaultTerms[this.termId] : undefined;
  }

  get isStoryProtocol() {
    return this.providerId === PublisherId.StoryProtocol;
  }

  get canRegister() {
    return this.hasEmail && !this.isAnonymous && !this.multipleParticipants;
  }

  get multipleParticipants() {
    return this.participants.length > 1;
  }

  get isAnonymous() {
    return this.userService.user?.userType !== 'user';
  }

  get hasEmail() {
    return !!this.userService.user?.email;
  }

  get isNonCommercial() {
    return this.isStoryProtocol && this.termId === LicenseTermId.NonCommercialRemix;
  }

  get onCommercialWaitingList() {
    return !!this.userService.user?.tags?.includes(RESERVED_TAGS.waitingForCommercialRemixing);
  }

  addingToCommercialWaitingList = false;
  async addToCommercialWaitingList() {
    try {
      this.addingToCommercialWaitingList = true;
      await this.userService.tag(RESERVED_TAGS.waitingForCommercialRemixing);
      this.toastService.success({  message: 'You successfully joined commercial remixing waiting list.' });
    } catch (e) {
      this.toastService.error({ message: 'We failed to add you to the commercial remixing waiting list, please try again later.' });
    } finally {
      this.addingToCommercialWaitingList = false;
    }
  }

  async createAccount() {
    await this.modalsService.signUp(true);
  }

  async setupEmail() {
    await this.router.navigate(['/my/account']);
  }
}
