import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { faArrowRight, faPlus } from 'magma/common/icons';
import { ModalService } from 'services/modal.service';
import { ToastService } from 'magma/services/toast.service';
import { JamsService } from 'magma/services/jams.service';
import { JAMS_USER_GUIDE_URL, JAMS_TERMS_OF_USE_URL, JamDrawing, JamFilter } from 'magma/common/interfaces-jams';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, map, mergeMap, skip, switchMap, tap } from 'rxjs';
import { UserService } from 'services/user.service';
import { TrackService } from 'services/track.service';
import { Analytics } from 'magma/common/interfaces';
import { belowBreakpointLG } from 'magma/common/utils';
import { isEqual } from 'lodash';
import { routeToArtJams } from 'shared/utils';

@UntilDestroy()
@Component({
  selector: 'jams',
  templateUrl: './jams.component.pug',
  styleUrls: ['./jams.component.scss'],
})
export class JamsComponent {
  readonly JAMS_USER_GUIDE_URL = JAMS_USER_GUIDE_URL;
  readonly JAMS_TERMS_OF_USE_URL = JAMS_TERMS_OF_USE_URL;

  @ViewChild('jamsElement') jamsElement!: ElementRef<HTMLDivElement>;

  plusIcon = faPlus;
  arrowIcon = faArrowRight;
  jams: JamDrawing[] = [];

  count = 0;
  pageSize = 20;
  pageCount = 0;
  page = 0;

  page$ = new BehaviorSubject<number>(0);
  refresh$ = new BehaviorSubject<boolean>(true);

  isLoading = false;

  queryMap: Params = {};
  filter: JamFilter | undefined;

  jams$ = combineLatest([
    this.activatedRoute.data,
    this.activatedRoute.params,
    this.jamsService.showNsfw$,
    this.jamsService.filterGenre$,
    this.refresh$,
    this.activatedRoute.queryParams,
  ]).pipe(
    map(([data, params, nsfw, filterGenre, showLoading, query]) => {
      this.queryMap = query;
      return { filter: { filter: data.filter, group: params['group'], nsfw, genre: filterGenre, archived: query['archived'] === 'true' ? true : false }, showLoading };
    }),
    switchMap((res) => {
      const isFilterChanged = !isEqual(this.filter, res.filter);
      this.filter = res.filter;
      this.jamsService.activeGroup$.next(this.filter.group);

      if (this.filter.filter === 'live') {
        return this.jamsService.liveArtJams$;
      } else {
        return this.page$.pipe(mergeMap(async page => {
          const showLoading = res.showLoading || page !== this.page || isFilterChanged;
          if (showLoading) this.jams = [];
          this.isLoading = showLoading;
          this.page = page;
          // on first page there is special "new jam" button visible only in few cases
          const modifier = this.showCreateLiveJamTile ? 1 : 0;
          const offset = page * this.pageSize - (page === 0 ? 0 : modifier);
          const count = this.pageSize - (page === 0 ? modifier : 0);

          const jams = await this.jamsService.getJams(this.filter!, offset, count);
          this.count = jams.count;
          this.pageCount = Math.ceil(jams.count / this.pageSize);
          return jams.jams;
        }));
      }
    }),
    tap(() => this.isLoading = false),
  );

  constructor(
    private jamsService: JamsService,
    private modalService: ModalService,
    private toastService: ToastService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private analyticsService: TrackService,
    private router: Router,
  ) {

  }

  calculatePageSize() {
    const horizontal = Math.floor((this.jamsElement.nativeElement.clientWidth - 24 * 2) / (240 + 16));
    const vertical = Math.floor((window.innerHeight - 250) / 260);
    return Math.max(10, horizontal * (vertical + 1));
  }

  async ngAfterViewInit() {
    this.pageSize = this.calculatePageSize();
    this.jams$.pipe(untilDestroyed(this)).subscribe((jams) => {
      this.jams = jams;
      setTimeout(() => {
        if (this.filter?.filter !== 'live') this.jamsElement.nativeElement.scrollTo({ top: 0 });
      }, 0);
    });
    // skip first becuase rpc will trigger it after subscribe
    this.jamsService.refreshList$.pipe(untilDestroyed(this), skip(1)).subscribe(() => {
      this.refresh$.next(false);
    });
  }

  changeCurrentPage(page: number) {
    this.page$.next(page - 1);
    this.analyticsService.event(Analytics.PaginateArtJams, { pageIndex: page - 1 });
  }

  private getArtJamGroup(): string | undefined {
    return this.jamsService.activeGroup$.getValue();
  }

  async createJam() {
    const jamDrawing = await this.modalService.createJam();
    if (jamDrawing) {
      try {
        jamDrawing.jam.group = this.getArtJamGroup();
        await this.jamsService.createJam(jamDrawing);
        this.refresh$.next(false);
      } catch (e) {
        this.toastService.error({ message: 'Failed to create jam', subtitle: e.message });
      }
    }
  }

  byId(_i: number, item: JamDrawing) {
    return item._id;
  }

  get isMobileView() {
    return belowBreakpointLG();
  }

  get isAnonymous() {
    return this.userService.user?.userType === 'anonymous';
  }

  get offset() {
    const modifier = this.showCreateLiveJamTile ? 1 : 0;
    return this.page * this.pageSize - (this.page === 0 ? 0 : modifier);
  }

  get isLive() {
    return this.filter?.filter === 'live';
  }

  get isPastJams() {
    return this.filter?.archived;
  }

  get showCreateLiveJamTile() {
    if (this.filter?.filter === 'live' || this.isPastJams) return false;
    return !this.isAnonymous && this.page === 0 && !(this.hasFilter() && !(this.jams.length));
  }

  hasFilter() {
    return this.filter?.genre !== undefined;
  }

  removeJam(shortId: string) {
    this.jams = this.jams.filter(j => j.shortId !== shortId);
  }

  openOngoingJams() {
    void this.router.navigate(routeToArtJams('browse'));
  }
}
