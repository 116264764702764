import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { akitaDevtools, enableAkitaProdMode } from '@datorama/akita';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerInsightGroups, DAY } from 'magma/common/constants';
import { delay } from 'magma/common/promiseUtils';
import { storageGetItem, storageGetJson, storageRemoveItem, storageSetBoolean, storageSetItem } from 'magma/services/storage';
import { debounceTime, distinct, distinctUntilChanged, filter, map, skip, startWith, take } from 'rxjs/operators';
import { AuthService } from 'services/auth.service';
import { ModalService } from 'services/modal.service';
import { UserService } from 'services/user.service';
import { EntitiesService } from 'services/entities.service';
import { RecentEntitiesStore } from 'services/recent-entities/recent-entities.store';
import { RouterService } from 'services/router.service';
import { ErrorReporterService } from 'services/error-reporter.service';
import { getCookie } from 'magma/common/cookies';
import { ErrorReporter } from 'magma/services/errorReporter';
import { TeamSelectorService } from 'services/team-selector.service';
import { SubscriptionService } from 'magma/services/subscription';
import { ServerConstant } from './utils';
import { AFTER_LOGIN_REDIRECT_QUERY_KEY, AFTER_LOGIN_REDIRECT_URL_KEY } from './login-signup-page.component';
import { ServerConstantVars, UserData } from 'shared/interfaces';
import { TeamService } from 'services/team.service';
import { isAnonymousName } from 'magma/common/userUtils';
import { signUps } from 'magma/common/data';
import { BlogService } from '../services/blog.service';
import { COMMUNITY_HUB_TEAM_ID } from '../../../shared/posts';
import { TeamsQuery } from '../services/team.query';
import { Model } from 'magma/services/model';
import { Analytics } from 'magma/common/interfaces';
import { ITrackService } from 'magma/services/track.service.interface';
import { belowBreakpointXL, sendIntercomUpdate } from 'magma/common/utils';
import { AnalyticsSessionCheckService } from 'magma/services/analytics-session-check.service';
import { Subject } from 'rxjs';
import { setSettingsHideTopbar, setSettingsSliders } from 'magma/common/settings';
import { CustomerInsightsService } from 'magma/services/customer-insights.service';
import { OnboardingService } from 'magma/services/onboarding.service';

const drawerState = trigger('drawerState', [
  transition('void => *', [
    style({ transform: 'translate3d(-100%, 0, 0)' }),
    animate('0.2s ease-in-out', style({ transform: 'translate3d(0, 0, 0)' })),
  ]),
  transition('* => void', [
    style({ transform: 'translate3d(0, 0, 0)' }),
    animate('0.2s ease-in-out', style({ transform: 'translate3d(-100%, 0, 0)' })),
  ]),
]);

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss'],
  animations: [drawerState],
})
export class AppComponent implements OnInit {
  @ServerConstant('vars') vars!: ServerConstantVars;

  teamSelectorMode = '';
  private teamSelectorOpen = false;
  private isMobile = false;
  private intercomUpdateSubject = new Subject<void>();
  private intercomIframe: HTMLIFrameElement | undefined;
  constructor(
    ngZone: NgZone,
    private router: Router,
    private userService: UserService,
    private modals: ModalService,
    private entitiesService: EntitiesService,
    private recentEntitiesStore: RecentEntitiesStore,
    private subscriptionService: SubscriptionService,
    private errorReporter: ErrorReporter,
    private teamSelectorService: TeamSelectorService,
    private auth: AuthService,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService, // listed for initialization - DO NOT REMOVE!
    _errorReporterService: ErrorReporterService, // listed for initialization - DO NOT REMOVE!
    _teamService: TeamService, // listed for initialization - DO NOT REMOVE!
    private blogService: BlogService,
    private teamsQuery: TeamsQuery,
    private model: Model,
    private trackService: ITrackService,
    private customerInsightsService: CustomerInsightsService,
    _analyticsSessionCheckService: AnalyticsSessionCheckService, // listed for initialization - DO NOT REMOVE!
    private onboardingService: OnboardingService,
  ) {
    if (DEVELOPMENT) {
      akitaDevtools(ngZone, {});
    } else {
      enableAkitaProdMode();
    }

    if (this.auth.hasAuthToken) {
      const urlAfterLogin = storageGetItem(AFTER_LOGIN_REDIRECT_URL_KEY);
      const queryParams = storageGetJson<{ [key: string]: any; }>(AFTER_LOGIN_REDIRECT_QUERY_KEY);
      storageRemoveItem(AFTER_LOGIN_REDIRECT_URL_KEY);
      storageRemoveItem(AFTER_LOGIN_REDIRECT_QUERY_KEY);

      if (urlAfterLogin) {
        void this.router.navigate([urlAfterLogin], { queryParams });
      } else if (queryParams) {
        void this.router.navigate([], { relativeTo: this.activatedRoute, queryParams, queryParamsHandling: 'merge' });
      }
    }

    this.routerService.data$.pipe(untilDestroyed(this)).subscribe(data => {
      const mode = data.teamSelector ?? '';
      if (this.teamSelectorMode !== mode) {
        this.teamSelectorMode = mode;
        if (this.teamSelectorMode !== 'toggle') {
          this.teamSelectorOpen = false;
        }
      }
    });

    this.teamSelectorService.toggled$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.teamSelectorMode === 'toggle' || this.isMobile) {
        this.teamSelectorOpen = !this.teamSelectorOpen;
      } else {
        this.teamSelectorOpen = false;
      }
    });

    this.resized();

    ngZone.runOutsideAngular(() => {
      setInterval(() => {
        const container = document.getElementById('intercom-container');
        if (container) {
          const iframe = container.getElementsByTagName('iframe')[0];
          if (iframe !== this.intercomIframe) {
            this.intercomUpdateSubject.next();
            this.intercomIframe = iframe;
          }
        } else {
          this.intercomIframe = undefined;
        }
      }, 1000);
    });
  }

  @HostListener('open-team-selector')
  openTeamSelector() {
    this.teamSelectorOpen = true;
  }
  get showTeamSelector() {
    // console.log('showTeamSelector', this.teamSelectorMode, this.teamSelectorOpen, this.isMobile);
    if (this.teamSelectorMode === 'always') return this.isMobile ? this.teamSelectorOpen : true;
    if (this.teamSelectorMode === 'toggle') return this.teamSelectorOpen;
    return false;
  }

  get showBackdrop() {
    return this.animateDrawer && this.teamSelectorOpen;
  }

  get animateDrawer() {
    return this.teamSelectorMode === 'toggle' || this.isMobile;
  }

  closeTeamSelector() {
    this.teamSelectorOpen = false;
  }

  @HostListener('window:resize')
  resized() {
    this.isMobile = belowBreakpointXL();
  }

  ngOnInit() {
    if (IS_PORTAL && !IS_HOSTED) {
      this.routerService.url$.pipe(
        filter((url) => {
          const loggedIn = this.auth.loggedIn;
          const notInitialRedirection = !!url.length;
          const notDrawing = url.split('/')[1] !== 'd'; // there is separate branch for doing that in editor
          return loggedIn && notInitialRedirection && notDrawing;
        }),
        map(() => this.teamsQuery.getActive()),
        distinctUntilChanged((previous, current) => previous?._id === current?._id),
        untilDestroyed(this),
      ).subscribe((team) => {
        this.blogService.handleQueuedModal(team?._id ?? COMMUNITY_HUB_TEAM_ID);
      });
    }

    this.userService.user$.pipe(
      skip(1), // TODO: why is this here ?
      filter((user): user is UserData => !!user),
      distinct(user => user._id),
      untilDestroyed(this)
    ).subscribe(async () => {
      if (this.routerService.data$.value.noReload) return;

      // TODO: do not reload recent entities if we don't need them
      // reload recent entities store when user changes
      this.recentEntitiesStore.remove();
      this.entitiesService.getRecentEntities().pipe(take(1)).subscribe();

      // TODO: what is this doing ?
      const urlTree = this.router.parseUrl(this.router.url);
      const { queryParams } = urlTree;
      if (Object.keys(queryParams).length) {
        urlTree.queryParams = {};
        const url = urlTree.toString();
        await this.router.navigateByUrl('', { skipLocationChange: true });
        void this.router.navigate([url], { queryParams });
      }
    });

    // show signup modal
    this.userService.user$.pipe(take(1), untilDestroyed(this)).subscribe(async user => {
      if (!user) return;

      if (!IS_HOSTED && !user.hideNewFeatureNotifications && user.newFeature === 'brushes' && user.lastNewFeature !== 'brushes') {
        this.modals.announcement();
      }

      if (this.onboardingService.shouldInitConferenceTimeouts) {
        await this.onboardingService.initConferenceTimeouts(user);
      }

      if (!IS_HOSTED && getCookie('magma-plan')) {
        // this will fail if user already has a subscription (even if it's an inactive unpaid subscription)
        this.subscriptionService.handleUpgrade()
          .catch(e => this.errorReporter.reportError('Upgrade to pro from magma-plan cookie failed', e));
      } else if (user.userType === 'anonymous' && !['/error', '/login', '/signup'].includes(this.router.url)) {
        const lastSignUp = +(storageGetItem('sign-up-time2') as any) || 0;
        // TODO: move referral logic to product definition?
        const ref = getCookie('referral');

        while (ref !== 'aggie') {
          try {
            const enforceSignup = !IS_HOSTED && lastSignUp !== 0 && (Date.now() - lastSignUp) > DAY && !this.vars.allowAnonymousUsers;

            if (enforceSignup) {
              if (!this.routerService.isOnDrawingRevision) {
                await this.modals.signUp(true);
              }
            } else if (isAnonymousName(user.name)) {
              if (signUps.includes('saml')) {
                window.location.href = '/login';
              } else {
                const activeGrowthHacks = await this.userService.getActiveGrowthHacks();
                const showForcedModal = activeGrowthHacks['forced-signup-modal'] && Math.random() < 0.5; // 50% of users
                const startWithSuperSimpleMode = Math.random() < 0.1; // 10% of users

                if (startWithSuperSimpleMode) {
                  setSettingsSliders('super-simple');
                  setSettingsHideTopbar(true);
                  this.trackService.event(Analytics.SuperSimpleModeCohort);
                }

                if (showForcedModal) {
                  storageSetBoolean('sign-up-forced', true);
                  await this.modals.signUp(true);
                } else {
                  await this.modals.signUp();
                }

                //  TODO: GH paused for now, will resume
                // if (showModal) {
                //   this.modals.signUp();
                // } else {
                //   let artJamJoin = false;

                //   if (isAnonymousName(user.name)) {
                //     const name = await this.userService.generateRandomName();
                //     this.model.updateAccount({ name }).then(() => {
                //       this.model.reloadSession(this.auth.token$.value);
                //     }).catch(e => this.errorReporter.reportError('Auto-Generating and updating name for Anonymous failed', e));
                //   } else if (lastSignUp === 0) {
                //     artJamJoin = true;
                //   }
                //   this.trackService.event(Analytics.AutoJoinAsAnonymous, { artJamJoin });
                //   storageSetItem('sign-up-time2', Date.now().toString());
                //   storageSetBoolean('sign-up-auto', true);
                // }
              }
            }
            break;
          } catch (e) {
            await delay(1000);
          }
        }

        const deployedOnboardingFlow = this.onboardingService.pickDeployedOnboardingFlow(user);
        if (deployedOnboardingFlow) {
          this.onboardingService.launchDeployedOnboardingFlows(user, deployedOnboardingFlow);
        }
      } else if (this.customerInsightsService.shouldShowModal()) {
        this.customerInsightsService.showModal();
      }
    });

    this.intercomUpdateSubject
    .pipe(
      debounceTime(1000),
      startWith(null),
    )
    .subscribe(() => sendIntercomUpdate());

    this.router.events.subscribe(_event => this.intercomUpdateSubject.next());
  }
}
