import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, take, catchError } from 'rxjs/operators';
import type { Stripe } from '@stripe/stripe-js';
import { ServerConstant } from 'components/utils';
import { CheckoutInfo, ServerConstantVars } from 'shared/interfaces';
import { storageGetItem, storageRemoveItem, storageSetItem } from 'magma/services/storage';
import { handleHttpError } from 'shared/utils';
import { UserError } from 'magma/common/userError';

// TODO: merge with billing.service.ts
@Injectable({ providedIn: 'root' })
export class PaymentService {
  @ServerConstant('vars') vars!: ServerConstantVars;

  constructor(private httpClient: HttpClient) { }

  startSubscriptionCreation(plan: string, interval: string, team: any = {}, newSubscription = false) {
    const payload = { subscription: { plan, interval }, team };
    return this.httpClient.post<CheckoutInfo>(`/api/billing/subscriptions`, payload).pipe(
      map(checkout => {
        !!checkout.checkoutId && newSubscription ? this.redirectToStripeCheckout(checkout) : of(true);
      }),
      catchError(error => throwError(error)),
    );
  }

  upgradeSubscription(teamId: string, subscriptionData: { interval?: string; plan?: string; }) {
    return this.httpClient.put<CheckoutInfo>(`/api/billing/subscriptions/teams/${teamId}`, subscriptionData).pipe(
      catchError(error => throwError(error)),
    );
  }

  checkPaymentAwaiting(sessionId: string | undefined): Observable<boolean> {
    if (!sessionId) return of(false);

    return this.httpClient.get<boolean>(`/api/billing/subscriptions/${sessionId}`)
      .pipe(take(1), handleHttpError());
  }

  redirectToStripeCheckout(checkout: CheckoutInfo, query = '') {
    const url = checkout.slug ? `/s/${checkout.slug}/settings/billing` : location.pathname + query;
    storageSetItem('redirect-from-billing', url);

    if (checkout.checkoutUrl) {
      location.href = checkout.checkoutUrl;
    } else {
      const stripe = (window as any).Stripe(this.vars.stripePublishableKey) as Stripe;
      if (!DEVELOPMENT && !stripe) throw new UserError('Failed to open checkout, reload the page and try again. If this error persists, please contact support.');
      void stripe.redirectToCheckout({ sessionId: checkout.checkoutId });
    }
  }
}

export function redirectAfterCheckout() {
  const redirectUrl = storageGetItem('redirect-from-billing');
  storageRemoveItem('redirect-from-billing');
  return redirectUrl;
}
