import { Component, Input } from '@angular/core';
import { artJamsIcon, faCheck, faGlobe, faTimes, nineDotsIcon } from 'magma/common/icons';
import { IAppNotificationService } from 'magma/services/app-notification.service.interface';
import { Model } from 'magma/services/model';
import { SearchBarService } from 'services/search-bar.service';
import { TeamSelectorService } from 'services/team-selector.service';
import { TeamsQuery } from 'services/team.query';
import { TeamService } from 'services/team.service';

@Component({
  selector: 'navigation-bar',
  templateUrl: './navigation-bar.component.pug',
  styleUrls: ['./navigation-bar.component.scss'],
  host: {
    '[class.force-home-button]': 'forceHomeButton',
  },
})
export class NavigationBarComponent {
  @Input() forceHomeButton = false;
  @Input() pageTitle = '';
  readonly closeIcon = faTimes;
  readonly checkIcon = faCheck;
  readonly nineDotsIcon = nineDotsIcon;
  readonly artJamsIcon = artJamsIcon;
  activeTeam$ = this.teamsQuery.selectActive();
  unreadNotification = false;

  constructor(
    private teamsQuery: TeamsQuery,
    private model: Model,
    private teamSelectorService: TeamSelectorService,
    public search: SearchBarService,
    private teamService: TeamService,
    private notificationService: IAppNotificationService,
  ) {
    this.notificationService.notificationCounters$.pipe().subscribe((counters) => {
      this.unreadNotification = [...counters.values()].filter((count) => count > 0).length > 0;
    });
  }

  get isArtJams() {
    return window.location.pathname.includes('art-jams');
  }

  openTeamSelector() {
    this.teamSelectorService.toggle();
  }

  getTeamAvatar(slug: string) {
    return this.teamService.getTeamBySlug(slug)?.avatar;
  }

  getTeamName(slug: string) {
    return this.teamService.getTeamBySlug(slug)?.name;
  }

  get teams() {
    return this.teamsQuery.getAll();
  }

  get user() {
    return this.model.user;
  }
}
