import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { moduleRoutes } from 'modules/module-routes';
import { AccountPageGuard } from 'guards/account-page.guard';
import { TeamSettingsGuard } from 'guards/team-settings-page.guard';
import { LogOutComponent } from './logout.component.ts/logout.component';
import { ManageEntitiesGuard } from '../guards/manage-entities.guard';
import { PaymentWaitGuard } from '../guards/payment-wait.guard';
import { AccountSettingsPageComponent } from './account-settings.component';
import { EntitiesPageComponent } from './entities-page/entities-page.component';
import { LoginSignupPageComponent } from './login-signup-page.component';
import { FormType } from './login-signup.component';
import { ManagePageComponent } from './manage-page/manage-page.component';
import { PaymentValidatorComponent } from './payment-validator.component';
import { TeamPageComponent } from './team-page.component';
import { ErrorPageComponent } from './error-page.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { RecentEntitiesPageComponent } from './recent-entities-page/recent-entities-page.component';
import { DrawingBuddiesPageComponent } from './drawing-buddies-page/drawing-buddies-page.component';
import { NotFoundPageComponent } from './404/not-found-page.component';
import { AccountPasswordComponent } from './account-password/account-password.component';
import { AccountBillingComponent } from './account-billing/account-billing.component';
import { AccountNotificationsComponent } from './account-notifications/account-notifications.component';
import { AccountPrivacyComponent } from './account-privacy/account-privacy.component';
import { LoadingPageComponent } from './loading-page.component';
import { TeamSettingsComponent } from './team-settings/team-settings.component';
import { TeamSettingsDetailsComponent } from './team-settings/team-settings-details/team-settings-details.component';
import { TeamSettingsMembersComponent } from './team-settings/team-settings-members/team-settings-members.component';
import { TeamSettingsBillingComponent } from './team-settings/team-settings-billing/team-settings-billing.component';
import { TeamInviteGuard } from 'guards/team-invite.guard';
import { AccountFeedbackComponent } from './account-feedback.component';
import { FeedbackRequestsComponent } from './feedback-requests/feedback-requests.component';
import { CanDeactivateGuard } from './can-deactivate-guard.component';
import { NoAccessOrNotFoundPageComponent } from './no-access-or-not-found/no-access-or-not-found.component';
import { TeamSettingsAccessComponent } from './team-settings/team-settings-access/team-settings-access.component';
import { TeamGuard } from 'guards/team.guard';
import { TeamSettingsMemberRolesComponent } from './team-settings/team-settings-member-roles/team-settings-member-roles.component';
import { AccountApiComponent } from './account-api/account-api.component';
import { TeamSettingsContributorsComponent } from './team-settings/team-settings-contributors/team-settings-contributors.component';
import { TeamSettingsBansComponent } from './team-settings/team-settings-bans/team-settings-bans.component';
import { TeamProjectsGuard } from 'guards/team-projects.guard';
import { NoAccessComponent } from './no-access/no-access.component';
import { DefaultPathGuard } from 'guards/default-path.guard';
import { EmptyComponent } from './empty/empty.component';
import { OauthComponent } from './oauth/oauth.component';
import { BinFolderComponent } from './bin-folder/bin-folder.component';
import { TeamBinFolderGuard } from 'guards/team-bin-folder.guard';
import { ReauthenticateComponent } from './reauthenticate/reauthenticate.component';
import { AiJobsComponent } from './ai-jobs/ai-jobs.component';
import { AiModelsComponent } from './ai-models/ai-models.component';
import { CommunityHubComponent } from './community-hub/community-hub.component';
import { ContentPageComponent } from './content-page/content-page.component';
import { AiComponent } from './ai/ai.component';
import { AiModelComponent } from './ai-model/ai-model.component';
import { JamsComponent } from './jams/jams.component';
import { AccountReferralsComponent } from './account-referrals/account-referrals.component';
import { FeatureGuard } from 'guards/feature.guard';
import { AccountIntegrationsComponent } from 'components/account-integrations/account-integrations.component';
import { EmbeddedFramerPagesComponent } from 'components/embedded-framer-pages/embedded-framer-pages.component';
import { EmbeddedFramerPages, EMBEDDED_FRAMER_PAGES_FEATURES, EMBEDDED_FRAMER_PAGES_TITLES } from 'magma/common/constants';
import { EmbeddedFramerPageComponent } from 'components/embedded-framer-pages/embedded-framer-page.component';

const homeRouteName = PRODUCT_INFO.home.route;

const routes: Routes = [
  { path: '', pathMatch: 'full', canActivate: [DefaultPathGuard], component: EmptyComponent },
  { path: 'app', pathMatch: 'full', canActivate: [DefaultPathGuard], component: EmptyComponent },
  {
    path: 'invite/:id',
    canActivate: [TeamInviteGuard],
    component: NotFoundPageComponent,
    data: {
      header: 'Invalid invitation',
      title: 'The invitation link that you are trying to use is either incomplete, or the invitation has already expired.',
    },
  },
  ...moduleRoutes,
  { path: '_loading', component: LoadingPageComponent },
  { path: 'error', component: ErrorPageComponent },
  { path: 'no-access-or-not-found', component: NoAccessOrNotFoundPageComponent },
  { path: 'oauth', component: OauthComponent },
  {
    path: 's/:team/reauthenticate',
    data: { team: true },
    component: ReauthenticateComponent,
  },
  {
    path: '',
    component: ManagePageComponent,
    canActivate: [ManageEntitiesGuard],
    children: [
      { path: 'search', component: SearchResultsComponent, data: { teamSelector: 'always', page: 'search' } },
      {
        path: 'e',
        component: EmbeddedFramerPagesComponent,
        children: Object.values(EmbeddedFramerPages).map((framerPageId) => ({
          path: framerPageId,
          canActivate: EMBEDDED_FRAMER_PAGES_FEATURES[framerPageId] ? [FeatureGuard] : [],
          component: EmbeddedFramerPageComponent,
          data: {
            embeddedFramerPagePath: framerPageId,
            feature: EMBEDDED_FRAMER_PAGES_FEATURES[framerPageId],
            teamSelector: 'always',
            title: EMBEDDED_FRAMER_PAGES_TITLES[framerPageId],
          }
        })),
      },
      {
        path: 'community-hub',
        data: { teamSelector: 'always', title: 'Community Hub' },
        children: [
          { path: '', component: CommunityHubComponent },
          { path: ':project', component: ContentPageComponent },
        ],
      },
      {
        path: 'art-jams',
        children: [
          { path: '', redirectTo: 'live', pathMatch: 'full' },
          { path: 'browse', component: JamsComponent, data: { teamSelector: 'always', title: 'Browse Art Jams', filter: 'all' } },
          { path: 'archived', component: JamsComponent, data: { teamSelector: 'always', title: 'Past Art Jams', filter: 'all' } },
          { path: 'live', component: JamsComponent, data: { teamSelector: 'always', title: 'Live Art Jams', filter: 'live' } },
          { path: 'my', component: JamsComponent, data: { teamSelector: 'always', title: 'Live Art Jams', filter: 'my' } },
          { path: 'joined', component: JamsComponent, data: { teamSelector: 'always', title: 'Live Art Jams', filter: 'joined' } },

          { path: ':group/browse', component: JamsComponent, data: { teamSelector: 'always', title: 'Browse Art Jams', filter: 'all' } },
          { path: ':group/archived', component: JamsComponent, data: { teamSelector: 'always', title: 'Past Art Jams', filter: 'all' } },
          { path: ':group/live', component: JamsComponent, data: { teamSelector: 'always', title: 'Live Art Jams', filter: 'live' } },
          { path: ':group/my', component: JamsComponent, data: { teamSelector: 'always', title: 'Live Art Jams', filter: 'my' } },
          { path: ':group/joined', component: JamsComponent, data: { teamSelector: 'always', title: 'Live Art Jams', filter: 'joined' } },
        ]
      },
      {
        path: 'my',
        data: { teamSelector: 'always' },
        children: [
          { path: '', redirectTo: homeRouteName, pathMatch: 'full' },
          { path: homeRouteName, component: EntitiesPageComponent, data: { page: 'drafts' } },
          { path: 'participated', component: RecentEntitiesPageComponent },
          { path: 'bin', component: BinFolderComponent, data: { title: 'Bin Folder' } },
          { path: 'buddies', component: DrawingBuddiesPageComponent },
          { path: 'logout', component: LogOutComponent },
          { path: 'shared-with-me', component: EntitiesPageComponent, data: { page: 'shared' } },
          { path: 'live', component: RecentEntitiesPageComponent, data: { onlyLive: true, title: 'Live now', path: 'live' } },
          {
            path: 'ai',
            component: AiComponent,
            children: [
              { path: '', redirectTo: 'models', pathMatch: 'full' },
              { path: 'models', component: AiModelsComponent, children: [] },
              { path: 'models/:rId', component: AiModelComponent, children: [] },
              { path: 'jobs', component: AiJobsComponent, children: [] },
            ],
          },
          {
            path: 'account',
            component: AccountSettingsPageComponent,
            data: { page: 'account', teamSelector: 'toggle', pageTitle: IS_HOSTED ? 'Account' : 'Account & Billing' },
            canActivate: [AccountPageGuard],
            children: [
              { path: '', redirectTo: 'password', pathMatch: 'full' },
              { path: 'api', component: AccountApiComponent, children: [] },
              { path: 'password', component: AccountPasswordComponent, children: [] },
              { path: 'billing', component: AccountBillingComponent, children: [] },
              { path: 'notifications', component: AccountNotificationsComponent, children: [] },
              { path: 'privacy', component: AccountPrivacyComponent, children: [] },
              { path: 'referrals', component: AccountReferralsComponent, children: [] },
              { path: 'integrations', component: AccountIntegrationsComponent, children: [] },
            ],
          },
          {
            path: 'feedback',
            component: AccountFeedbackComponent,
            data: { page: 'account', teamSelector: 'toggle', pageTitle: 'Feedback' },
            children: [
              { path: '', redirectTo: 'requests', pathMatch: 'full' },
              {
                path: 'requests',
                component: FeedbackRequestsComponent,
                children: [
                  { path: '**', component: FeedbackRequestsComponent }
                ],
              },
              { path: 'artspaces', redirectTo: '/my/feedback/requests' },
              {
                path: 'bugs',
                component: FeedbackRequestsComponent,
                children: [
                  { path: '**', component: FeedbackRequestsComponent }
                ],
              },
              {
                path: 'community-hub',
                component: FeedbackRequestsComponent,
                children: [
                  { path: '**', component: FeedbackRequestsComponent }
                ],
              },
            ],
          },
        ],
      },
      {
        path: 's/:team',
        canActivate: [TeamGuard],
        data: { team: true, teamSelector: 'always' },
        children: [
          { path: '', component: TeamPageComponent },
          {
            path: 'settings',
            component: TeamSettingsComponent,
            canActivate: [TeamSettingsGuard],
            children: [
              { path: '', redirectTo: 'details', pathMatch: 'full' },
              { path: 'details', component: TeamSettingsDetailsComponent, data: { title: 'Team details' } },
              { path: 'members', component: TeamSettingsMembersComponent, data: { title: 'Team members' } },
              { path: 'bans', component: TeamSettingsBansComponent, data: { title: 'Team bans' } },
              { path: 'roles', component: TeamSettingsMemberRolesComponent, data: { title: 'Team roles' } },
              { path: 'contributors', component: TeamSettingsContributorsComponent, data: { title: 'Team contributors' } },
              { path: 'billing', component: TeamSettingsBillingComponent, data: { title: 'Team billing' } },
              { path: 'access', component: TeamSettingsAccessComponent, data: { title: 'Team access' } },
            ],
          },
          { path: 'bin', component: BinFolderComponent, title: 'Artspace Bin Folder', canActivate: [TeamBinFolderGuard] },
          { path: 'no-access', component: NoAccessComponent },
          { path: 'recent', component: RecentEntitiesPageComponent, data: { title: 'Recent', path: 'recent' }, canActivate: [TeamProjectsGuard] },
          { path: 'live', component: RecentEntitiesPageComponent, data: { onlyLive: true, title: 'Live now', path: 'live' }, canActivate: [TeamProjectsGuard] },
          {
            path: 'ai',
            component: AiComponent,
            children: [
              { path: '', redirectTo: 'models', pathMatch: 'full' },
              { path: 'models', component: AiModelsComponent, children: [] },
              { path: 'models/:rId', component: AiModelComponent, children: [] },
              { path: 'jobs', component: AiJobsComponent, children: [] },
            ],
          },
          { path: ':project', component: EntitiesPageComponent, data: { page: 'project' }, canActivate: [TeamProjectsGuard] },
        ],
      },
    ],
  },
  { path: 'payment', component: PaymentValidatorComponent, canActivate: [PaymentWaitGuard] },
  { path: FormType.Login, component: LoginSignupPageComponent, data: { noReload: true } },
  { path: FormType.SignUp, component: LoginSignupPageComponent, data: { noReload: true } },
  { path: FormType.EmailConfirmation, component: LoginSignupPageComponent, data: { noReload: true } },
  { path: `${FormType.ResetPassword}/:token`, component: LoginSignupPageComponent },
  { path: `${FormType.FinishRegisteration}/:token`, component: LoginSignupPageComponent },
  { path: '404', component: NotFoundPageComponent },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
  providers: [CanDeactivateGuard],
})
export class AppRoutingModule { }
