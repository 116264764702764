import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { JAM_THUMB_HEIGTH, JAM_THUMB_WIDTH, JamsJoinModalInput, LiveJam, UpdateJamDrawing } from 'magma/common/interfaces-jams';
import { JamsService } from 'magma/services/jams.service';
import { ToastService } from 'magma/services/toast.service';
import { drawingsPath } from 'magma/common/data';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from 'services/user.service';
import { artJamsReadOnlyIcon, faEnvelope, faUsers, farExclamationTriangle } from 'magma/common/icons';
import { routeToArtJams } from 'shared/utils';
import { ModalService } from 'services/modal.service';
import { createTranslate, nonTranslatable } from 'magma/common/i18n';

const tr = createTranslate();

@UntilDestroy()
@Component({
  selector: 'jams-join-modal',
  templateUrl: 'jams-join-modal.component.pug',
  styleUrls: ['./jams-join-modal.component.scss', '../../jams/common.component.scss'],
})
export class JamsJoinModalComponent {
  readonly THUMB_WIDTH = JAM_THUMB_WIDTH;
  readonly THUMB_HEIGHT = JAM_THUMB_HEIGTH;
  readonly matureWarningIcon = farExclamationTriangle;
  readonly usersIcon = faUsers;
  readonly archiveIcon = artJamsReadOnlyIcon;
  @ViewChild('thumb') thumbCanvas!: ElementRef<HTMLCanvasElement>;
  @Output() close = new EventEmitter<UpdateJamDrawing | undefined>();
  @Input() data!: JamsJoinModalInput;
  expandRules = false;
  expandDescription = false;
  acceptedRules = false;

  liveJamData: LiveJam | undefined;

  constructor(private router: Router, private jamsService: JamsService, private toastService: ToastService, private userService: UserService, private modalsService: ModalService) { }

  private getLocationForAnalytics() {
    return this.router.url;
  }

  ngAfterViewInit() {
    this.jamsService.observeJam(this.data.jamDrawing.shortId).pipe(untilDestroyed(this)).subscribe(async jamDrawing => {
      if (jamDrawing) {
        this.data.jamDrawing = jamDrawing;
      }
    });

    this.jamsService.observeLiveJam(this.data.jamDrawing.shortId).pipe(untilDestroyed(this)).subscribe(async update => {
      if (update) this.liveJamData = update;
    });

    this.acceptedRules = this.hasJoined();
  }

  async leaveJam() {
    try {
      await this.jamsService.leave(this.data.jamDrawing.shortId, this.getLocationForAnalytics());
    } catch (e) {
      this.toastService.error({ message: 'Failed to leave Art Jam', subtitle: e.message });
    }
  }

  async joinAndOpen() {
    try {
      if (this.data.jamDrawing.jam.nsfw) {
        await this.jamsService.ensureUserMatureContentAccess();
      }

      if (this.data.jamDrawing.jam.nsfw && !this.jamsService.showNsfw$.getValue()) {
        await this.jamsService.updateArtJamsMatureFilter(true);
      }

      if ((this.hasRules() && this.acceptedRules) || !this.hasRules()) {
        await this.jamsService.join(this.data.jamDrawing.shortId, this.getLocationForAnalytics(), this.data.indexOnTheList);
      }
      void this.router.navigate([drawingsPath, this.data.jamDrawing.shortId]);
      this.close.emit();
      if (this.data.fromEditor) window.location.reload();
    } catch (e) {
      this.toastService.error({ message: 'Failed to join Art Jam', subtitle: e.message });
    }
  }

  openDrawing() {
    void this.router.navigate([drawingsPath, this.data.jamDrawing.shortId]);
    this.close.emit();
  }

  hasJoined() {
    return !!(this.userService.userId && this.data.jamDrawing.jam.users.find(userId => userId === this.userService.userId));
  }

  hasRules() {
    return !!this.data.jamDrawing.jam.rules?.length;
  }

  isArchived() {
    return this.data.jamDrawing.jam.archived;
  }

  canJoin() {
    return ((this.hasRules() && this.acceptedRules) || !this.hasRules()) && this.isUserNonAnonymous && this.isUserVerified && !this.isArchived();
  }

  async closeModal() {
    this.close.emit();
    if (this.data.fromEditor && !this.hasJoined()) {
      void this.router.navigate(routeToArtJams());
    }
  }

  get closeLabel() {
    return (this.data.fromEditor && !this.hasJoined()) ? 'Back to Art Jams' : 'Close';
  }

  get isUserNonAnonymous() {
    return this.userService.user?.userType !== 'anonymous';
  }

  get isUserVerified() {
    return !this.userService.user?.unverifiedEmail;
  }

  get isOwner() {
    return this.userService.user?._id === this.data.jamDrawing.author?._id;
  }

  async resendEmail() {
    try {
      await this.userService.resendEmailVerification();
      this.toastService.success({ message: tr`We've send a link to verify your email to ${nonTranslatable(this.userService.user!.unverifiedEmail)}, please check your inbox`, icon: faEnvelope });
    } catch (e) {
      this.toastService.error({ message: 'Failed to resend email' });
    }
  }

  async signUp() {
    await this.modalsService.signUp(true);
  }

  async unarchive() {
    try {
      await this.jamsService.unarchive(this.data.jamDrawing.shortId);
      this.toastService.success({ message: 'Successfully restored Art Jam' });
    } catch (e) {
      this.toastService.error({ message: 'Failed to restore Art Jam', subtitle: e.message });
    }
  }

  get tags() {
    return this.data.jamDrawing.tags;
  }
}
