import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Permission } from 'magma/common/interfaces';
import { ProjectService } from 'services/projects.service';
import { TeamService } from 'services/team.service';
import { ProjectData } from 'shared/interfaces';
import { routeToTeam, getLastProject, removeLastProject } from 'shared/utils';
import { BlogService } from 'services/blog.service';
import { clampIndex } from 'magma/common/mathUtils';

@Component({
  selector: 'delete-project-modal',
  templateUrl: './delete-project-modal.component.pug',
})
export class DeleteProjectModalComponent {
  @Input() data!: ProjectData;
  @Output() close = new EventEmitter();
  error = '';
  loading = false;
  constructor(
    private projectService: ProjectService,
    private teamService: TeamService,
    private router: Router,
    private blogService: BlogService,
  ) { }
  async submit() {
    try {
      this.loading = true;
      const slug = this.teamService.getTeamSlug(this.data.team)!;
      await this.projectService.deleteProject(this.data._id, slug);
      if (getLastProject(slug) === this.data._id) {
        removeLastProject(slug);
      }

      if (this.blogService.isCommunityHubProject(this.data._id)) {
        const index = this.blogService.communityHubProjects.findIndex(project => project._id === this.data._id);
        if (index === -1) throw new Error('Community-hub project not existent!');
        this.blogService.communityHubProjects.splice(index, 1);
        const navigateTo = ['community-hub', this.blogService.communityHubProjects[clampIndex(index - 1, this.blogService.communityHubProjects)]._id];
        await this.router.navigate(navigateTo);
      } else {
        await this.router.navigate(routeToTeam(slug));
      }
      this.close.emit();
    } catch (e) {
      this.loading = false;
      this.error = e.message;
    }
  }
  onClose() {
    this.close.emit();
  }
  get canDeleteProject() {
    return this.teamService.hasPermissionFlag(Permission.CanDeleteProjects);
  }
}
