import { Component, Input } from '@angular/core';
import { compareRoles } from 'magma/common/userRole';
import { belowBreakpointSM } from 'magma/common/utils';
import { Participant } from 'shared/interfaces';

function compareOnline(a: boolean, b: boolean) {
  return a === b ? 0 : (a ? -1 : 1);
}

@Component({
  selector: 'participants-list',
  templateUrl: './participants-list.component.html',
  styleUrls: ['./participants-list.component.scss'],
})
export class ParticipantsListComponent {
  @Input() participantNumber?: number = undefined;

  get overflowThreshold() {
    if (this.participantNumber !== undefined) {
      return this.participantNumber;
    }
    return belowBreakpointSM() ? 3 : 6;
  }

  get overflowLimit() {
    return this.overflowThreshold + (8 * 5);
  }

  private _participants: Participant[] = [];

  get participants() {
    return this._participants;
  }

  @Input()
  set participants(participants: Participant[]) {
    this._participants = participants ? [...participants] : [];
    this._participants.sort((a, b) => compareRoles(a.role, b.role) || compareOnline(a.isOnline, b.isOnline));
  }

  byId(_: number, participant: Participant) {
    return participant._id;
  }
}
