import { AbstractControl, ValidatorFn, FormGroup } from '@angular/forms';
import { hasBadWords } from '../common/swears';
import { isLowercase } from '../common/baseUtils';

export const matchingControls = (c1: AbstractControl, c2: AbstractControl): ValidatorFn => {
  return () => {
    if (c1.value !== c2.value) {
      return { matchingControls: 'Both inputs must hold the same value', _matchingControls_c2touched: c2.touched };
    } else {
      return null;
    }
  };
};

export const hasSpecialChars = (str: string): boolean => !!str.match(new RegExp('[^a-zA-Z0-9-_]', 'gi'));

export const noSpecialChars: ValidatorFn = (control: AbstractControl) => {
  if (hasSpecialChars(control.value)) {
    return { noSpecialCharacters: 'You can\'t use special characters here' };
  } else {
    return null;
  }
};

export const isOnlyNumbers = (str: string): boolean => Number.isFinite(parseInt(str));

export const noOnlyNumbers: ValidatorFn = (control: AbstractControl) => {
  if (isOnlyNumbers(control.value)) {
    return { noOnlyNumbers: 'This can\'t consist of only numbers' };
  } else {
    return null;
  }
};

export const cantHaveBadWords: ValidatorFn = (control: AbstractControl) => {
  if (hasBadWords(control.value)) {
    return { cantHaveBadWords: 'This can\'t contain any abusive language' };
  } else {
    return null;
  }
};

export const isLowercaseOnly: ValidatorFn = (control: AbstractControl) => {
  if (!isLowercase(control.value)) {
    return { isLowercaseOnly: 'This has to be only lowercase' };
  } else {
    return null;
  }
};

export const notEqualTo = (notEqualTo: (() => string), alternativeMessage = ''): ValidatorFn => {
  return (control: AbstractControl) => {
    const notEqualToWhat = notEqualTo();
    if (control.value === notEqualToWhat) {
      return { notEqualTo: alternativeMessage ||  `This can't be "${notEqualToWhat}"`};
    } else {
      return null;
    }
  };
};

const reactiveFormErrorToString = ([errorKey, errorData]: [string, any]) => {
  switch (errorKey) {
    case 'required':
      return 'This property is required';
    case 'maxlength':
      return `This property can't have more than ${errorData.requiredLength} characters`;
    default:
      return errorData;
  }
};

export const listFormErrors = (form: FormGroup | undefined) => {
  const errors: string[] = [];
  if (!form || !form.dirty) return errors;

  const formErrors = form.errors ?? { };
  for (const entry of Object.entries(formErrors)) {
    if (entry[0] === '_matchingControls_c2touched') continue;
    if (entry[0] !== 'matchingControls' || formErrors['_matchingControls_c2touched']) {
      errors.push(reactiveFormErrorToString(entry));
    }
  }

  for (const control of Object.values(form.controls)) {
    const controlErrors = control.errors ?? {};
    for (const entry of Object.entries(controlErrors)) {
      errors.push(reactiveFormErrorToString(entry));
    }
  }

  return errors;
};
