import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, NEVER, merge, of, Subject, Subscription, Observable } from 'rxjs';
import { distinctUntilChanged, filter, finalize, switchMap, ignoreElements, tap, catchError } from 'rxjs/operators';
import type { VoiceChat, VoiceChatParticipant, VoiceControls, MediasoupVoiceChatProvider } from '@codecharm/voice-chat-client-mediasoup';
import { voiceChatUrl } from 'magma/common/data';
import { removeElement, toggleClass } from 'magma/common/htmlUtils';
import { Analytics, Feature, OtherAction, User, CancellablePromise } from 'magma/common/interfaces';
import { getUrl } from 'magma/common/rev';
import { removeFromMap } from 'magma/common/utils';
import { isiOS, isiPhone, isAndroid } from 'magma/common/userAgentUtils';
import { removeItem } from 'magma/common/baseUtils';
import { Model, havePermission, userHasPermission } from 'magma/services/model';
import { storageGetBoolean, storageGetJson, storageGetNumber, storageSetItem } from 'magma/services/storage';
import { defaultVoiceChatSettings, VoiceChatService, VoiceChatSettings, VoiceChatSounds, VoiceChatUser, isKnownDeviceError, closeStream, onStreamClosed, VoiceChatStream } from 'magma/services/voiceChatService';
import { ErrorReporter } from 'magma/services/errorReporter';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SECOND, NO_CAMERA_PERMISSIONS_TOAST, NO_CAMERA_DEVICE_TOAST, NO_MIC_PERMISSIONS_TOAST, NO_MIC_DEVICE_TOAST, NO_START_VOICE_CALL_PERMISSION } from 'magma/common/constants';
import { FeatureFlagService } from 'magma/services/feature-flag.service.interface';
import { VcFeedback } from 'magma/components/shared/modals/vc-feedback-modal/vc-feedback-modal';
import { voiceChatActionWithRetry, RealModel } from 'magma/services/real-model';
import { logAction } from 'magma/common/actionLog';
import { isError, isStream } from 'magma/common/typescript-utils';
import { ToastService } from 'magma/services/toast.service';
import { Router, NavigationStart } from '@angular/router';
import { remove } from 'lodash';
import { UserError } from 'magma/common/userError';

const USE_SOUNDS = true;

interface Participant {
  uniqId: string;
  participant: VoiceChatParticipant<any>;
  video?: HTMLVideoElement;
  presentation?: HTMLVideoElement;
  audio: HTMLAudioElement;
  subscription: Subscription;
  element: HTMLElement | undefined;
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class ActualVoiceChatService extends VoiceChatService {
  readonly canChangeOutputDevice = ((typeof window.Audio !== 'undefined') && ('setSinkId' in (new Audio())));
  session = false; // true if session exists in current drawing
  drawingId: string | undefined = undefined; // used for reconnecting after refresh
  muted = new Set<string>(); // users muted by themselves
  joined = new Set<string>(); // users joined
  mutedByAdmin = new Set<string>(); // users muted by admin
  onError = new Subject<string>();
  onTalking = new Subject<User | undefined>();
  onVoiceActivated = new BehaviorSubject<boolean>(false);
  settings: VoiceChatSettings = { ...defaultVoiceChatSettings, ...storageGetJson('voice-chat-settings') };
  isConnected = false; // TODO: maybe these should be one state field instead ?
  isConnecting = false;
  userElement: HTMLElement | undefined = undefined;
  model: Model | undefined = undefined;
  private sessionStartTime = 0;

  private sounds: VoiceChatSounds = (USE_SOUNDS && voiceChatUrl) ? {
    started: createSound('call_started'),
    joined: createSound('call_joined'),
    muted: createSound('call_muted'),
    unmuted: createSound('call_unmuted'),
    leftMe: createSound('call_left_me'),
    leftOther: createSound('call_left_other'),
  } : ({} as any);
  private token$ = new BehaviorSubject<string | undefined>(undefined);
  private voiceChat$ = new BehaviorSubject<VoiceChat | undefined>(undefined);
  private controls$ = new BehaviorSubject<VoiceControls | undefined>(undefined);
  private usersVolumes = new Map<string, number>();
  private usersMuted = new Set<string>();
  private participantsByConnectionId = new Map<string, Participant>();
  private participantsByUniqId = new Map<string, Participant>();
  private userElementsByUniqId = new Map<string, HTMLElement>();
  private initializedSounds = false;
  private chatProvider: MediasoupVoiceChatProvider | undefined = undefined;
  private talkingStack: Participant[] = [];
  private updatedTokenAt = 0;
  private _videoUsers = [] as VoiceChatUser[];
  private _screensharingUsers = [] as VoiceChatUser[];

  get videoUsers() {
    return this._videoUsers;
  }

  get screensharingUsers() {
    return this._screensharingUsers;
  }

  hasVideo() {
    return !!this.selfVideoElement;
  }

  ngOnDestroy() {
    logAction('[voice] actualVoiceChatService.ngOnDestroy()');
  }

  constructor(
    private zone: NgZone,
    private errorReporter: ErrorReporter,
    private features: FeatureFlagService,
    private toastService: ToastService,
    private router: Router,
  ) {
    super();

    if (DEVELOPMENT) (window as any).vc = this;

    this.router.events.pipe(untilDestroyed(this)).subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.navigatingAway = true;
      }
    });

    if (IS_PORTAL && voiceChatUrl) {
      void this.initLibrary();
    }

    // leave note that we closed the page with connected voice chat, so we knot to reconnect
    window.addEventListener('beforeunload', () => {
      if ((this.isConnected || this.isConnecting) && this.drawingId) {
        this.consumeTokens = false;
        this.stop('window:beforeunload');
        storageSetItem('voice-chat-connected', JSON.stringify({ id: this.drawingId, time: Date.now() }));
      }
    });

    try {
      for (const [name, volume, muted] of this.settings.users) {
        if (name && volume !== 1) this.usersVolumes.set(name, volume);
        if (name && muted) this.usersMuted.add(name);
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
    }

    // TEMP: we changed name of some of the keys, fix them here
    if (!['column', 'focus', 'cursors'].includes(this.settings.videoLayout)) {
      this.settings.videoLayout = 'column';
    }
  }

  private getUserMedia(constraints: MediaStreamConstraints | undefined): Promise<MediaStream> {
    if (navigator.mediaDevices.getUserMedia !== undefined && typeof navigator.mediaDevices.getUserMedia === 'function') {
      return navigator.mediaDevices.getUserMedia(constraints);
    } else if ((navigator.mediaDevices as any).webkitGetUserMedia !== undefined && typeof (navigator.mediaDevices as any).webkitGetUserMedia === 'function') {
      return (navigator.mediaDevices as any).webkitGetUserMedia(constraints);
    } else if ((navigator.mediaDevices as any).mozGetUserMedia && typeof (navigator.mediaDevices as any).mozGetUserMedia === 'function') {
      return (navigator.mediaDevices as any).mozGetUserMedia(constraints);
    } else {
      logAction(`navigator.mediaDevices functions: [${Object.keys(navigator.mediaDevices).filter(k => typeof navigator.mediaDevices[k as keyof typeof navigator.mediaDevices] === 'function').join(', ')}]`);
      throw new Error('Unsupported getUserMedia implementation');
    }
  }

  vcLogs$: Observable<string> = of('');
  vcLogs$subscription: Subscription | undefined = undefined;

  private initObservables() {
    this.zone.runOutsideAngular(() => {
      this.token$.pipe(
        filter(token => this.consumeTokens || token === undefined),
        distinctUntilChanged(),
        switchMap(token => {
          try {
            if (token && this.voiceChat) {
              this.updatedTokenAt = performance.now();
              return this.voiceChat.updateToken(token);
            }
          } catch (e) {
            this.errorReporter.reportError('updateToken failed', e);
          }

          return Promise.resolve(token);
        }),
        filter((result): result is undefined | string => typeof result !== 'boolean'),
        switchMap(token => {
          try {
            this.zone.run(() => this.isConnecting = !!token);

            this.vcLogs$subscription?.unsubscribe();
            this.vcLogs$subscription = undefined;
            if (token) {
              return this.chatProvider!.connectSession(voiceChatUrl!, token, (vc: VoiceChat) => {
                this.vcLogs$ = vc.logs$;
                this.vcLogs$subscription = this.vcLogs$.pipe(untilDestroyed(this)).subscribe((log) => {
                  logAction(`[voice] ${log}`);
                });
              }).pipe(finalize(() => {
                this.stop('pipe-finalize');
                this.voiceChat$.next(undefined);
              }));
            }
          } catch (e) {
            this.errorReporter.reportError('connectSession failed', e);
            this.onError.next(`Error: ${e.message}`);
            this.stop('connectSession catch');
          }

          return of(undefined);
        }),
        catchError((e) => {
          if (e.message.match(/Failed to fetch|fetch resource/)) {
            this.toastService.error({ message: 'Sorry, but it seems voice chat is currently offline' });
          }
          this.vcLogs$subscription?.unsubscribe();
          this.vcLogs$subscription = undefined;
          this.reset();
          this.selfVideoElement?.remove();
          this.errorReporter.reportError('token$ error', e);
          return of(undefined);
        }),
        untilDestroyed(this),
      ).subscribe(voiceChat => {
        this.voiceChat$.next(voiceChat);
      });

      this.voiceChat$
        .pipe(
          switchMap(async voiceChat => {
            if (!voiceChat) return of(undefined);

            this.sessionStartTime = new Date().getTime();
            this.applySettings();

            try {
              this.selfAudioStream = await this.getAudioStream(() => this.onAudioStreamFinished());
              return voiceChat.join(this.selfAudioStream);
            } catch (error) {
              this.zone.run(() => {
                DEVELOPMENT && console.log(error);
                this.stop('voiceChat$ catch');
              });
              return of(undefined);
            }
          }),
          switchMap(x => x),
          catchError((e) => {
            this.errorReporter.reportError('voiceChat$ error', e);
            return of(undefined);
          }),
          untilDestroyed(this),
        ).subscribe(voiceControls => {
          this.controls$.next(voiceControls);
          if (voiceControls) {
            voiceControls.pushToTalkActivation$.next(false);
            this.zone.run(() => {
              this.applyMuted();
              if (this.settings.videoEnabled) {
                this.enableVideo(voiceControls).catch((e) => DEVELOPMENT && console.error(e));
              } else {
                this.disableVideo(voiceControls);
              }
            });
          } else {
            this.onTalking.next(undefined);
            this.zone.run(() => {
              this.participantsByConnectionId.forEach(releaseParticipant);
              this.participantsByConnectionId.clear();
              this.participantsByUniqId.clear();
              this.talkingStack.length = 0;
              this.userElement?.classList.remove('is-talking');
              this.isConnected = false;
              this.isConnecting = false;
            });
          }
        });

      this.controls$.pipe(
        switchMap(controls => controls?.isActivated$ ?? NEVER),
        untilDestroyed(this),
        catchError((e) => {
          this.errorReporter.reportError('controls$ error', e);
          return of(undefined);
        }),
      ).subscribe(talking => {
        if (talking === undefined) return;
        if (this.userElement && !this.mutedByAdmin.has(this.model?.user.uniqId ?? '')) {
          toggleClass(this.userElement, 'is-talking', talking);
          this.onVoiceActivated.next(talking);
        }
      });

      this.voiceChat$.pipe(
        switchMap(voiceChat => voiceChat ? voiceChat.canChangeAudioDevice$ : NEVER),
        untilDestroyed(this),
        catchError((e) => of(e)),
      ).subscribe((value) => this.canChangeAudioDevice$.next(value));

      this.voiceChat$.pipe(
        switchMap(voiceChat => voiceChat ? voiceChat.errors$ : NEVER),
        filter(v => !!v),
        untilDestroyed(this),
        catchError((e) => of(e)),
      ).subscribe((err) => {
        if (isError(err)) {
          this.errorReporter.reportError(err.message, err);
        } else {
          this.errorReporter.reportError(err);
        }
      });

      this.voiceChat$.pipe(
        switchMap(voiceChat => voiceChat ? voiceChat.feedback$ : NEVER),
        filter(v => !!v),
        untilDestroyed(this),
        catchError((e) => of(e)),
      ).subscribe((feedback) => {
        const { type } = feedback;
        const toast = { message: feedback.message };
        switch (type) {
          case 'info': this.toastService.info(toast); break;
          case 'error': this.toastService.error(toast); break;
          case 'warning': this.toastService.warning(toast); break;
          case 'success': this.toastService.warning(toast); break;
          default: break;
        }
      });

      this.voiceChat$.pipe(
        switchMap(voiceChat => voiceChat ? voiceChat.connection$ : of(undefined)),
        filter(v => !!v),
        untilDestroyed(this),
        catchError(err => {
          DEVELOPMENT && console.error(err);
          return of(err);
        }),
      ).subscribe((state) => {
        logAction(`[voice] connection$ (iceConnectionStateChange): ${state}`);
        switch (state!) {
          case 'new':
          case 'checking':
          case 'completed': {
            break;
          }
          case 'connected': {
            this.isConnected = true;
            this.isConnecting = false;
            if (!this.justUpdatedToken) this.playSound('joined');
            break;
          }
          case 'failed':
          case 'closed':
          case 'disconnected': {
            this.stop(`connection$.next(${state})`);
            break;
          }
          default: {
            logAction(`[voice] invalid (unhandled) ice state - ${state}`);
            DEVELOPMENT && console.log(`Invalid (unhandled) ice state - ${state}`);
            break;
          }
        }
      });

      this.voiceChat$
        .pipe(
          switchMap(voiceChat => voiceChat ? voiceChat.participants$ : of([])),
          untilDestroyed(this),
        ).subscribe(participants => {
          let sound: keyof VoiceChatSounds | undefined = undefined;

          // determining what happened
          const participantsThatLeft = new Set<string>([]);
          const participantsThatJoined = new Set<string>([]);

          this.participantsByConnectionId.forEach((participant, key) => {
            const foundParticipant = participants.find(p => p.connectionId === key);

            if (!foundParticipant) {
              // remove participants
              const participant2 = this.participantsByConnectionId.get(key)!;
              this.participantsByConnectionId.delete(key);
              this.participantsByUniqId.delete(participant2.uniqId);
              this.updateTalking(participant2, false);
              releaseParticipant(participant2);
              participantsThatLeft.add(participant.participant.data.peerId);
            } else {
              // participant was present and is preset
            }
          });

          for (const p of participants) {
            // add new participant
            if (!this.participantsByConnectionId.has(p.connectionId)) {
              participantsThatJoined.add(p.data.peerId);
              const uniqId = `${p.data.peerId}`;
              const audio = new Audio();

              if (!this.canChangeOutputDevice && this.settings.outputDeviceId) {
                (audio as any).setSinkId(this.settings.outputDeviceId)
                  .catch((e: Error) => DEVELOPMENT && console.error(e));
              }

              document.body?.appendChild(audio); // body is null sometimes
              p.setHostElement(audio);
              const participant: Participant = { uniqId, participant: p, audio, subscription: undefined as any, element: undefined };

              let videoElement: HTMLVideoElement;
              p.onNewMediaTrack = async (mediaTag, track: MediaStreamTrack) => {
                if (mediaTag === 'cam-video') {
                  videoElement = participant.video = this.videoElementFromTrack(track, () => {
                    participant.video!.hidden = true;
                  });
                  participant.video.classList.add('vc-video');
                  this.onNewVideo.next(videoElement);

                  return true;
                } else if (mediaTag.startsWith('present')) {
                  const screenshareVideoElement = participant.presentation = this.videoElementFromTrack(track);

                  track.addEventListener('ended', () => {
                    this.zone.run(() => {
                      screenshareVideoElement.dispatchEvent(new Event('ended'));
                      screenshareVideoElement.remove();
                      participant.presentation = undefined;
                      const item = this.screensharingUsers.find((u) => u.uniqId === p.data.peerId);
                      removeItem(this._screensharingUsers, item);
                    });
                  });

                  this.screensharingUsers.push({ uniqId: participant.uniqId, name: '' });

                  this.onNewPresentation.next(screenshareVideoElement);

                  return true;
                }
                return false;
              };

              this.participantsByConnectionId.set(p.connectionId, participant);
              this.participantsByUniqId.set(uniqId, participant);
              const isTalkingMonitor$ = participant.participant.isTalking$.pipe(tap((talking) => {
                this.updateTalking(participant, talking as boolean);
              }), ignoreElements());
              const hasVideoMonitor$ = p.hasVideo$.pipe(tap(hasVideo => {
                this.zone.run(() => {
                  if (videoElement) {
                    // can't delete the element since it hold the producer stream which is created once
                    // and may have replaced track later on
                    videoElement.hidden = !hasVideo;
                    if (!hasVideo) {
                      const item = this._videoUsers.find((u) => u.uniqId === p.data.peerId);
                      removeItem(this._videoUsers, item);
                    } else {
                      this._videoUsers.push({ uniqId, name: '' });
                    }
                  }
                });
              }));

              participant.subscription = merge(isTalkingMonitor$, hasVideoMonitor$).subscribe(() => { }, e => {
                this.errorReporter.reportError('merge(isTalkingMonitor$, hasVideoMonitor$) error', e);
              });
              setParticipantElement(participant, this.userElementsByUniqId.get(uniqId));
            }
          }

          this._videoUsers = Array.from(this.participantsByConnectionId.values()).filter(u => !!u.video).map(({ uniqId }) => ({ uniqId, name: '' }));
          this._screensharingUsers = Array.from(this.participantsByConnectionId.values()).filter(u => !!u.presentation).map(({ uniqId }) => ({ uniqId, name: '' }));

          if (participantsThatJoined.size) {
            sound = 'joined';
            this.updateUserMutes();
          } else if (participantsThatLeft.size) {
            sound = 'leftOther';
          }

          if (sound && !this.isConnecting && !this.justUpdatedToken) {
            this.playSound(sound);
          }
        }, (e) => {
          this.errorReporter.reportError('participants$ error', e);
          return of(undefined);
        });
    });
  }
  videoForUser(user: VoiceChatUser): HTMLVideoElement | undefined {
    if (user.uniqId === this.model?.uniqId) {
      return this.selfVideoElement;
    } else {
      return this.participantsByUniqId.get(user.uniqId)?.video;
    }
  }
  screenshareForUser(user: VoiceChatUser): HTMLVideoElement | undefined {
    if (user.uniqId === this.model?.uniqId) {
      return this.selfScreenshareElement;
    } else {
      return this.participantsByUniqId.get(user.uniqId)?.presentation;
    }
  }
  get controls() {
    return this.controls$.value;
  }

  private get voiceChat() {
    return this.voiceChat$.value;
  }

  private get justUpdatedToken() {
    return (performance.now() - this.updatedTokenAt) < (2 * SECOND);
  }

  private async initLibrary() {
    try {
      if (!this.chatProvider) {
        const library = await import('@codecharm/voice-chat-client-mediasoup' /* webpackChunkName: "voice-chat" */);
        library.enableProdMode();
        this.chatProvider = new library.MediasoupVoiceChatProvider();
        this.initObservables();
      }
    } catch (e) {
      console.error(e);
      this.errorReporter.reportError('Failed to import voice chat', e);
      // TODO: show error to user ?
    }
  }

  private isSupported() {
    return typeof RTCPeerConnection !== 'undefined' && 'getUserMedia' in navigator.mediaDevices && !!this.chatProvider?.isSupported();
  }

  async start(caller: string) {
    logAction(`[voice] start(${caller})`);
    if (this.isConnected || this.isConnecting) return;
    if (!this.model || !this.model.isConnected) return;
    if (!this.chatProvider) return;

    if (!this.isSupported()) {
      if (isiOS) {
        if (isiPhone) {
          throw new UserError(`Please switch to Safari browser to use voice chat on iPhone`);
        } else {
          throw new UserError(`Please switch to Safari browser to use voice chat on iPad`);
        }
      } else {
        throw new UserError(`Voice chat is not supported on this browser`);
      }
    }

    const model = this.model;

    if (this.session) {
      if (!havePermission(model, 'voiceListen')) {
        throw new UserError(`You don't have permission to join voice calls`);
      }
    } else {
      if (!havePermission(model, 'voiceTalk')) {
        throw new UserError(`You don't have permission to start voice calls`);
      }
    }

    this.consumeTokens = true;
    this.isConnecting = true;
    await this.join();
  }

  videoElementFromStream(stream: MediaStream) {
    const element = document.createElement('video');
    element.srcObject = stream;
    element.autoplay = true;
    element.playsInline = true;
    return element;
  }

  private videoElementFromTrack(track: MediaStreamTrack, destructor = () => { }) {
    const video = document.createElement('video');
    video.autoplay = true;
    const stream = new MediaStream([track]);
    onStreamClosed(stream, destructor);
    video.srcObject = stream;
    video.playsInline = true;
    video.hidden = true;
    document.body.appendChild(video);
    return video;
  }

  private applyVideoCursors() {
    if (this.model?.editor) {
      this.model.editor.settings.includeVideo = this.settings.videoLayout === 'cursors';
    }
  }

  async join() {
    if (!this.model) throw new Error('Model is not initialized');
    logAction(`[voice] join`);

    if (!this.settings.rememberCameraState) {
      this.settings.videoEnabled = false;
    }
    this.applySettings();
    this.applyVideoCursors();

    // play sound right away to unlock audio on iOS
    if (!this.initializedSounds) {
      this.initializedSounds = true;
      const sound = this.sounds.leftOther;
      if (sound) {
        sound.volume = 0.01;
        sound.play()
          .then(() => sound.pause())
          .catch(e => DEVELOPMENT && console.warn(e));
      }
    }

    try {
      await voiceChatActionWithRetry(this.model, OtherAction.JoinVoiceChat, this.settings, 'Failed to connect to voice chat');
    } catch (e) {
      if (isError(e)) {
        if (e.message === NO_START_VOICE_CALL_PERMISSION) {
          this.toastService.error({ message: NO_START_VOICE_CALL_PERMISSION });
        } else if (e.message.match(/^\(disconnected\)|\(socket not found\)$/gi)) {
          this.toastService.error({ message: 'Failed to connect to voice chat' });
        } else {
          this.errorReporter.reportError('Failed to connect to voice chat', e);
          this.toastService.error({ message: 'Failed to connect to voice chat' });
        }
      } else if (!e.match(/^\(disconnected\)|\(socket not found\)$/gi)) {
        this.errorReporter.reportError('Failed to connect to voice chat', e);
      }
      this.stop('failed-join');
      throw e;
    }
  }

  setToken(token: string | undefined, caller: string) {
    logAction(`[voice] setToken(${!!token}, ${caller}${(!this.consumeTokens && token !== undefined) ? ', aborted' : ''})`);
    this.zone.runOutsideAngular(() => {
      this.token$.next(token);
    });
  }

  private trackCameraState(enabled: boolean) {
    const key = enabled ? Analytics.CameraEnabled : Analytics.CameraDisabled;
    const value = {
      isCameraStatusSaved: this.settings.rememberCameraState,
      numberOfUsersOnVoiceChat: this.participantsByUniqId.size + 1,
      numerOfUsersPresent: (this.model?.drawing?.users.length ?? 0) + 1,
    };
    this.model?.trackEvent(key, value);
  }

  private trackRandomDisconnect(model: Model, caller: string) {
    const reportData = {
      sessionTime: Date.now() - this.sessionStartTime,
      participantsByConnectionId: this.participantsByConnectionId.size,
      participantsByUniqId: this.participantsByUniqId.size,
      caller,
    };
    logAction('[voice] random dc reported');
    const error = new Error('[voice] random voice chat disconnect');
    this.errorReporter.reportError(error.message, error, reportData);
    model.trackEvent(Analytics.NotManualVcDisconnection, reportData);
  }

  async stopAsync(caller: string) {
    logAction(`[voice] stopAsync(${caller})`);
    const manualDc = this.isManualDc(caller);
    let promise: Promise<void> | undefined = undefined;

    if (this.model) {
      if (this.isConnected || this.isConnecting) {
        if (!manualDc) this.trackRandomDisconnect(this.model, caller);
        promise = voiceChatActionWithRetry(this.model, OtherAction.LeaveVoiceChat, undefined, 'LeaveVoiceChat failed');
      }
      this.displayVcFeedbackModal(this.model, manualDc);
    } else {
      DEVELOPMENT && console.warn(`[voice] missing model in actualVoiceChatService.ts stopAsync(${caller})`);
    }

    this.finishStop();

    return promise;
  }

  stop(caller: string) {
    const manualDc = this.isManualDc(caller);
    logAction(`[voice] stop(${caller}, ${this.consumeTokens})`);

    if (this.model) {
      if (this.isConnected || this.isConnecting) {
        if (!manualDc) this.trackRandomDisconnect(this.model, caller);
        voiceChatActionWithRetry(this.model, OtherAction.LeaveVoiceChat, undefined, 'LeaveVoiceChat failed')
          .catch(e => { if (DEVELOPMENT) console.error(e); });
      }
      this.displayVcFeedbackModal(this.model, manualDc);
    } else {
      const msg = `[voice] missing model in actualVoiceChatService.ts stop(${caller})`;
      logAction(msg);
      DEVELOPMENT && console.warn(msg);
    }

    this.finishStop();
  }

  private isManualDc(stopCaller: string) {
    if (!this.consumeTokens) return true;
    if (this.navigatingAway) return true;
    if (!this.session) return true;
    return stopCaller !== 'pipe-finalize';
  }

  private finishStop() {
    this.setToken(undefined, 'finishStop');
    if (this.isConnected || this.isConnecting) this.playSound('leftMe');

    // reset these values here, so we can immediately start again, otherwise we'd have to wait for token rxjs pipeline to finish.
    this.isConnected = false;
    this.isConnecting = false;

    this.stopScreensharing();

    if (this.selfAudioStream) closeStream(this.selfAudioStream);
    if (this.selfVideoStream) closeStream(this.selfVideoStream);
    this.sessionStartTime = 0;

    setTimeout(() => Array.from(document.querySelectorAll('video.vc-video')).forEach((e) => e.remove()), 25);
  }

  reset() {
    this.stop('reset');
    this.setToken(undefined, 'reset');
    this.session = false;
    this.lonelyCall = true;
    this.muted.clear();
    this.mutedByAdmin.clear();
    this.joined.clear();
  }

  addUserElement(uniqId: string, element: HTMLElement) {
    this.userElementsByUniqId.set(uniqId, element);
    const participant = this.participantsByUniqId.get(uniqId);
    if (participant) setParticipantElement(participant, element);
  }

  removeUserElement(uniqId: string, element: HTMLElement) {
    const participant = this.participantsByUniqId.get(uniqId);
    if (participant) setParticipantElement(participant, undefined);
    removeFromMap(this.userElementsByUniqId, value => value === element);
  }

  getUserVolume(user: VoiceChatUser) {
    return this.usersVolumes.get(user.name) ?? 1;
  }

  setUserVolume(user: VoiceChatUser, volume: number) {
    if (volume === 1) this.usersVolumes.delete(user.name);
    else this.usersVolumes.set(user.name, volume);

    const participant = this.participantsByUniqId.get(user.uniqId);
    participant?.participant.outputGain$.next(volume);

    this.saveSettings();
  }

  isMuted() {
    return this.settings.mute || this.settings.deafen || (this.model ? !havePermission(this.model, 'voiceTalk') : false);
  }

  isUserMuted(user: VoiceChatUser) {
    return this.usersMuted.has(user.name);
  }

  setUserMuted(user: VoiceChatUser, muted: boolean) {
    if (muted) {
      this.usersMuted.add(user.name);
    } else {
      this.usersMuted.delete(user.name);
    }

    const participant = this.participantsByUniqId.get(user.uniqId);
    if (participant) this.updateMute(participant);

    this.saveSettings();
  }

  updateUserMutes() {
    // make sure we remove talking indicator for user if they got muted by admin
    if (this.userElement && this.mutedByAdmin.has(this.model?.user.uniqId ?? '')) {
      this.userElement.classList.remove('is-talking');
    }

    this.participantsByUniqId.forEach(participant => this.updateMute(participant));
    this.model?.editor?.apply(() => { });
  }

  private isParticipantMuted(participant: Participant) {
    if (this.mutedByAdmin.has(participant.uniqId)) return true;

    const user = this.model?.getUserByUniqId(participant.uniqId);
    return !!user && (this.isUserMuted(user) || !userHasPermission(this.model!, user, 'voiceTalk'));
  }

  private updateMute(participant: Participant) {
    participant.participant.mute$.next(this.isParticipantMuted(participant));
  }

  updateOutputDevice() {
    if (!this.canChangeOutputDevice) return;

    const deviceId = this.settings.outputDeviceId;

    this.participantsByConnectionId.forEach(value => {
      const audio = value.audio as any;
      audio.setSinkId(deviceId).catch((e: Error) => DEVELOPMENT && console.error(e));
    });
  }

  applyMuted() {
    this.applySettings();

    if (this.model) {
      const muted = this.settings.mute || this.settings.deafen;
      voiceChatActionWithRetry(this.model, muted ? OtherAction.MuteVoiceChat : OtherAction.UnmuteVoiceChat, undefined, 'MuteUnmute failed')
        .catch(e => DEVELOPMENT && console.error(e));
    }
  }

  private screenshareHandle: ReturnType<VoiceControls['present']> | undefined;
  private selfVideoElement?: HTMLVideoElement;
  private selfScreenshareElement?: HTMLVideoElement;

  isScreensharing() {
    return this.screenshareHandle && this.selfScreenshareElement && !this.selfScreenshareElement?.paused || false;
  }

  async startScreensharing() {
    if (!this.controls || !this.model) return;
    if (this.screenshareHandle) return;

    const stream = await navigator.mediaDevices.getDisplayMedia({ audio: false });
    if (!this.controls) {
      closeStream(stream);
      return;
    }

    this.selfScreenshareElement = this.videoElementFromTrack(stream.getVideoTracks()[0]);
    this.screenshareHandle = this.controls.present(this.model.user.localId.toString(), stream);
    onStreamClosed(stream, () => this.stopScreensharing());
  }

  stopScreensharing() {
    if (!this.model) return;
    if (!this.screenshareHandle) return;

    this.screenshareHandle.remove();
    this.screenshareHandle = undefined;
    if (this.selfScreenshareElement) {
      closeStream(this.selfScreenshareElement.srcObject as MediaStream);
      this.selfScreenshareElement.dispatchEvent(new Event('ended'));
      this.selfScreenshareElement.remove();
      this.selfScreenshareElement = undefined;
    }
  }

  async getAudioStream(destructor = () => { }) {
    const devices = await getInputDevices();
    let lastUsedDevice = undefined;
    let defaultDevice = undefined;
    for (let i = 0; i < devices.length; i++) {
      const device = devices[i];
      if (device.deviceId === this.settings.inputDeviceId) lastUsedDevice = device;
      if (device.deviceId === 'default' || device.deviceId === 'auto') defaultDevice = device;
      if (lastUsedDevice && defaultDevice) break;
    }

    const audio = {
      deviceId: this.settings.inputDeviceId,
      noiseSuppression: !this.settings.disableNoiseSupression,
      echoCancellation: !this.settings.disableEchoCancellation,
    };

    if (lastUsedDevice) {
      const stream: MediaStream | Error = await this.getUserMedia({ audio }).catch((err0) => err0);
      if (isStream(stream)) {
        this.ensureCorrectInputStream(audio.deviceId, stream);
        return onStreamClosed(stream, destructor);
      } else {
        const finish = this.onFailedStreamAccess(stream);
        if (finish) return undefined;
      }
    }

    if (defaultDevice) {
      delete audio.deviceId;
      const stream: MediaStream | Error = await this.getUserMedia({ audio }).catch((err1) => err1);
      if (isStream(stream)) {
        this.ensureCorrectInputStream(undefined, stream);
        return onStreamClosed(stream, destructor);
      } else {
        const finish = this.onFailedStreamAccess(stream);
        if (finish) return undefined;
      }
    }

    for (const device of devices) {
      if (device === lastUsedDevice || device === defaultDevice) continue;
      audio.deviceId = device.deviceId;
      let stream: MediaStream | Error = await this.getUserMedia({ audio }).catch((err2) => err2);
      if (isStream(stream)) {
        this.ensureCorrectInputStream(audio.deviceId, stream);
        return onStreamClosed(stream, destructor);
      } else {
        const knownCode = isKnownDeviceError(stream);
        if (knownCode === 401) {
          this.unsupportedMic = true;
          this.settings.mute = true;
          this.applyMuted();
          this.toastService.error(NO_MIC_PERMISSIONS_TOAST);
          return undefined;
        } else if (!knownCode) {
          if (!isError(stream)) stream = new Error(`Error when getting audio stream: ${stream}`);
          this.errorReporter.reportError(stream.message, stream);
        }
      }
    }

    this.unsupportedMic = true;
    this.settings.mute = true;
    this.applyMuted();
    this.toastService.error(NO_MIC_DEVICE_TOAST);
    return undefined;
  }

  selfAudioStream: VoiceChatStream | undefined = undefined;
  selfVideoStream: VoiceChatStream | undefined = undefined;
  selfVideoStreamCreation: CancellablePromise<VoiceChatStream | undefined> | undefined = undefined;

  private async getVideoStream(deviceId?: string) {
    const video: MediaTrackConstraints = {
      width: { max: 640 },
      height: { max: 480 },
      aspectRatio: { ideal: 4 / 3 },
      frameRate: 30,
    };
    if (deviceId !== undefined) video.deviceId = deviceId;
    return this.getUserMedia({ video });
  }

  async createVideoStream(destructor = (stream: VoiceChatStream) => { }) {
    const devices = await getVideoDevices();
    let lastUsedDevice = undefined;
    let defaultDevice = undefined;
    for (let i = 0; i < devices.length; i++) {
      const device = devices[i];
      if (device.deviceId === this.settings.videoDeviceId) lastUsedDevice = device;
      if (device.deviceId === 'default' || device.deviceId === 'auto') defaultDevice = device;
      if (lastUsedDevice && defaultDevice) break;
    }

    if (lastUsedDevice) {
      const stream: MediaStream | Error = await this.getVideoStream(lastUsedDevice.deviceId).catch((err0) => err0);
      if (isStream(stream)) {
        return onStreamClosed(stream, destructor);
      } else {
        const knownCode = isKnownDeviceError(stream);
        if (knownCode === 401) {
          this.unsupportedWebcam = true;
          this.toastService.error(NO_CAMERA_PERMISSIONS_TOAST);
          return undefined;
        } else if (!knownCode) {
          logAction(`[voice] unrecognized error when getting video stream (err0): ${stream?.name ?? stream}`);
        }
      }
    }

    if (defaultDevice) {
      delete this.settings.videoDeviceId;
      const stream: MediaStream | Error = await this.getVideoStream(defaultDevice.deviceId).catch((err1) => err1);
      if (isStream(stream)) {
        return onStreamClosed(stream, destructor);
      } else {
        const knownCode = isKnownDeviceError(stream);
        if (knownCode === 401) {
          this.unsupportedWebcam = true;
          this.toastService.error(NO_CAMERA_PERMISSIONS_TOAST);
          return undefined;
        } else if (!knownCode) {
          logAction(`[voice] unrecognized error when getting video stream (err0): ${stream?.name ?? stream}`);
        }
      }
    }

    let stream: MediaStream | Error = await this.getVideoStream(devices[0]?.deviceId).catch((err2) => err2);
    if (isStream(stream)) {
      return onStreamClosed(stream, destructor);
    } else {
      this.unsupportedWebcam = true;
      this.settings.videoEnabled = false;
      this.saveSettings();
      const knownCode = isKnownDeviceError(stream);
      if (knownCode === 401) {
        this.toastService.error(NO_CAMERA_PERMISSIONS_TOAST);
        return undefined;
      } else if (knownCode === 404) {
        this.toastService.error(NO_CAMERA_DEVICE_TOAST);
        return undefined;
      } else {
        logAction(`[voice] unrecognized error when getting video stream (err2): ${stream?.name ?? stream}`);
        if (!isError(stream)) stream = new Error(`Error when getting video stream: ${stream}`);
        this.errorReporter.reportError(stream.message, stream);
        this.toastService.error({ message: 'Enabling your camera failed.' });
        return undefined;
      }
    }
  }

  enableVideo(controls: VoiceControls) {
    if (this.selfVideoStream) {
      return Promise.resolve(this.selfVideoStream) as CancellablePromise<VoiceChatStream>;
    } else if (this.selfVideoStreamCreation) {
      return this.selfVideoStreamCreation;
    } else {
      let cancelled = false;
      const streamCreation = this.createVideoStream(() => this.disableVideo(controls))
        .then((stream) => {
          if (!stream || !this.isConnected || !this.controls || cancelled) {
            stream && closeStream(stream);
            return;
          }
          this.unsupportedWebcam = false;
          return this.voiceChat?.changeVideoDevice(stream).then(() => stream);
        })
        .then((stream) => {
          if (!stream || cancelled) {
            stream && closeStream(stream);
            return;
          }
          this.selfVideoStream = stream;
          if (this.selfVideoElement) {
            if (this.selfVideoElement.srcObject) closeStream(this.selfVideoElement.srcObject as VoiceChatStream);
            this.selfVideoElement.srcObject = this.selfVideoStream;
          } else {
            this.selfVideoElement = this.videoElementFromStream(this.selfVideoStream);
            this.selfVideoElement.classList.add('vc-video');
          }
          this.trackCameraState(true);
        })
        .catch((e) => this.errorReporter.reportError(e.message, e))
        .finally(() => {
          this.selfVideoStreamCreation = undefined;
          this.applySettings();
        }) as CancellablePromise<VoiceChatStream | undefined>;
      streamCreation.cancel = () => cancelled = true;
      this.selfVideoStreamCreation = streamCreation;
      return streamCreation;
    }
  }

  disableVideo(controls?: VoiceControls) {
    this.settings.videoEnabled = false;
    this.selfVideoStreamCreation?.cancel();
    const videoStream = this.selfVideoStream;
    if (videoStream) {
      this.selfVideoStream = undefined;
      closeStream(videoStream);
    }
    if (controls?.enableVideo$.value) {
      controls.enableVideo$.next(false);
      this.trackCameraState(false);
    }
    if (this.selfVideoElement) {
      this.selfVideoElement.srcObject = null;
      this.selfVideoElement.remove();
    }
    this.selfVideoElement = undefined;
    this.saveSettings();
  }

  async setVideoDevice(_deviceId: string | undefined) {
    this.changingVideoDevice = true;
    const currentStreamDestructor = this.selfVideoStream?.destructor;
    if (isAndroid) this.selfVideoStream?.getTracks().forEach((t) => t.stop());
    const stream = await this.createVideoStream(currentStreamDestructor ?? (() => this.disableVideo(this.controls)));
    if (stream && this.isConnected && this.controls?.enableVideo$.value) {
      this.ensureCorrectInputStream(_deviceId, stream);
      this.voiceChat?.changeVideoDevice(stream).then(() => {
        if (this.isConnected && this.controls?.enableVideo$.value) {
          if (this.selfVideoStream && this.selfVideoElement) {
            this.selfVideoStream.destructor = undefined;
            closeStream(this.selfVideoStream);
            this.selfVideoStream = stream;
            this.selfVideoElement.srcObject = stream;
          } else {
            this.selfVideoElement = this.videoElementFromStream(stream);
          }
        } else {
          closeStream(stream);
          this.selfVideoElement?.remove();
          this.selfVideoElement = undefined;
        }
      })
        .catch((e) => DEVELOPMENT && console.error(e))
        .finally(() => this.changingVideoDevice = false);
    } else {
      stream && closeStream(stream);
      this.selfVideoElement?.remove();
      this.selfVideoElement = undefined;
      this.changingVideoDevice = false;
    }
  }

  async setInputDevice(deviceId: string | undefined) {
    try {
      const stream = await this.getUserMedia({ audio: { deviceId } });
      if (!stream) return false;
      onStreamClosed(stream, () => this.onAudioStreamFinished());
      this.unsupportedMic = false;
      await this.voiceChat?.changeAudioDevice(stream);
      if (!this.selfAudioStream) throw new Error('No audio stream');
      const oldTracks = this.selfAudioStream.getTracks();
      this.selfAudioStream.addTrack(stream.getAudioTracks()[0]);
      oldTracks.forEach(t => {
        t.stop();
        this.selfAudioStream?.removeTrack(t);
      });
      this.applySettings();
      return true;
    } catch (e) {
      return false;
    }
  }

  applySettings() {
    const settings = this.settings;

    if (this.voiceChat) {
      this.voiceChat.globalOutputGain$.next(settings.outputVolume);
      this.voiceChat.globalMute$.next(settings.deafen);
    }

    if (this.controls) {
      this.controls.inputGain$.next(settings.inputVolume);
      this.controls.inputMute$.next(settings.mute || settings.deafen || !havePermission(this.model!, 'voiceTalk'));
      this.controls.activationType$.next(settings.activation);
      this.controls.voiceLevelActivation$.next(settings.voiceActivationLevel);
    }

    this.saveSettings();
  }
  private saveSettings() {
    const users: [string, number, number][] = [];

    this.usersVolumes.forEach((value, key) => {
      users.push([key, value, this.usersMuted.has(key) ? 1 : 0]);
    });

    this.usersMuted.forEach(key => {
      if (!this.usersVolumes.has(key)) {
        users.push([key, 1, 1]);
      }
    });

    // HACK: clear whole list if we reached limit
    if (users.length < 100) {
      this.settings.users = users;
    }

    this.applyVideoCursors();

    storageSetItem('voice-chat-settings', JSON.stringify(this.settings));
  }
  updateUserName(oldName: string, newName: string) {
    if (this.usersVolumes.has(oldName)) {
      this.usersVolumes.set(newName, this.usersVolumes.get(oldName)!);
      this.usersVolumes.delete(oldName);
    }

    if (this.usersMuted.has(oldName)) {
      this.usersMuted.add(newName);
      this.usersMuted.delete(oldName);
    }
  }

  private updateTalking(participant: Participant, talking: boolean) {
    if (participant.element) toggleClass(participant.element, 'is-talking', talking);

    // emit last talking user
    removeItem(this.talkingStack, participant);
    if (talking) this.talkingStack.push(participant);
    const last = this.talkingStack.length ? this.talkingStack[this.talkingStack.length - 1] : undefined;
    const user = last && this.model?.getUserByUniqId(last.uniqId);
    if (user) {
      user.talking = talking;
    }
    this.onTalking.next(user);
  }

  playSound(soundName: keyof VoiceChatSounds) {
    if (!USE_SOUNDS) return;
    if (this.model?.settings.muteSounds) return;

    try {
      const sound = this.sounds[soundName];

      if (sound && sound.paused) {
        sound.currentTime = 0;
        sound.volume = Math.min(this.settings.outputVolume, 1);
        sound.play().catch(e => DEVELOPMENT && console.warn(e));
      }
    } catch (e) {
      DEVELOPMENT && console.error(e);
    }
  }

  private displayVcFeedbackModal(model: RealModel, manualDc: boolean) {
    const everyFifthCall = !(storageGetNumber('voiceCalls') % 5);
    const notManualDisconnect = !manualDc;
    const popupUnpresent = !document.querySelector('.vc-feedback-popup');
    const notLonelyCall = !model.voiceChat.lonelyCall;
    const notOptedOut = !storageGetBoolean('hideVcFeedback');
    if (everyFifthCall && notManualDisconnect && popupUnpresent && notLonelyCall && notOptedOut) {
      model.modals.openVoiceChatFeedbackModal()
        .then((feedback) => {
          if (feedback && this.model) {
            this.model.trackEvent<VcFeedback>(Analytics.VcFeedbackSubmitted, feedback);
            this.toastService.success({ message: 'Thank you for your feedback!' });
          }
        })
        .catch((e) => {
          this.errorReporter.reportError(e);
        });
    }
  }

  private onAudioStreamFinished() {
    if (this.isConnected) {
      this.setInputDevice(undefined).then((foundNewDevice) => {
        if (!foundNewDevice) {
          this.unsupportedMic = true;
          this.settings.mute = true;
          this.applyMuted();
        }
      }).catch((e) => DEVELOPMENT && console.error(e));
    }
  }

  ensureCorrectInputStream(requestedDeviceId: string | undefined, stream: MediaStream) {
    // this is for firefox and browsers where user is able to assign microphone permission on per-device basis,
    // we need this to avoid situation that user is prompted for device with id "device-A" and during selection
    // he changes allowed microphone to "device-B", code completes successfully (no throw) but we have stream from different
    // device which we want to reflect on the UI to not confuse user
    // (actually he would propably not know that he's using incorrect device)
    const { deviceId: trackDeviceId } = stream.getTracks()[0].getSettings();
    if (!requestedDeviceId) {
      this.settings.inputDeviceId = trackDeviceId;
      return false;
    } else if (trackDeviceId !== requestedDeviceId) {
      this.settings.inputDeviceId = trackDeviceId;
      return false;
    } {
      return true;
    }
  }

  private onFailedStreamAccess(e: Error) {
    const knownCode = isKnownDeviceError(e);
    if (knownCode === 401) {
      this.unsupportedMic = true;
      this.settings.mute = true;
      this.applyMuted();
      this.toastService.error(NO_MIC_PERMISSIONS_TOAST);
      return true;
    } else if (!knownCode) {
      logAction(`[voice] unrecognized error when getting audio stream (err0): ${e?.name ?? e}`);
    }
    return false;
  }
}

function createSound(name: string) {
  try {
    const audio = new Audio(getUrl(`sounds/${name}.mp3`));
    audio.load();
    return audio;
  } catch (e) {
    DEVELOPMENT && console.error(e);
    return undefined;
  }
}

function releaseParticipant(participant: Participant) {
  removeElement(participant.audio);
  if (participant.video) {
    participant.video.dispatchEvent(new Event('ended'));
    removeElement(participant.video);
  }
  if (participant.presentation) {
    participant.presentation.dispatchEvent(new Event('ended'));
    removeElement(participant.presentation);
  }
  setParticipantElement(participant, undefined);
  participant.subscription.unsubscribe();
}

function setParticipantElement(participant: Participant, element: HTMLElement | undefined) {
  participant.element?.classList.remove('is-talking', 'is-connected');
  participant.element = element;
  participant.element?.classList.add('is-connected');
}

async function getVideoDevices() {
  if (typeof navigator.mediaDevices?.enumerateDevices !== 'function') return [];
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter(d => d.kind === 'videoinput');
}

async function getInputDevices() {
  if (typeof navigator.mediaDevices?.enumerateDevices !== 'function') return [];
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.filter(d => d.kind === 'audioinput');
}

export const getBrowserPermissionLog = (name: string) => navigator.permissions
  .query({ name } as any)
  .then((p) => `${name}:${p.state}`)
  .catch(() => undefined);

export function getPermissionLogs() {
  const promises = [
    getBrowserPermissionLog('speaker'),
    getBrowserPermissionLog('speaker-selection'),
    getBrowserPermissionLog('midi'),
    getBrowserPermissionLog('camera'),
    getBrowserPermissionLog('microphone'),
    getBrowserPermissionLog('display-capture'),
  ];

  return Promise.all(promises).then((perms) => {
    return '[' + perms.filter(p => p !== undefined).join(',') + ']';
  });
}
