import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TeamService } from 'services/team.service';
import { ContainerScrollEventService } from 'services/container-scroll-event.service';
import { ModalService } from 'services/modal.service';
import { PresenceStateService } from 'services/presence-state.service';
import { RecentParticipationService } from 'services/recent-participation.service';
import { redirectAfterCheckout } from 'services/payment.service';
import { RouterService } from 'services/router.service';
import { deleteCookie, getCookie } from 'magma/common/cookies';
import { UserService } from 'services/user.service';
import { filter, take } from 'rxjs/operators';
import { ToastService } from 'magma/services/toast.service';
import { TrackService } from 'services/track.service';
import { Analytics, SurveyId } from 'magma/common/interfaces';
import { UserStore } from 'services/user.store';
import { RESERVED_TAGS } from 'magma/common/constants';
import { Model } from 'magma/services/model';
import { belowBreakpointMD, isMobile, userHasAds } from 'magma/common/utils';

@UntilDestroy()
@Component({
  selector: 'manage-page',
  templateUrl: './manage-page.component.pug',
  styleUrls: ['./manage-page.component.scss'],
  host: {
    '[class.no-team-selector]': 'noTeamSelector',
  },
  providers: [
    PresenceStateService,
    RecentParticipationService,
  ],
})
export class ManagePageComponent implements AfterViewInit, OnDestroy {
  noTeamSelector = false;
  _isDarkMode = false;
  pageTitle = '';
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private containerScrollEventService: ContainerScrollEventService,
    private modals: ModalService,
    private routerService: RouterService,
    private userService: UserService,
    private toastService: ToastService,
    private trackService: TrackService,
    private userStore: UserStore,
    _teamService: TeamService, // here to ensure teams
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.paymentUpdated) {
        this.toastService.success({ message: 'Payment method has been updated' });
        void this.router.navigate([], { queryParams: { paymentUpdated: null }, queryParamsHandling: 'merge' });
      }
      if (params.upgraded) {
        this.toastService.success({ message: 'Your artspace was upgraded to Blaze Plan' });
        void this.router.navigate([], { queryParams: { upgraded: null }, queryParamsHandling: 'merge' });
      }
    });

    const invite = this.router.getCurrentNavigation()?.extras.state?.invite;
    if (invite) {
      void this.modals.joinTeam({ invite });
    }

    this.routerService.data$.pipe(untilDestroyed(this)).subscribe(data => {
      this.noTeamSelector = data.teamSelector === 'toggle';
      this.pageTitle = data.pageTitle ?? '';
    });

    if (getCookie('open-create-team')) {
      this.userService.user$.pipe(filter(x => !!x), take(1), untilDestroyed(this)).subscribe(() => {
        deleteCookie('open-create-team', '/');
        void this.modals.createTeam();
      });
    }

    window.Intercom?.('update', { hide_default_launcher: false });

    this.router.events.pipe()
    .subscribe(() => {
      // Light mode pages
      this._isDarkMode = !document.querySelectorAll('team-settings, account-settings, account-feedback').length;
    });
  }

  async ngAfterViewInit() {
    const redirect = redirectAfterCheckout();
    if (redirect) return this.router.navigateByUrl(redirect);
  }

  onScrollToEnd() {
    this.containerScrollEventService.approachingBottom();
  }

  // NPS survey

  ngOnDestroy() {
    this.userService.updateUser({ npsSurvey: undefined });
  }

  private sentNoNPS = false;

  get surveyId() {
    const surveyId = this.userService.user?.npsSurvey;

    if (!!surveyId && !this.sentNoNPS && !this.isNPSSurvey) {
      this.sentNoNPS = true;
      this.userService.tag(RESERVED_TAGS.noNps).catch(e => DEVELOPMENT && console.error(e));
    }

    return surveyId;
  }

  get surveyQuestion() {
    const surveyId = this.userService.user?.npsSurvey;
    switch (surveyId) {
      case SurveyId.Nps: return 'Do you like Magma?';
      case SurveyId.Ai: return 'Do you like Magma AI Assistant?';
      default: return '';
    }
  }

  get surveyCTA() {
    const surveyId = this.userService.user?.npsSurvey;
    switch (surveyId) {
      case SurveyId.Nps: return 'Give 10 second feedback!';
      case SurveyId.Ai: return 'Share your thoughts!';
      default: return '';
    }
  }

  get isNPSSurvey() {
    return this.userService.user?.npsSurvey === SurveyId.Nps;
  }

  async openSurvey() {
    if (!this.userService.user?.npsSurvey) throw new Error('Invalid state for NPS');
    this.modals.survey(this.userService.user.npsSurvey);
    this.userService.updateUser({ npsSurvey: undefined });
    if (!this.isNPSSurvey) {
      await this.userService.tag(RESERVED_TAGS.noNps);
    }
  }

  async closeSurveyPopup() {
    this.trackService.event(Analytics.SurveyDismiss);
    this.userService.updateUser({ npsSurvey: undefined });
    if (!this.isNPSSurvey) {
      await this.userService.tag(RESERVED_TAGS.noNps);
    }
  }

  async dontShowSurveyAgain() {
    this.trackService.event(Analytics.SurveyDontShowAgain);
    this.userService.updateUser({ npsSurvey: undefined });
    await this.userService.tag(RESERVED_TAGS.noNps);
  }

  get isDarkMode() {
    return this._isDarkMode;
  }

  get hasAds() {
    return userHasAds(this.userService.user) && !belowBreakpointMD() && !isMobile;
  }
}
