import { Injectable, NgZone } from '@angular/core';
import { ITrackService } from './track.service.interface';
import { filter, fromEvent, interval, merge, throttle, timestamp } from 'rxjs';
import { Analytics, DrawingAction } from '../common/interfaces';
import { Model } from './model';
import { isValidDrawingId } from '../common/drawingUtils';
import { MINUTE } from '../common/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// @ignore-translate
const ACTIVITY_EVENTS_TYPES = [
  'click',
  'wheel',
  'mousedown',
  'pointerdown',
  'touchstart',
  'keydown'
];
const EVENT_COLLECTION_INTERVAL = MINUTE;
const INTERVAL_MARGIN = 500;

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AnalyticsSessionCheckService {
  private timestamp = 0;
  private drawingId = '';

  model?: Model = undefined;

  constructor(
    private ngZone: NgZone,
    private trackService: ITrackService,
  ) {
    this.ngZone.runOutsideAngular(() => {
      const eventStreams$ = ACTIVITY_EVENTS_TYPES.map(
        eventType => fromEvent(document, eventType));

      merge(...eventStreams$)
        .pipe(
          filter(() => document.hasFocus()),
          throttle(() => interval(EVENT_COLLECTION_INTERVAL), { leading: true, trailing: true }),
          timestamp(),
          untilDestroyed(this))
        .subscribe((ev) => {
          this.trackService.event(Analytics.AnalyticsSessionCheck);
          if (this.model !== undefined && this.model.drawing.socket !== undefined && isValidDrawingId(this.model.drawing.id)) {
            if (this.drawingId === this.model.drawing.id && ev.timestamp - this.timestamp < EVENT_COLLECTION_INTERVAL + INTERVAL_MARGIN) {
              this.model.drawingAction(DrawingAction.AnalyticsSessionCheck)
                .catch(e => DEVELOPMENT && console.error(e));
            }
            this.drawingId = this.model.drawing.id;
            this.timestamp = ev.timestamp;
          } else {
            this.drawingId = '';
            this.timestamp = 0;
          }
        });
    });
  }
}
