import { Component, Input } from '@angular/core';
import { getAvatarPath } from 'magma/common/utils';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { TeamsQuery } from 'services/team.query';
import { UserService } from 'services/user.service';
import { hasInProSources } from 'shared/utils';

@Component({
  selector: 'pro-sources',
  templateUrl: './pro-sources.component.pug',
  styleUrls: ['./pro-sources.component.scss'],
})
export class ProSourcesComponent {
  @Input() count = 3;
  @Input() showAll = false;
  @Input() showHeader = false;

  pays$ = combineLatest([this.userService.user$, this.teamsQuery.selectAll()]).pipe(map(([user, teams]) => {
    const result = [];
    if (user?.proSources) {
      if (user.proSources.includes('magma') || user.proSources.includes('ads')) {
        result.push({ name: 'magma', avatar: '' });
      }
      if (user.proSources.includes('paid')) {
        result.push({ name: user.name, avatar: getAvatarPath(user.avatar, 24) });
      }
      for (const team of teams.filter(t => hasInProSources(user, t._id))) {
        result.push({ name: team.name, avatar: getAvatarPath(team.avatar, 24) });
      }
    }
    if (this.showAll) this.count = result.length;
    return result;
  }));

  constructor(private userService: UserService, private teamsQuery: TeamsQuery) {
  }
}
