import { Component } from '@angular/core';
import { options } from 'magma/common/data';
import { faEnvelope, faInfoCircle, farExclamationCircle, socialIcons } from 'magma/common/icons';
import { ErrorReporter } from 'magma/services/errorReporter';
import { ToastService } from 'magma/services/toast.service';
import { UserService } from 'services/user.service';
import { FeatureFlagService } from 'magma/services/feature-flag.service.interface';
import { Feature } from 'magma/common/interfaces';
import { canChangeUsername } from 'shared/utils';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators, AbstractControl, type AsyncValidatorFn, type ValidationErrors } from '@angular/forms';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { USER_NAME_LENGTH_LIMIT, RESERVED_TAGS, LANGUAGES, SupportedLocales } from 'magma-editor/src/ts/common/constants';
import { noOnlyNumbers, noSpecialChars, matchingControls, isLowercaseOnly, listFormErrors, notEqualTo } from 'magma/services/form-utils';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LocaleService } from 'magma/services/locale.service';


function usernameAvailabilityValidator(http: HttpClient): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    if (!control.value) return of(null);
    return http.get<boolean>(`/api/profile/check-username?username=${control.value || ''}`).pipe(
      map(response => {
        if (response) return null;
        throw response;
      }),
      catchError((e) => of({ usernameTaken: 'This username is already taken by some user' })),
    );
  };
}

@UntilDestroy()
@Component({
  selector: 'account-password',
  templateUrl: './account-password.component.pug',
  styleUrls: [
    '../account-common.component.scss',
    './account-password.component.scss',
  ],
})
export class AccountPasswordComponent {
  readonly warningIcon = faInfoCircle;
  readonly errorIcon = farExclamationCircle;
  isSubmitting = false;
  newEmail = '';
  confirmPassword = '';
  password = '';
  newPassword = '';
  repeatNewPassword = '';
  changingEmail = false;
  changingPassword = false;
  changingUsername = false;
  settingEmail = false;
  error: string | undefined = undefined;
  readonly languages = LANGUAGES;

  constructor(
    private userService: UserService,
    private toastService: ToastService,
    private errorReporter: ErrorReporter,
    private featuresService: FeatureFlagService,
    private httpClient: HttpClient,
    private localeService: LocaleService
  ) {
    const username1 = new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(USER_NAME_LENGTH_LIMIT),
      isLowercaseOnly,
      noOnlyNumbers,
      noSpecialChars,
      notEqualTo(() => this.user?.username ?? '', `You can't use previous username`),
    ], usernameAvailabilityValidator(this.httpClient));
    const username2 = new FormControl<string>('');
    this.usernameForm = new FormGroup<{ username1: FormControl<string | null>, username2: FormControl<string | null> }>({ username1, username2 }, { validators: [ matchingControls(username1, username2) ] });
  }
  get disabled() {
    return !!options.disableChangingProfile && !this.user?.isSuperAdmin;
  }
  get user() {
    return this.userService.user;
  }
  get oauthIcon() {
    return socialIcons[this.user?.oauth as any];
  }
  get IsSaml() {
    return this.user?.oauth === 'okta';
  }

  // email
  changeEmail() {
    this.hideForms();
    this.changingEmail = true;
    this.newEmail = this.user?.email ?? '';
    this.confirmPassword = '';
  }
  cancelChangingEmail() {
    this.changingEmail = false;
  }

  isEmailChanged() {
    return this.user!.email === this.newEmail;
  }

  async submitEmail() {
    if (this.isEmailChanged()) return;

    await this.submitForm(async () => {
      await this.userService.changeEmail({ email: this.newEmail, password: this.confirmPassword });
      this.changingEmail = false;
    }, 'Email successfully updated');
  }

  // password
  changePassword() {
    this.hideForms();
    this.changingPassword = true;
    this.password = '';
    this.newPassword = '';
    this.repeatNewPassword = '';
  }
  cancelChangingPassword() {
    this.changingPassword = false;
  }
  async submitPassword() {
    await this.submitForm(async () => {
      await this.userService.changePassword({
        password: this.password,
        newPassword: this.newPassword,
        repeatNewPassword: this.repeatNewPassword,
      });
      this.changingPassword = false;
    }, 'Password successfully updated');
  }

  // email & password

  async resendEmail() {
    if (!this.user?.unverifiedEmail) return;

    await this.submitForm(
      () => this.userService.resendEmailVerification(),
      `We've send a link to verify your email to ${this.user.unverifiedEmail}, please check your inbox`,
      'Failed to resend email', faEnvelope);
  }
  async resetPassword() {
    const email = this.user?.email ?? this.user?.unverifiedEmail;
    if (!email) return;

    await this.submitForm(
      () => this.userService.resetPassword(email),
      `We've send a link to reset your password to ${email}, please check your inbox`,
      'Failed to reset password', faEnvelope);
  }
  setupEmail() {
    this.newEmail = '';
    this.settingEmail = true;
  }
  cancelSettingEmail() {
    this.settingEmail = false;
  }
  async submitSetupEmail() {
    await this.submitForm(
      async () => {
        await this.userService.initEmail({ email: this.newEmail });
        this.settingEmail = false;
      },
      `We've send a link to reset your password to ${this.newEmail}, please check your inbox`,
      'Failed to set an email address', faEnvelope);
  }

  // username
  usernameForm: FormGroup<{ username1: FormControl<string | null>, username2: FormControl<string | null> }>;
  get username1() {
    return this.usernameForm.controls.username1;
  }
  get username2() {
    return this.usernameForm.controls.username2;
  }
  get usernameSectionVisible() {
    return this.featuresService.isFeatureSupported(Feature.UniqueUsernames);
  }
  get canChangeUsername() {
    return this.featuresService.isFeatureSupported(Feature.AdjustUsername) && canChangeUsername(this.user);
  }
  startChangingUsername() {
    if (!canChangeUsername(this.user)) {
      // TODO: trigger upsell flow?
    } else {
      this.changingUsername = true;
    }
  }
  cancelChangingUsername() {
    this.changingUsername = false;
    this.usernameForm.reset();
  }
  async submitUsername() {
    const value = this.usernameForm.controls.username1.value;
    if (value) {
      await this.submitForm(async () => {
          await this.userService.changeUsername(value);
          this.changingUsername = false;
          const tags = (this.user?.tags ?? []).slice();
          tags.push(RESERVED_TAGS.updatedUsername);
          this.userService.updateUser({ tags });
        },
        `We've changed your username to "${value}"`,
        'Failed to change username');
    }
  }

  get alreadyChangedUsername() {
    return !!this.user?.tags?.includes(RESERVED_TAGS.updatedUsername);
  }

  get usernameFormErrors() {
    return listFormErrors(this.usernameForm);
  }

  // utils

  private hideForms() {
    this.error = undefined;
    this.cancelChangingEmail();
    this.cancelChangingPassword();
    this.cancelSettingEmail();
  }
  private async submitForm(action: () => Promise<void>, successMessage: string, errorMessage?: string, successIcon?: any) {
    try {
      this.error = undefined;
      this.isSubmitting = true;
      await action();
      this.toastService.success({ message: successMessage, icon: successIcon });
    } catch (e) {
      if (!e.userError) {
        this.errorReporter.reportError(e.message, e);
      }

      if (errorMessage) {
        this.toastService.error({ message: errorMessage, subtitle: e.message });
      } else {
        this.error = e.message;
      }
    } finally {
      this.isSubmitting = false;
    }
  }

  setSelectedLocale(locale: SupportedLocales) {
    this.localeService.setLocale(locale);
  }

  get selectedLocaleName() {
    return this.localeService.getLocaleName();
  }
}
