import { Injectable } from '@angular/core';
import { DEFAULT_LOCALE, LANGUAGES, LOCALE_KEY, SupportedLocales } from 'magma/common/constants';
import { getDefaultLocaleCode } from 'magma/common/i18n';
import { Analytics } from 'magma/common/interfaces';
import { LocaleService } from 'magma/services/locale.service';
import { storageGetItem } from 'magma/services/storage';
import { ITrackService } from 'magma/services/track.service.interface';

@Injectable({ providedIn: 'root' })
export class PortalLocaleService extends LocaleService {

  constructor(private trackService: ITrackService) {
    super();
  }

  getLocale(): SupportedLocales | null {
    const defaultLocaleCode = getDefaultLocaleCode(Object.keys(LANGUAGES)) ?? null;
    return (storageGetItem(LOCALE_KEY) || defaultLocaleCode) as SupportedLocales | null;
  }

  setLocale(locale: SupportedLocales): void {
    if (locale !== this.getLocale()) {
      localStorage.setItem(LOCALE_KEY, locale);
      this.trackService.event(Analytics.SwitchLanguage, {
        language: locale
      });
      setTimeout(() => location.reload());
    }
  }

  getLocaleName(locale?: SupportedLocales): string {
    if (locale === undefined) {
      locale = this.getLocale() ?? DEFAULT_LOCALE;
    }
    return LANGUAGES[locale] ?? LANGUAGES[DEFAULT_LOCALE];
  }
}
