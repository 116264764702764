import { Component, EventEmitter, Output } from '@angular/core';
import { faTimes } from 'magma/common/icons';
import { LANGUAGES, SupportedLocales } from 'magma/common/constants';
import { LocaleService } from 'magma/services/locale.service';

@Component({
  selector: 'conference-start-modal',
  templateUrl: './conference-start-modal.component.pug',
  styleUrls: ['./conference-start-modal.component.scss'],
})
export class ConferenceStartModalComponent {
  readonly crossIcon = faTimes;

  readonly languages = LANGUAGES;

  @Output() close = new EventEmitter<boolean>();

  constructor(private localeService: LocaleService) { }

  setSelectedLocale(locale: SupportedLocales) {
    this.localeService.setLocale(locale);
  }

  get selectedLocaleName() {
    return this.localeService.getLocaleName();
  }
}
