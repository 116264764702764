import { BillingData, BillingInterval, ProductPrice, TeamData } from '../../../../../shared/interfaces';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BillingService } from 'services/billing.service';
import { SubscriptionPlanType, TEAM_PRODUCTS, getSubscriptionPlanByEnum } from 'shared/billing';
import { ToastService } from 'magma/services/toast.service';
import { getBillingItems } from 'shared/utils';
import Stripe from 'stripe';
import { faInfoCircle } from 'magma/common/icons';
import { Feature } from 'magma/common/interfaces';
import { FeatureFlagService } from 'magma/services/feature-flag.service.interface';
import { TeamService } from 'services/team.service';
import { BehaviorSubject } from 'rxjs';
import { createTranslate } from 'magma/common/i18n';

const tr = createTranslate();

export interface CreateTeamSubscriptionModalInput {
  team: TeamData,
  items: { [key: string]: number }; // stripeProductId: quantity
  afterItems: { [key: string]: number }; // stripeProductId: quantity
  billingData: BillingData;
  userIdToAddPro?: string,
  autoUpdateBilling: boolean;
  membersCount: number;
  plan: SubscriptionPlanType;
}

interface Item {
  product: string; // stripe productId
  name: string;
  quantity: number;
  price: ProductPrice
}

@Component({
  selector: 'create-team-subscription-modal',
  templateUrl: 'create-team-subscription-modal.component.pug',
  styleUrls: ['create-team-subscription-modal.component.scss'],
})
export class CreateTeamSubscriptionModalComponent implements OnInit, OnDestroy {
  TEAM_PRODUCTS = TEAM_PRODUCTS;
  items: Item[] = [];
  afterItems: Item[] = [];
  prices: Map<string, ProductPrice> = new Map();
  upcomingInvoice: Stripe.Invoice | undefined;
  faInfoCircle = faInfoCircle;
  invoiceLoading = false;
  autoUpdateBilling = false;

  isAnnual = false;

  chargeNow$: BehaviorSubject<number> = new BehaviorSubject(0);

  get billingInterval(): BillingInterval {
    return this.isAnnual ? 'year' : 'month';
  }

  get intervalShort() {
    return this.isAnnual ? 'yr' : 'mo';
  }

  get intervalLong() {
    return this.isAnnual ? 'year' : 'month';
  }

  get licenseLimit() {
    return this.data.plan.planLimit;
  }

  sumBefore = 0;
  sumAfter = 0;
  planQuantity = 0;

  @Output() close = new EventEmitter<BillingInterval>();

  private _data!: CreateTeamSubscriptionModalInput;
  @Input() set data(_params: CreateTeamSubscriptionModalInput) {
    this._data = _params;
    this.resetPlanQuantity();
  }

  get data(): CreateTeamSubscriptionModalInput {
    return this._data;
  }

  constructor(
    private featureFlagService: FeatureFlagService,
    protected billingService: BillingService,
    private teamService: TeamService,
    private toastService: ToastService
  ) { }

  async ngOnInit() {
    this.isAnnual = this.data.billingData.interval === 'year';
    const allPrices = await this.billingService.getAllProductsPrices();
    this.prices = new Map(allPrices.map(p => [p.id, p]));

    this.sumBefore = this.mapItems(this.data.items, this.items);
    this.sumAfter = this.mapItems(this.data.afterItems, this.afterItems);
    void this.refreshSummary();

    this.autoUpdateBilling = this.data.autoUpdateBilling;
    this.resetPlanQuantity();
  }

  ngOnDestroy() {
    this.chargeNow$.complete();
  }

  private resetPlanQuantity() {
    this.planQuantity = this.data.items[this.data.plan.code];
  }

  mapItems(items: { [key: string]: number }, output: Item[]) {
    output.length = 0;
    let sum = 0;
    for (const [productId, quantity] of Object.entries(items)) {
      const product = TEAM_PRODUCTS.get(productId);
      if (!product) {
        DEVELOPMENT && console.error('Invalid product id');
        continue;
      }
      const price = this.prices.get(this.data.billingData.interval === 'month' ? product.stripePriceId['month'] : product.stripePriceId['year']);
      if (!price) {
        DEVELOPMENT && console.error('Invalid price id');
        continue;
      }
      output.push({ product: product.productId, name: product?.name, quantity: quantity, price });
      sum += price.amount * quantity;
    }
    return sum;
  }

  cancel() {
    this.close.emit();
  }

  increaseLicenseNumber() {
    this.planQuantity++;
    this.updatePlanQuantity();
  }

  decreaseLicenseNumber() {
    this.planQuantity--;
    this.updatePlanQuantity();
  }

  updatePlanQuantity() {
    this.planQuantity = this.planQuantity | 0;
    if (this.planQuantity < 1) this.planQuantity = 1;
    void this.refreshSummary();
  }

  get canUpgrade() {
    const { planLimit } = getSubscriptionPlanByEnum(this.data.team.pro);
    return this.planQuantity > 0 && this.planQuantity <= planLimit || this.featureFlagService.isFeatureSupported(Feature.BillingSkipBlazeLimit);
  }

  get blazeLimitationReachedLink() {
    return `https://magm.ai/blaze-limitation-reached?name=${this.data.team.slug ?? 'new'}&quantity=${this.planQuantity}`;
  }

  get isIntercomAvailable() {
    return !!window.Intercom;
  }

  async refreshSummary() {
    this.data.afterItems[this.data.plan.code] = this.autoUpdateBilling ? this.data.membersCount : this.planQuantity;
    this.sumAfter = this.mapItems(this.data.afterItems, this.afterItems);
    const products = new Map(this.afterItems.map(i => [i.product, i.quantity]));
    const items = getBillingItems(this.data.billingData, products, this.billingInterval);

    this.invoiceLoading = true;

    if (this.canUpgrade) {
      const isExpiredOrCancelled = this.data.billingData.expiry?.isExpired || this.data.billingData.expiry?.cancelAtPeriodEnd;
      if (isExpiredOrCancelled && this.data.billingData.nextCharge) {
        const nextPrice = this.data.billingData.nextCharge * this.planQuantity;
        this.chargeNow$.next(nextPrice / 100);
      }
      else {
        this.upcomingInvoice = await this.billingService.getTeamUpcomingInvoice({
          customerId: this.data.billingData.stripe?.customerId,
          subscriptionId: this.data.billingData.stripe?.subscriptionId,
          items,
        });
        this.updateChargeNow();
      }
    }

    this.invoiceLoading = false;
  }

  private updateChargeNow() {
    if (!this.areBillingItemsChanged) {
      this.chargeNow$.next(0);
      return;
    }

    const charge = this.upcomingInvoice?.amount_due ?? 0;

    this.chargeNow$.next(charge > 0 ? charge / 100 : 0);
  }

  get nextCharge() {
    const amount = this.sumAfter - this.balanceAfterChange;
    return amount > 0 ? amount : 0;
  }

  get balance() {
    return -(this.upcomingInvoice?.starting_balance ?? 0);
  }

  get balanceAfterChange() {
    return -(this.upcomingInvoice?.ending_balance ?? 0);
  }

  get balanceTooltip() {
    return tr`Your balance will be used to reduce upcoming invoices. Your current balance is $${this.balance / 100}, balance after change $${this.balanceAfterChange / 100}`;
  }

  get nextChargeDate() {
    // it will set to now if billing interval is changed
    const nextBilling = new Date();
    if (this.data.billingData.interval === 'month') {
      nextBilling.setMonth(nextBilling.getMonth() + 1);
    } else {
      nextBilling.setFullYear(nextBilling.getFullYear() + 1);
    }
    return nextBilling;
  }

  // so far simple check because we have only blaze
  get areBillingItemsChanged() {
    return this.data.items[this.data.plan.code] !== this.planQuantity;
  }

  async accept() {
    try {
      const products = new Map(this.afterItems.map(i => [i.product, i.quantity]));
      const items = getBillingItems(this.data.billingData, products, this.billingInterval);

      if (this.autoUpdateBilling !== this.data.autoUpdateBilling) {
        await this.teamService.updateTeam(this.data.team, { autoUpdateBilling: this.autoUpdateBilling });
      }

      if (!this.autoUpdateBilling) {
        await this.billingService.updateTeamSubscription(this.data.team._id, items);
        if (this.data.userIdToAddPro) await this.billingService.updateTeamMemberPro(this.data.team._id, this.data.userIdToAddPro, true);
      }

      this.teamService.reloadTeams();
      this.close.emit();
    } catch (e) {
      this.toastService.error({ message: 'Failed to open stripe', subtitle: e.message });
    }
  }

  get confirmButtonText() {
    return this.chargeNow$.value <= 0 ? 'Confirm' : 'Confirm & Pay';
  }

  async onAutoBillingChanged() {
    if (!this.autoUpdateBilling) {
      this.resetPlanQuantity();
    }
    void this.refreshSummary();
  }
}
