import { Component, Injector, Input, NgZone } from '@angular/core';
import { aiTool, faChevronDown, faChevronUp, faExchangeAlt, faFileCertificate, faLightbulbDollar, faShieldCheck, magmaIcon, faInfoCircle } from 'magma/common/icons';
import { type ParticularSplashScreen, SplashScreenService } from 'magma/services/splashScreenService';
import { ModalService } from '../../../services/modal.service';
import { UserService } from '../../../services/user.service';
import { EntitiesService } from '../../../services/entities.service';
import { PublishersService } from 'magma/services/publishersService';
import { PublisherId } from 'magma/common/interfaces';
import { RouterService } from '../../../services/router.service';
import { RegisterArtworkModalOutputData } from '../../../../../shared/interfaces';
import { Editor } from 'magma/services/editor';

@Component({
  selector: 'connect-to-story',
  styleUrls: ['./connect-to-story.component.scss'],
  templateUrl: 'connect-to-story.component.pug',
})
export class ConnectToStoryComponent implements ParticularSplashScreen {
  readonly icons = {
    arrows: faExchangeAlt,
    shield: faShieldCheck,
    certificate: faFileCertificate,
    lightbulbDollar: faLightbulbDollar,
    magma: magmaIcon,
    ai: aiTool,
    infoCircle: faInfoCircle,
    chevron: {
      down: faChevronDown,
      up: faChevronUp,
    },
  };

  @Input() editor: Editor | undefined = undefined;

  permissionsVisible = false;

  constructor(
    private splashScreens: SplashScreenService,
    private modals: ModalService,
    private users: UserService,
    private entities: EntitiesService,
    private publishers: PublishersService,
    private routerService: RouterService,
    private zone: NgZone,
  ) { }

  canOpen(injector: Injector) {
    return !!injector.get(UserService)?.user;
  }

  pendingOperations = false;
  complete(confirmed: boolean) {
    this.pendingOperations = true;
    if (confirmed && this.editor) {
      const editor = this.editor;
      this.zone.runOutsideAngular(() => {
        this.publishers.linkAccount(PublisherId.StoryProtocol)
          .catch((e) => DEVELOPMENT && console.error(e))
          .finally(() => {
            this.zone.run(() => {
              this.splashScreens.closeSplashScreen(true);
            });
          });
      });
    } else {
      this.splashScreens.closeSplashScreen(false);
    }
  }

  get avatar() {
    return this.users.user!.avatar;
  }
}
