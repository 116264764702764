import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PublishersService } from 'magma/services/publishersService';
import { UserService } from './user.service';
import { PublicationStatus, PublisherId, SortOrders, UserPublishersSettings, ArtworkLicensingData, ArtworkPublishingResult, StoryProtocolRegisterRevisionData, LicenseRights, PublisherConstantId, DrawingRevision } from 'magma/common/interfaces';
import { ToastService } from 'magma/services/toast.service';
import { navigateToDrawing } from 'magma/common/clientUtils';
import { Router } from '@angular/router';
import { Editor } from 'magma/services/editor';
import { EntityData } from 'shared/interfaces';
import { toPromise } from 'shared/utils';
import { getRevisionLabel, getRegisteringTaskName } from 'magma/common/publishing';

@Injectable()
export class PortalPublishersService extends PublishersService {
  constructor(
    private http: HttpClient,
    private usersService: UserService,
    private toastsService: ToastService,
    private router: Router
  ) {
    super();
  }
  async linkAccount(id: PublisherId) {
    const response = await toPromise(this.http.post<{ data: UserPublishersSettings }>('api/publishers/link/account', { providerId: id }));
    this.usersService.updateUser({ publishers: response.data });
  }

  async publishRevision(editor: Editor, providerId: PublisherId, opts: StoryProtocolRegisterRevisionData) {
    const registered = editor.model.startTask(getRegisteringTaskName(providerId));
    editor.apply(() => { });
    const showErrorToast = (message: string) => {
      this.toastsService.error({ message });
      return;
    };
    try {
      const connId = editor.model.connId;
      const isParticipant = editor.drawing.user.role === 'owner' || !!editor.drawing.participants?.find(p => p.id === this.usersService.user?._id);
      if (!isParticipant) return showErrorToast('You have to be a drawing participant to register artwork');

      if (providerId === PublisherId.StoryProtocol) {
        const currentPublicationPending = editor.drawing.licensing?.revision?.publishedIn?.StoryProtocol?.status === PublicationStatus.Pending;
        if (currentPublicationPending) return showErrorToast('There\'s a pending registration, please resolve before registering a new one');
      } else if (providerId === PublisherId.Template) {
        const publishedAlready = editor.drawing.publishedIn?.template;
        if (publishedAlready) return showErrorToast('Artwork has already been published as template');
      }

      const revision = await editor.model.server.createDrawingRevision(connId, getRevisionLabel(providerId), false, true);
      const body = { providerId, drawingId: editor.drawing.id, revisionId: revision.shortId, publishingOpts: opts };
      const response = await toPromise(this.http.post<{ data: ({ result: ArtworkPublishingResult } & { licensing: ArtworkLicensingData }) }>('api/publishers/publish/revision', body));
      await editor.model.server.licenseArtwork(connId, {
        providerId: providerId,
        entityId: editor.drawing._id,
        revisionId: revision.shortId,
      });
      if (providerId === PublisherId.StoryProtocol) {
        const participantsLength = response.data.licensing.revision?.publishedIn?.StoryProtocol?.participants?.length || 0;
        this.toastsService.notification({
          title: participantsLength > 1 ? 'We’ve asked our users to approve artwork registration, you can track the status here.' : 'Artwork has been successfully registered',
          actionText: participantsLength > 1 ? undefined : 'View on Story',
          action: participantsLength > 1 ? undefined : () => window.open(response.data.result.url, '_blank'),
        });
      } else if (providerId === PublisherId.Template) {
        this.toastsService.notification({
          title: 'Artwork has been successfully registered as template',
          actionText: 'View revision',
          action: () => {
            const url = new URL(location.href);
            url.pathname = response.data.result.url;
            window.open(url, '_blank');
          }
        });
      }
      await editor.model.getDrawingRevisions();
    } catch (e) {
      DEVELOPMENT && console.log(e);
      this.toastsService.error({ message: 'There was an error registering your artwork' });
    } finally {
      registered();
      editor.apply(() => { });
    }
  }

  async remixRevision(shortId: string, artworkName: string | undefined) {
    const response = await toPromise(this.http.post<{ data?: string }>('api/publishers/remix/revision', { revisionId: shortId, artworkName }));
    if (!response || !response.data) throw new Error('Invalid response');
    navigateToDrawing(this.router, response.data, 'remixing revision');
  }

  async getMyRegisteredIps(limit: number, offset: number, sortBy: 'date' | 'name', sortOrder: SortOrders): Promise<{ revisions: any[], count: number }> {
    const url = `api/publishers/my-published?limit=${limit}&offset=${offset}&sortBy=${sortBy}&sortOrder=${sortOrder}`;
    const response = await toPromise(this.http.get<{ data?: { revisions: DrawingRevision[], count: number } }>(url));
    return {
      revisions: response?.data?.revisions ?? [],
      count: response?.data?.count ?? 0,
    };
  }

  async getEntity(id: string) {
    return (await toPromise(this.http.get<{ data?: EntityData }>(`api/entities/publication/${id}`))).data;
  }

  async updateUserPublicationStatus(providerId: PublisherId, revisionId: string, status: PublicationStatus) {
    return await toPromise(this.http.post<boolean>(`/api/publishers/revision/status`, { revisionId, status, providerId }));
  }

  async cancelPublication(providerId: PublisherId, revisionId: string) {
    return await toPromise(this.http.post<boolean>(`/api/publishers/cancel/revision`, { revisionId, providerId }));
  }

  async getConstant(providerId: PublisherId, constantId: PublisherConstantId) {
    return await toPromise(this.http.get(`/api/publishers/publisher/${providerId}/const/${constantId}`));
  }
}
