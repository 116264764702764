import { BillingData, RoleType, ScheduledTeamBillingUpdate } from 'shared/interfaces';
import { BehaviorSubject } from 'rxjs';
import { ROLE_TYPES_READ_ONLY, TeamData, TeamMember, TeamRole } from 'shared/interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BillingService } from 'services/billing.service';
import { Component } from '@angular/core';
import { ModalService } from 'services/modal.service';
import { DEFAULT_SIMPLE_TABLE_PARAMS, Permission, SimpleTableColumn, SimpleTableParams, SortOrders, SubscriptionPlan } from 'magma/common/interfaces';
import { TEAM_PRODUCTS, getSubscriptionPlanByEnum } from 'shared/billing';
import { TeamMembersQuery } from 'services/team-members.query';
import { TeamMembersService } from 'services/team-members.service';
import { TeamService } from 'services/team.service';
import { TeamsQuery } from 'services/team.query';
import { ToastService } from 'magma/services/toast.service';
import { faBan, faInfoCircle, faPen, faPlus, farEllipsisH, farEllipsisV, faStar, faTimes, faTrash, faUserPlus, farExclamationTriangle, blazeIcon, faSearch, filteringIcon, faCheck, fusionIcon } from 'magma/common/icons';
import { UserService } from 'services/user.service';
import { getNumberOfPurchasedSub, getPlanForMember, hasInProSources, toPromise } from 'shared/utils';
import { map, tap } from 'rxjs/operators';
import { SimpleTableService } from 'services/simple-table.service';
import { ErrorReporter } from 'magma/services/errorReporter';
import { belowBreakpointMD } from 'magma/common/utils';
import { createTranslate, nonTranslatable } from 'magma/common/i18n';

const tr = createTranslate();

@UntilDestroy()
@Component({
  selector: 'team-settings-members',
  templateUrl: './team-settings-members.component.pug',
  styleUrls: [
    '../../account-common.component.scss',
    './team-settings-members.component.scss',
  ],
})
export class TeamSettingsMembersComponent {
  readonly showSearchRolesThreadshold = 10;
  ROLE_TYPES_READ_ONLY = ROLE_TYPES_READ_ONLY;
  RoleType = RoleType;
  farEllipsisH = farEllipsisH;
  farEllipsisV = farEllipsisV;
  faTrash = faTrash;
  faUserPlus = faUserPlus;
  faStar = faStar;
  faPen = faPen;
  faPlus = faPlus;
  faTimes = faTimes;
  faBan = faBan;
  faInfoCircle = faInfoCircle;
  farExclamationTriangle = farExclamationTriangle;
  searchIcon = faSearch;
  filterIcon = filteringIcon;
  checkIcon = faCheck;

  filter = '';
  teamMembers$ = this.teamMembersQuery.selectAll();
  activeTeam$ = this.teamsQuery.selectActive();
  activeTeam: TeamData | undefined;
  roles: TeamRole[] = [];
  roles$ = this.teamService.teamRoles$;
  searchQ = '';
  filterRole: string | undefined = undefined;

  columns: SimpleTableColumn[] = [
    { name: 'User', key: 'user.name', sortable: true, inMobileView: true },
    { name: () => (this.autoUpdateBilling || this.hasForcedPro) ? 'Plan' : tr`Plan (${this.numberOfUnassigned} ${this.planName} left)`, isHidden: IS_HOSTED },
    { name: 'Last active', key: 'user.lastActive', sortable: true },
    { name: 'Roles' },
    { name: 'Action', inMobileView: true },
  ];
  members: TeamMember[] = [];
  _members: TeamMember[] = [];
  simpleTableParams = { ...DEFAULT_SIMPLE_TABLE_PARAMS, sortBy: 'user.lastActive', sortDirection: SortOrders.Descending };
  isLoading = false;

  reload$ = new BehaviorSubject(null);
  memberWithTransferrableSeat: TeamMember[] = [];
  autoUpdateBilling = false;
  billingData: BillingData | undefined;
  billingPendingChanges: ScheduledTeamBillingUpdate | undefined;

  constructor(
    private teamsQuery: TeamsQuery,
    private teamMembersQuery: TeamMembersQuery,
    private teamMembersService: TeamMembersService,
    private toastService: ToastService,
    private modalService: ModalService,
    private userService: UserService,
    private teamService: TeamService,
    private billingService: BillingService,
    private simpleTableService: SimpleTableService,
    private errorReporter: ErrorReporter
  ) {
    this.activeTeam$.pipe(untilDestroyed(this)).subscribe(team => this.activeTeam = team);

    this.teamService.teamRoles$.pipe(untilDestroyed(this)).subscribe(roles => {
      this.roles = roles.filter(r => !ROLE_TYPES_READ_ONLY.includes(r.type));
    });

    this.teamMembers$.pipe(untilDestroyed(this)).subscribe(members => {
      this.memberWithTransferrableSeat = members.filter(m => !m.roles.find(r => r.type === RoleType.Blocked || r.type === RoleType.Owner));
    });

    this.teamMembersQuery.selectAll()
      .pipe(
        tap(() => this.isLoading = true),
        map(members => (members.map(m => ({ ...m, roles: m.roles.filter(r => !r.user) })))))
      .subscribe(members => {
        this._members = members;
        this.syncTable();
        this.isLoading = false;
      });
  }

  async ngOnInit() {
    await this.loadBillingData();
  }

  async invite() {
    if (this.activeTeam) {
      await this.modalService.inviteTeamMembers({
        team: this.activeTeam,
        canManageInvites: this.teamService.hasPermissionFlag(Permission.CanManageInvites),
      });
    }
  }

  filteredRoles(member: TeamMember) {
    const userRoleIds = member.roles.map(r => r._id);
    return this.roles.filter(r => !userRoleIds.includes(r._id) && r.name.toLowerCase().includes(this.filter.toLowerCase()));
  }

  isBlocked(member: TeamMember) {
    return member.roles.find(r => r.type === RoleType.Blocked);
  }

  setSearchMember(q: string) {
    this.searchQ = q;
    this.simpleTableParams.currentPage = 1;
    this.syncTable();
  }

  get hasForcedPro() {
    if (!this.activeTeam) return false;
    return this.activeTeam.forcePro || (this.activeTeam.forceProUntil && new Date(this.activeTeam.forceProUntil).getTime() > Date.now());
  }

  get hasActiveSubscription() {
    if (!this.activeTeam) return;
    return this.billingService.hasActiveBilling(this.activeTeam);
  }

  get canChangePlan() {
    return this.hasActiveSubscription && !this.hasForcedPro && !this.autoUpdateBilling;
  }

  get canManageRoles() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeamRoles);
  }

  get canManageBilling() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeamBilling);
  }

  get canRemoveMembers() {
    return this.teamService.hasPermissionFlag(Permission.CanManageTeamMembers);
  }

  get showContextMenu() {
    return this.canManageRoles || this.canRemoveMembers;
  }

  isMe(member: TeamMember) {
    return this.userService.userId === member.user._id;
  }

  get teamId() {
    return this.activeTeam!._id;
  }

  get isSearch() {
    return !!this.searchQ.length;
  }

  get isEmpty() {
    return !this.members.length;
  }

  async switchToPublicMode() {
    if (!this.activeTeam) return;
    const confirmed = await this.modalService.switchingToPublic(this.activeTeam);
    if (confirmed) {
      try {
        await this.teamService.updateTeam(this.activeTeam, { isPublic: true });
        this.toastService.success({ message: `Team mode changed to Public` });
      } catch (e) {
        this.toastService.success({ message: e.message });
      }
    }
  }

  async transferOwnership(member: TeamMember) {
    try {
      if (await this.modalService.transferTeamOwnership({
        teamId: this.activeTeam!._id,
        teamName: this.activeTeam!.name,
        userId: member.user._id,
        userName: member.user.name,
      })) {
        await this.teamService.updateOwner(this.activeTeam!, member);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to transfer ownership', subtitle: e.message });
    }
  }

  async removeTeamMember(member: TeamMember) {
    try {
      if (await this.modalService.removeTeamMember({ teamName: this.activeTeam!.name, userName: member.user.name })) {
        await this.teamMembersService.removeMemberTeam(this.activeTeam!._id, member.user._id);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to remove artspace member', subtitle: e.message });
    }
  }

  async removeAndBanTeamMember(member: TeamMember) {
    try {
      if (await this.modalService.banTeamMember({ teamName: this.activeTeam!.name, userName: member.user.name })) {
        await this.teamMembersService.removeMemberTeam(this.activeTeam!._id, member.user._id, true);
      }
    } catch (e) {
      this.toastService.error({ message: 'Failed to remove & ban artspace member', subtitle: e.message });
    }
  }

  async addRole(member: TeamMember, role: TeamRole) {
    if (!this.activeTeam) {
      DEVELOPMENT && console.error('No active team');
      return;
    }

    const roles = [...member.roles.map(r => r._id), role._id];

    if (roles) {
      const toast = this.toastService.loading({ message: 'Updating roles...' });
      try {
        await this.teamMembersService.updateTeamMember(this.teamId, member.user._id, roles);
        this.toastService.updateToSuccess(toast, { message: 'Successfully updated roles' });
      } catch (e) {
        this.toastService.updateToError(toast, { message: 'Failed to update roles' });
      }
    }
  }

  async reassignRoles(sourceMember: TeamMember, targetMember: TeamMember) {
    const toast = this.toastService.loading({ message: 'Updating roles...' });
    try {
      await this.teamMembersService.transferTeamMemberRoles(this.teamId, sourceMember._id, targetMember._id);
      this.toastService.updateToSuccess(toast, { message: 'Successfully updated roles' });
    } catch (e) {
      this.toastService.updateToError(toast, { message: 'Failed to update roles' });
    }
  }

  async removeRole(member: TeamMember, role: TeamRole) {
    if (!this.activeTeam) {
      DEVELOPMENT && console.error('No active team');
      return;
    }

    const roles = member.roles.map(r => r._id).filter(id => id !== role._id);

    if (roles) {
      const toast = this.toastService.loading({ message: 'Updating roles...' });
      try {
        await this.teamMembersService.updateTeamMember(this.teamId, member.user._id, roles);
        this.toastService.updateToSuccess(toast, { message: 'Successfully updated roles' });
      } catch (e) {
        this.toastService.updateToError(toast, { message: 'Failed to update roles' });
      }
    }
  }

  async setRoles(member: TeamMember) {
    if (!this.activeTeam) {
      DEVELOPMENT && console.error('No active team');
      return;
    }

    const roles = await this.modalService.setRoles({
      userId: member.user._id,
      team: this.activeTeam,
      roles: member.roles.map(r => r._id),
    });

    if (roles) {
      try {
        await this.teamMembersService.updateTeamMember(this.teamId, member.user._id, roles);
      } catch (e) {
        this.toastService.error({ message: 'Failed to update roles', subtitle: e.message });
      }
    }
  }

  getPlanForMember(member: TeamMember) {
    const productId = getPlanForMember(member);
    return productId ? TEAM_PRODUCTS.get(productId) : undefined;
  }

  get numberOfPages() {
    return Math.ceil((this.simpleTableParams.total || 0) / this.simpleTableParams.pageSize);
  }

  get isMobileView() {
    return belowBreakpointMD();
  }

  get proIcon() {
    return this.activeTeam?.pro === SubscriptionPlan.Fusion ? fusionIcon : blazeIcon;
  }

  get proClass() {
    return this.activeTeam?.pro === SubscriptionPlan.Fusion ? 'text-fusion' : 'text-blaze';
  }

  get plan() {
    return this.activeTeam ? getSubscriptionPlanByEnum(this.activeTeam.pro) : undefined;
  }

  get planName() {
    return this.plan?.name ?? 'Blaze';
  }

  getPlanName({ user }: TeamMember) {
    if (this.activeTeam && hasInProSources(user, this.activeTeam._id)) {
      return nonTranslatable(getSubscriptionPlanByEnum(this.activeTeam.pro).name);
    } else if (user.pro) {
      return tr`${nonTranslatable(getSubscriptionPlanByEnum(user.pro).name)} (self-managed)`;
    } else {
      return 'Spark (free)';
    }
  }

  get numberOfAssignedPlans() {
    if (!this.activeTeam) return 0;
    const teamId = this.activeTeam._id;
    return this.members.reduce((sum, m) => sum + (hasInProSources(m.user, teamId) ? 1 : 0), 0);
  }

  get numberOfPlans() {
    return this.activeTeam ? getNumberOfPurchasedSub(this.billingPendingChanges, this.billingData, this.activeTeam.pro) : 0;
  }

  get numberOfUnassigned() {
    return this.numberOfPlans - this.numberOfAssignedPlans;
  }

  get isFiltered() {
    return !!this.filterRole?.length;
  }

  hasTeamPro({ user }: TeamMember) {
    return this.activeTeam && user.pro && hasInProSources(user, this.activeTeam._id);
  }

  canActivate(member: TeamMember) {
    return !this.autoUpdateBilling && !this.hasTeamPro(member);
  }

  canDeactivate(member: TeamMember) {
    return !this.autoUpdateBilling && !this.hasForcedPro && this.hasTeamPro(member);
  }

  async buyMoreLicenses(member: TeamMember) {
    if (!this.activeTeam || !this.plan) return;
    if (!this.billingData) return;

    await this.modalService.createTeamSubscriptionModal({
      plan: this.plan,
      team: this.activeTeam,
      billingData: this.billingData,
      autoUpdateBilling: this.autoUpdateBilling,
      items: { [this.plan.code]: this.numberOfPlans },
      afterItems: { [this.plan.code]: this.numberOfPlans + 1 },
      userIdToAddPro: member.user._id,
      membersCount: this.members.length
    });

    await this.loadBillingData();
    await this.teamService.refreshTeamUsageData(this.activeTeam._id);
    this.teamMembersService.reloadMembers();
  }

  async updateMember(member: TeamMember, assing: boolean) {
    if (!this.activeTeam) {
      DEVELOPMENT && console.error('Missing team');
      return;
    }

    if (!this.billingData) {
      DEVELOPMENT && console.error('Missing team');
      return;
    }

    const showModal = (this.numberOfPlans - this.numberOfAssignedPlans) <= 0 && this.canActivate(member);
    if (assing && showModal) {
      return this.buyMoreLicenses(member);
    }

    if (!assing && !this.canDeactivate(member)) {
      return;
    }

    try {
      await this.billingService.updateTeamMemberPro(this.activeTeam._id, member.user._id, assing);
      this.teamMembersService.reloadMembers();
    } catch (e) {
      this.errorReporter.reportError(e.message, e);
      this.toastService.error({ message: e.message });
    }
  }

  showTeamProBadge({ user }: TeamMember) {
    return user.pro && this.activeTeam && hasInProSources(user, this.activeTeam._id);
  }

  showOtherProBadge({ user }: TeamMember) {
    return user.pro && !(this.activeTeam && hasInProSources(user, this.activeTeam._id));
  }

  sortingTable(data: SimpleTableParams) {
    this.simpleTableParams = { ...data };
    this.syncTable();
  }

  syncTable() {
    let filteredMembers = !this.searchQ.length ? this._members : this._members.filter(m => m.user.name.toLowerCase().includes(this.searchQ.toLowerCase()));
    filteredMembers = !this.filterRole ? filteredMembers : filteredMembers.filter(m => m.roles.some(r => r.name.toLowerCase() === this.filterRole?.toLowerCase()));
    const result = this.simpleTableService.sortAndPaginateArray(filteredMembers, this.simpleTableParams.sortBy, this.simpleTableParams.sortDirection, this.simpleTableParams.currentPage, this.simpleTableParams.pageSize);
    this.members = result.data;
    this.simpleTableParams.total = result.totalItems;
  }

  isFilteredRole(role: string) {
    return this.filterRole === role;
  }

  changeFilterRole(role: string) {
    this.filterRole = this.filterRole === role ? undefined : role;
    this.syncTable();
  }

  private async loadBillingData() {
    if (!this.activeTeam) return;

    const [{ billing, autoUpdateBilling }, changes] = await Promise.all([
      toPromise(this.billingService.getTeamBillingInformation(this.activeTeam._id)),
      this.billingService.getTeamBillingPendingChanges(this.activeTeam._id),
    ]);

    this.billingData = billing;
    this.autoUpdateBilling = !!autoUpdateBilling;
    if (changes?.items?.length) this.billingPendingChanges = changes;
  }
}
